export const TopNav = {
    "Dashboard": "賬戶",
    "Apply Loan":"申請貸款",
    "Loans": "貸款詳情",
    "Active Loans": "貸款（已生效）",
    "Pending Applications": "貸款（申請中）",
    "Applications History": "貸款申請歷史",
    "Withdraw": "提現",
    "Withdraw Crypto": "提現虛擬貨幣",
    "User ID": "用戶ID",
    "Email": "電郵",
    "My Profile": "個人頁面",
    "KYC Verification": "實名認證",
    "View Transactions":"交易記錄",
    "Sign out":"登出"
} 