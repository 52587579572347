import React from 'react';
import { useLocation } from "react-router-dom";
import './docusign.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export enum DocuSignEvent {
    VIEW_COMPLETE="viewing_complete",
    SIGNING_COMPLETE="signing_complete",
    CANCEL="cancel",
}


const Docusign = () => {
    
    const msgDisplay = (status:DocuSignEvent) => {
        console.log(status)
        if (status === DocuSignEvent.SIGNING_COMPLETE || status === DocuSignEvent.VIEW_COMPLETE) {
            return 'Loan Agreement Signed'
        } 
         else {
            return 'Loan Agreement Cancelled'
        }
    }
    
    const icon = (status:DocuSignEvent) => {
        if (status === DocuSignEvent.SIGNING_COMPLETE || status === DocuSignEvent.VIEW_COMPLETE) {
            return "icon ni ni-check"
        } else {
            return "icon ni ni-file"
        }
    }
    
    const msgBody = (status:DocuSignEvent) => {
        if (status === DocuSignEvent.SIGNING_COMPLETE || status === DocuSignEvent.VIEW_COMPLETE) {
            return 'You have successfully signed the loan agreement.'
        } else {
            return 'You have not completed signing the loan agreement.'
        }
    }
    
    const msgBody2 = (status:DocuSignEvent) => {
        if (status === DocuSignEvent.SIGNING_COMPLETE || status === DocuSignEvent.VIEW_COMPLETE) {
            return 'To finish the loan aplication process, deposit your collateral if you haven\'t already.'
        } else {
            return 'Please finish signing the loan agreement when you are ready.'
        }
    }
    
    const closeWindow = () => {
        var docuWindow = window.self;
        // docuWindow.location.href = 'http://www.google.com';
        console.log(window.self)
        docuWindow.close();
    }

    let query = useQuery();
    let status = query.get("event") as DocuSignEvent;

    return (
        <React.Fragment>
            <div className="nk-content nk-content-lg nk-content-fluid">
                <div className="container-xl wide-lg">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="kyc-app wide-sm m-auto">
                                <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                                    <div className="nk-block-head-content text-center">
                                        <h2 className="nk-block-title fw-normal">{msgDisplay(status)}</h2>
                                        <div className="nk-block-des">
                                            <p>{msgBody(status)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block">
                                    <div className="card card-bordered">
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-kyc-app p-sm-2 text-center">
                                                <div className="nk-kyc-app-icon">
                                                    <em className={icon(status)}></em>
                                                </div>
                                                <div className="nk-kyc-app-text mx-auto">
                                                    <p className="lead">{msgBody2(status)}</p>
                                                </div>
                                                <div className="nk-kyc-app-action">
                                                    <button onClick={closeWindow} className="btn btn-lg btn-outline-primary">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Docusign;