import React, { useState,  useContext } from 'react';
import WAValidator from 'wallet-address-validator';
import axios from 'axios';
import {Link} from 'react-router-dom';
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/appContext';

const override = css`
  display: block;
  border-color: #8094ae;
  width: 175px;
  margin: auto;
`;

let WithdrawDetails = (props) => {

    const [amountError, setAmountError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [quoteloading, setQuoteLoading] = useState(false);
    const [withdrawConfirm, setWithdrawConfirm] = useState(false);
    const [withdrawFee, setWithdrawFee]= useState(0);
    const [baseFee, setBaseFee]= useState(0);
    const [percentFee, setPercentFee]= useState(0);
    const { currentUser, currentUserSetting } = useContext(AppContext);
    const { t, i18n } = useTranslation('WithdrawPageCrypto')

    const displayAmtError = (state) => {
        if (state) {
            return (
                <p className="text-danger">{t('Withdraw Amount must be less or equal to your available balance')}</p>
            )
        }
    }

    const displayAddressError = (state) => {
        if (state) {
            return (
                <p className="text-danger">{t('Withdraw Address is invalid, please check your destination wallet address and try again')}</p>
            )
        }
    }

    const showWithdrawConfirmation = (state, asset, amt, add) => {
        if( state ) {
        return (
            <React.Fragment>
                <div style={{display: "block", opacity: 1}} className="modal fade" tabIndex={-1} id="confirm-invest">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                    <Link to="/dashboard" className="close" data-dismiss="modal"><em className="icon ni ni-cross-sm"></em></Link>
                    <div className="modal-body modal-body-lg text-center">
                        <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">You withdraw is being processed!</h4>
                        <div className="nk-modal-text">
                            <p className="sub-text"><strong>Your withdraw request of {amt} {asset} </strong> will be released to <strong>{add}</strong> as soon as the network confirms the withdraw request.</p>
                            <p className="sub-text">Time to process could range from 10 - 30 minutes depending on network usage. You will be able to view your transactions history at the "Trasnactions" at the top right under your profile tab.</p>
                        </div>
                        <div className="nk-modal-action-lg">
                            <ul className="btn-group flex-wrap justify-center g-4">
                            <li data-dismiss="modal"><Link to="/dashboard" className="btn btn-lg btn-mw btn-primary">Got it</Link></li>
                            {/*<li data-dismiss="modal"><Link to="/transactions/crypto" className="btn btn-lg btn-mw btn-dim btn-primary"><em className="icon ni ni-reports"></em><span>View Transactions</span></Link></li>*/}
                            </ul>
                        </div>
                        </div>
                    </div>{/*
                    <div className="modal-footer bg-lighter">
                        <div className="text-center w-100">
                        <p>Earn upto $25 for each friend your refer! <a href="#">Invite friends</a></p>
                        </div>
                    </div>*/}
                    </div>
                </div>
                </div>
            </React.Fragment>

            )
        }
    }

    
    const handleQuote = (e, asset, amount, address, aBTC, aETH, aUSDT) => {
        // console.log("amount",amount)
        e.preventDefault();
        let withdrawAsset, withdrawAmt, withdrawAddress, avaliableBTC, avaliableETH, avaliableUSDT, validateAsset;
        withdrawAsset = asset;
        withdrawAmt = amount;
        withdrawAddress = address;
        avaliableBTC = aBTC;
        avaliableETH = aETH;
        avaliableUSDT = aUSDT;
        validateAsset = asset;

        let changeUSDT = (asset) => {
            if (asset === 'USDT') {
                validateAsset = 'ETH';
            }
        }

        changeUSDT(asset);

        let checkBal = (amount, asset) => {
            if (asset === 'BTC') {
                if (amount <= avaliableBTC && amount > 0 ) {
                    return true;
                } else return false
            } 
            if (asset === 'ETH') {
                if (amount <= avaliableETH && amount > 0) {
                    return true;
                } else return false
            } 
            if (asset === 'USDT') {
                if (amount <= avaliableUSDT && amount > 0 ) {
                    return true;
                } else return false
            } 
        }
        let balanceCheck = checkBal(withdrawAmt, withdrawAsset);
        if (balanceCheck) {
            setAmountError(false);
            let valid = WAValidator.validate(address, validateAsset, "both");
            
            if (valid) {
               
                setAddressError(false)
                let data  = {
                    assetType: '',
                    amount: 0,
                    address: ''
                }
                let config = {
                    method: 'post',
                    url: '/api/v1/withdraw/quoteCryptoWithdrawFee',
                    headers: { 
                    'Authorization': localStorage.firebaseToken,
                    'Content-Type': 'application/json'
                    },
                    data: data
                }
                data.assetType = withdrawAsset.toLowerCase();
                data.amount = parseFloat((withdrawAmt * 1).toFixed(8));
                data.address = withdrawAddress;
                console.log(data);
                setQuoteLoading(true);
                axios(config).then((res) => {
                        console.log(res);
                        setWithdrawFee(res.data.fee)
                        setBaseFee(res.data.baseFee)
                        setPercentFee(res.data.percentFee)
                        setQuoteLoading(false);
                        // setWithdrawConfirm(true);
                    })
                    .catch(
                        
                        err => {
                            console.log(err)
                            setQuoteLoading(false)
                            window.confirm("Amount Not Enough !!!")
                        }
                )
            }else{
                setAddressError(true);
                console.log('withdraw address is invalid, please check the format')
            }
        } else {
            setAmountError(true);
        }
    }
    const handleTestNetWithdraw = (e, asset, amount, address, aBTC, aETH, aUSDT) => {
        // console.log("amount",amount)
        e.preventDefault();
        let withdrawAsset, withdrawAmt, withdrawAddress, avaliableBTC, avaliableETH, avaliableUSDT, validateAsset;
        withdrawAsset = asset;
        withdrawAmt = amount;
        withdrawAddress = address;
        avaliableBTC = aBTC;
        avaliableETH = aETH;
        avaliableUSDT = aUSDT;
        validateAsset = asset;

        let changeUSDT = (asset) => {
            if (asset === 'USDT') {
                validateAsset = 'ETH';
            }
        }

        changeUSDT(asset);

        let checkBal = (amount, asset) => {
            if (asset === 'BTC') {
                if (amount <= avaliableBTC && amount > 0 ) {
                    return true;
                } else return false
            } 
            if (asset === 'ETH') {
                if (amount <= avaliableETH && amount > 0) {
                    return true;
                } else return false
            } 
            if (asset === 'USDT') {
                if (amount <= avaliableUSDT && amount > 0 ) {
                    return true;
                } else return false
            } 
        }
        let balanceCheck = checkBal(withdrawAmt, withdrawAsset);
        if (balanceCheck) {
            setAmountError(false);
            let valid = WAValidator.validate(address, validateAsset, "both");
            if (valid) {
                setAddressError(false)
                let data  = {
                    assetType: '',
                    amount: 0,
                    walletAddress: ''
                }
                let config = {
                    method: 'post',
                    url: '/api/v1/withdraw/crypto',
                    headers: { 
                      'Authorization': localStorage.firebaseToken,
                      'Content-Type': 'application/json'
                    },
                    data: data
                }
                data.assetType = withdrawAsset.toLowerCase();
                data.amount = parseFloat((withdrawAmt * 1).toFixed(8));
                data.walletAddress = withdrawAddress;
                console.log(data);
                setLoading(true);
                axios(config).then((res) => {
                        console.log(res);
                        setLoading(false);
                        setWithdrawConfirm(true);
                    })
                    .catch(err => console.log(err))
            } 
            else {
                setAddressError(true);
                console.log('withdraw address is invalid, please check the format')
            }
        } else {
            setAmountError(true);
        }
    }
    const handleWithdraw = (e, asset, amount, address, aBTC, aETH, aUSDT) => {
        // console.log("amount",amount)
        e.preventDefault();
        let withdrawAsset, withdrawAmt, withdrawAddress, avaliableBTC, avaliableETH, avaliableUSDT, validateAsset;
        withdrawAsset = asset;
        withdrawAmt = amount;
        withdrawAddress = address;
        avaliableBTC = aBTC;
        avaliableETH = aETH;
        avaliableUSDT = aUSDT;
        validateAsset = asset;

        let changeUSDT = (asset) => {
            if (asset === 'USDT') {
                validateAsset = 'ETH';
            }
        }

        changeUSDT(asset);

        let checkBal = (amount, asset) => {
            if (asset === 'BTC') {
                if (amount <= avaliableBTC && amount > 0 ) {
                    return true;
                } else return false
            } 
            if (asset === 'ETH') {
                if (amount <= avaliableETH && amount > 0) {
                    return true;
                } else return false
            } 
            if (asset === 'USDT') {
                if (amount <= avaliableUSDT && amount > 0 ) {
                    return true;
                } else return false
            } 
        }
        let balanceCheck = checkBal(withdrawAmt, withdrawAsset);
        if (balanceCheck) {
            setAmountError(false);
            let valid = WAValidator.validate(address, validateAsset);
            if (valid) {
                setAddressError(false)
                let data  = {
                    assetType: '',
                    amount: 0,
                    walletAddress: ''
                }
                let config = {
                    method: 'post',
                    url: '/api/v1/withdraw/crypto',
                    headers: { 
                      'Authorization': localStorage.firebaseToken,
                      'Content-Type': 'application/json'
                    },
                    data: data
                }
                data.assetType = withdrawAsset.toLowerCase();
                data.amount = parseFloat((withdrawAmt * 1).toFixed(8));
                data.walletAddress = withdrawAddress;
                console.log(data);
                setLoading(true);
                axios(config).then((res) => {
                        console.log(res);
                        setLoading(false);
                        setWithdrawConfirm(true);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                        setAmountError(true);
                    })
            } 
            else {
                setAddressError(true);
                console.log('withdraw address is invalid, please check the format')
            }
        } else {
            setAmountError(true);
        }
    }

  return (
    <div className="col-xl-4 col-lg-5 offset-xl-1">
      <div className="card card-bordered ml-lg-4 ml-xl-0">
          <div className="nk-iv-wg4">
              <div className="nk-iv-wg4-sub">
                  <h6 className="nk-iv-wg4-title title">{t('Your Withdraw Details')}</h6>
                  <ul className="nk-iv-wg4-overview g-2">
                      <li>
                          <div className="sub-text">{t('Asset to Withdraw')}</div>
                          <div className="lead-text">{props.withdrawAsset}</div>
                      </li>
                      <li>
                          <div className="sub-text">{t('Amount to Withdraw')}</div>
                          <div className="lead-text">{(props.withdrawAmt * 1).toFixed(8)}</div>
                      </li>
                  </ul>
              </div>
              <div className="nk-iv-wg4-sub">
                  <ul className="nk-iv-wg4-list">
                      <li>
                          <div className="sub-text">{t('Send to address')}</div>
                          <div className="lead-text">{props.withdrawAddress}</div>
                      </li>
                  </ul>
              </div>
              <div className="nk-iv-wg4-sub">
                  <ul className="nk-iv-wg4-list">
                      <li>
                          <div className="sub-text">{t('Amount to Withdraw')}</div>
                          <div className="lead-text">{props.withdrawAmt? (props.withdrawAmt * 1).toFixed(8) : ''}</div>
                      </li>
                      <li>
                          {/* <div className="sub-text">Withdraw Fee <span>(0.5%)</span></div> */}
                          <div className="sub-text">{t('Minimum Withdraw Fee')}</div>
                          <div className="lead-text">-{baseFee? (baseFee).toFixed(8) : ''}</div>
                      </li>
                      <li>
                          {/* <div className="sub-text">Withdraw Fee <span>(0.5%)</span></div> */}
                          <div className="sub-text">{t('Network fee')}</div>
                          <div className="lead-text">-{percentFee? (percentFee).toFixed(8) : ''}</div>
                      </li>
                  </ul>
              </div>
              <div className="nk-iv-wg4-sub">
                  <ul className="nk-iv-wg4-list">
                      <li>
                          <div className="lead-text">{t('You can get')}</div>
                          <div className="caption-text text-primary">{props.withdrawAmt? (props.withdrawAmt - (withdrawFee)).toFixed(8) : ''}</div>
                      </li>
                  </ul>
              </div>
              <div className="nk-iv-wg4-sub text-center bg-lighter">
                  <button onClick={(e) => handleQuote(e, props.withdrawAsset, props.withdrawAmt, props.withdrawAddress, props.avaliableBTC, props.avaliableETH, props.avaliableUSDT)} className="btn btn-lg btn-primary ttu" data-toggle="modal" data-target="#invest-plan">{t('Quote Withdraw Fee')}</button>
                  <BarLoader css={override} size={150} color={"#123abc"} loading={quoteloading} />
                  {displayAmtError(amountError)}
                  {displayAddressError(addressError)}
              </div>
              {/*<div className="nk-iv-wg4-sub text-center bg-lighter">
                  <button onClick={(e) => handleTestNetWithdraw(e, props.withdrawAsset, props.withdrawAmt, props.withdrawAddress, props.avaliableBTC, props.avaliableETH, props.avaliableUSDT)} disabled={props.withdrawAmt - (withdrawFee) > 0 ? false : true} className="btn btn-lg btn-primary ttu" data-toggle="modal" data-target="#invest-plan">Confirm Testnet &amp; proceed</button>
                  <BarLoader css={override} size={150} color={"#123abc"} loading={loading} />
                  {displayAmtError(amountError)}
                  {displayAddressError(addressError)}
                </div>*/}
              <div className="nk-iv-wg4-sub text-center bg-lighter">
                  <button onClick={(e) => handleWithdraw(e, props.withdrawAsset, props.withdrawAmt, props.withdrawAddress, props.avaliableBTC, props.avaliableETH, props.avaliableUSDT)} disabled={props.withdrawAmt - (withdrawFee) > 0 ? false : true} className="btn btn-lg btn-primary ttu" data-toggle="modal" data-target="#invest-plan">{t('Confirm proceed')}</button>
                  <BarLoader css={override} size={150} color={"#123abc"} loading={loading} />
              </div>
          </div>
      </div>
      {showWithdrawConfirmation(withdrawConfirm, props.withdrawAsset, props.withdrawAmt, props.withdrawAddress)}
  </div>
  );
}

export default WithdrawDetails;