import { func } from "prop-types";
import React, { useEffect , useState } from "react";
import { useTranslation } from "react-i18next";
import { SignOutUser } from "../ApiService/auth";
import { BorrowAsset, CollateralAsset } from "../ApiService/loan";
import { BalanceAsset } from "../ApiService/user";
import ConfigFirebase from '../config/firebaseConfig'

const enableBitGo = true


export enum UserVerificationStatus{
    None = "none",
    Pending = "pending",
    UnderReview = "under_review",
    Verified = "verified"
}

// 'none', 'pending', 'under_review', 'verified'
export interface User{
    userID: number;
    name: string;
    email: string;
    verificationLevel: number;
    verificationStatus: UserVerificationStatus;
    userPermissions: number;
}

export interface UserKYC{

    userID: 5,
    firstName: string;
    lastName: string;
    fullEnglishName: string;
    fullChineseName: string;
    dob: string;
    gender: string;
    idType: string;
    idNumber: string;
    idCountry:string;
    mobileNumber: string;
    mobileNumber2: string;
    residentialAddress: string;
    annualIncomeCurrency: string;
    annualIncomeAmount: string;
    incomeSource: string;
    employerName: string;
    employmentYears: string;
    businessName: string;
    incorporationBusinessCountry:string;
    businessRegistrationNumber:string;
    kycAt: Date
}

export enum Language{
    en_US = "en_US",
    zh_TW = "zh_TW",
    zh_CN = "zh_CN",
}
export interface UserSetting{
    displayCurrency: BalanceAsset | BorrowAsset | CollateralAsset
    usdToCurrency: number
    language: Language
}


export interface AppContextInterface {
    currentUser: User | undefined,
    currentUserSetting: UserSetting ,
    setCurrentUserSetting: (userSetting: UserSetting) => void
    setLanguage: (language: Language) => void,
    // currentCoinPriceInUSD: GetAllCoinPriceInUSDRes | undefined,
    // setCurrentCoinPriceInUSD: (priceRes: GetAllCoinPriceInUSDRes)=>void,
    enableBitGo:boolean,
    
    setCurrentUser: (user: User)=>void,
    isSignin: boolean,
    setSignin: (signin: boolean) => void,
    signOut: ()=>void
  }
  
export const AppContext = React.createContext<AppContextInterface>({
    currentUser:undefined,
    // for default
    currentUserSetting:{
        displayCurrency:BorrowAsset.HKD,
        usdToCurrency: 7.85,
        language:Language.en_US
    },
    setCurrentUserSetting: () =>{},
    setLanguage: () =>{},
    // currentCoinPriceInUSD:{
    //     btcPrice:10000,
    //     ethPrice:300,
    //     usdtPrice:1,
    // },
    // setCurrentCoinPriceInUSD:()=>{},
    enableBitGo:enableBitGo,
    setCurrentUser: ()=>{},
    isSignin:false,
    setSignin: ()=>{},
    signOut: ()=>{},
});

// this will override all setting above
export const useAppContext = ():AppContextInterface =>{
    const [currentUser, setCurrentUser] = React.useState<User| undefined>(undefined)
    const [currentUserSetting, setCurrentUserSetting] = React.useState<UserSetting>({
        displayCurrency:BorrowAsset.HKD,
        usdToCurrency: 7.85,
        language:Language.zh_TW,
    })
    // const [currentCoinPriceInUSD, setCurrentCoinPriceInUSD] = React.useState<GetAllCoinPriceInUSDRes| undefined>(undefined)
    const [isSignin, setSignin] = React.useState<boolean>(false)
    // const [cookies, setCookie, removeCookie] = useCookies(['emailForSignIn']);
    const { i18n } = useTranslation()

    useEffect(()=>{
        //create a default language setting for user
        {localStorage.getItem('language') ? localStorage.setItem('1','2') : localStorage.setItem('language','zh_TW')}
        const user_define_language = <string>localStorage.getItem('language')

        i18n.changeLanguage(user_define_language)
    },[currentUserSetting.language])

    useEffect(()=>{
    },[])

    const SetLanguage = (language: Language) => {
        let newSetting = {... currentUserSetting}
        newSetting.language = language
        setCurrentUserSetting(newSetting)
        localStorage.setItem('language',newSetting.language)
        window.location.reload()
    }


    return {
        currentUser:currentUser,
        currentUserSetting:currentUserSetting, 
        setCurrentUserSetting:setCurrentUserSetting,
        setLanguage:SetLanguage,
        // currentCoinPriceInUSD:currentCoinPriceInUSD,
        // setCurrentCoinPriceInUSD:setCurrentCoinPriceInUSD,
        enableBitGo:enableBitGo,
        setCurrentUser: setCurrentUser,
        isSignin,
        setSignin,
        signOut:async()=>{
            ConfigFirebase.auth().signOut()
            // localStorage.clear()
            // sessionStorage.clear();
            // removeCookie('emailForSignIn')
            // await indexedDB.deleteDatabase('firebaseLocalStorageDb')
            await SignOutUser()
            setCurrentUser(undefined)
            setSignin(false)
        }
    }
}


