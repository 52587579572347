export const Common = {
    "HKD":"港幣",
    "USDT":"泰達幣",
    "APR":"年利率",
    "LTV":"貸款抵押比例",
    "Yes":"是",
    "No":"否",
    "Days":"{{days}} 日",
    "Months":"{{months}} 個月",
    "Years":"{{years}} 年",
    "Confirm":"確認",
    "Close":"關閉",
    "Cancel":"取消",

    "page_go_back":"返回",
    "copy_to_clipboard":"複製到剪貼板",
    "ask_for_support":"如有問題，請聯絡 cs@gpfi.com.hk",

    "bank_name":"銀行名稱",
    "account_holder_name":"賬戶持有人名稱",
    "account_number":"賬戶號碼",
    "amount":"金額",
    "Bank_of_Communications_hong_kong_Limited":"交通銀行（香港）",

    "Terms & Conditions": "條款及細則",
} 