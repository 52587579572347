import React, { useState, useEffect } from 'react';
import CryptoTransactionItem from './CryptosxTransactionItem'
import axios from 'axios'
import { guard } from '../../util/jacob/core/lambda';
import {formatTransactionAmount, FormatTransactionType, formatTransactionTarget, useFormatPrice} from '../../util/jacob/components/format'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const CryptoTransactionsPage = () => {
    const { t } = useTranslation('Tx')
    let [txArr, settxArr] = useState([])
    let txArrStore;

    useEffect(() => {
        let fgMounted = true;
        var configBTC = {
            method: 'get',
            url: '/api/v1/transaction/list?asset=BTC',
            headers: { 
              'Authorization': localStorage.firebaseToken,
              'Content-Type': 'application/json'
            }
        };

        var configETH = {
            method: 'get',
            url: '/api/v1/transaction/list?asset=ETH',
            headers: { 
              'Authorization': localStorage.firebaseToken,
              'Content-Type': 'application/json'
            }
        };

        var configUSDT = {
            method: 'get',
            url: '/api/v1/transaction/list?asset=USDT',
            headers: { 
              'Authorization': localStorage.firebaseToken,
              'Content-Type': 'application/json'
            }
        };

        const getBTC = axios(configBTC);
        const getETH = axios(configETH);
        const getUSDT = axios(configUSDT);
        
        axios.all([getBTC, getETH, getUSDT]).then(
            axios.spread((...data) => {
                let BTC = data[0].data.entries
                let ETH = data[1].data.entries
                let USDT = data[2].data.entries
                txArrStore = BTC.concat(ETH, USDT);
                console.log(txArrStore);
                txArrStore = (
                    txArrStore
                        .sort((b, a) => {
                            if (moment(a.timestamp).isBefore(moment(b.timestamp)))
                                return -1;
                            if (moment(b.timestamp).isBefore(moment(a.timestamp)))
                                return 1;
                            return 0;
                        })
                );
                //settxArr(txArr.concat(txArrStore));
                return txArr.concat(txArrStore)
            }))
            .then(_ => guard(fgMounted)(data=>{
                let oldTransaction = data.filter(tx => !(({'deposit_reverse':true,'withdraw_reverse':true})[tx.transactionType]))
                console.log("oldTransaction", oldTransaction)
                let newTransaction = []
                oldTransaction.map((tran, index)=>{
                    console.log("tran", tran)
                    if(tran.transactionTarget === 'pending_deposit' && tran.transactionType === "deposit"  ){
                        console.log("find Pending Deposit", tran)
                        // if(oldTransaction[index + 1].type === 'Deposit' && oldTransaction[index + 1].target === "Amount" && tran.amount === oldTransaction[index + 1].amount){
                        //     return
                        // }
                    }
                    newTransaction.push(tran)
                })
                settxArr(newTransaction)
                console.log("newTransaction", newTransaction)
                return newTransaction
            }
            )(_))
            .catch(function(err) {
                console.log(err);
            }) 
        return () => {fgMounted=false};
    }, [])
    
    const arr = [1,2,3]
    return (
        <React.Fragment>
            <div className="nk-content nk-content-fluid">
                <div className="container-xl wide-xl">
                    <div className="nk-content-inner">
                            <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between g-3">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">{t('Tx:crypto_transactions',{defaultValue:"Crypto Transactions"})}</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>{t('Tx:number_of_tx',{defaultValue:"You have total {{total}} transactions.", total:txArr.length})}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block">
                                    <div className="card card-bordered card-stretch">
                                        <div className="card-inner-group">
                                            {/* <div className="card-inner">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h5 className="title">All Crypto transactions</h5>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="card-inner p-0">
                                                <div className="nk-tb-list nk-tb-tnx">
                                                    <div className="nk-tb-item nk-tb-head">
                                                        <div className="nk-tb-col"><span>{t('Tx:type',{defaultValue:"Transaction Type"})}</span></div>
                                                        <div className="nk-tb-col "><span>{t('Tx:asset',{defaultValue:"Asset"})}</span></div>
                                                        <div className="nk-tb-col tb-col-lg"><span>{t('Tx:date',{defaultValue:"Date"})}</span></div>
                                                        <div className="nk-tb-col tb-col-lg"><span>{t('Tx:tx_ref',{defaultValue:"Transaction Reference"})}</span></div>
                                                        <div className="nk-tb-col "><span>{t('Tx:amount',{defaultValue:"Amount"})}</span></div>{/*
                                                        <div className="nk-tb-col text-right tb-col-sm"><span>Balance</span></div>*/}
                                                        {/* <div className="nk-tb-col nk-tb-col-status"><span className="sub-text d-none d-md-block">Status</span></div> */}
                                                        <div className="nk-tb-col nk-tb-col-tools"></div>
                                                    </div>
                                                    {txArr.filter(tx => !(({'deposit_reverse':true,'withdraw_reverse':true})[tx.transactionType])).map((item, i) => 
                                                        (<CryptoTransactionItem 
                                                            key={i} transactionRef={item.transactionID} asset={item.asset} amount={formatTransactionAmount(item)} 
                                                            transactionType={FormatTransactionType(item,t)} transactionTarget={formatTransactionTarget(item)} timestamp={moment(item.timestamp).format("YYYY-MM-DD, h:mm:ss A")} 
                                                        
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ React.Fragment>
    )
}

export default CryptoTransactionsPage;      