import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoanTicketActive, Repayment } from '../../../ApiService/loan'
import { CalculateDayDifference } from '../../../ApiService/shareInfo'
import { useFormatPrice } from '../../../util/jacob/components/format'

const LoanDetailActiveStatus = ({
    loanTicket,
    nextRepayment,
    finalRepayment
}:{
    loanTicket:LoanTicketActive,
    nextRepayment:Repayment,
    finalRepayment:Repayment,
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
        <div className="nk-block nk-block-lg">
            <div className="nk-block-head">
                <h5 className="nk-block-title">{t('Loan:loan_status',{defaultValue:"Loan Status"})}</h5>
            </div>
            <div className="row g-gs">
                <div className="col-lg col-sm-6">
                <div className="card card-bordered h-100">
                    <div className="card-inner justify-center text-center h-100">
                    <div className="nk-iv-wg5">
                        <div className="nk-iv-wg5-head">
                        <h5 className="nk-iv-wg5-title">{t('Loan:current_LTV',{defaultValue:"Current LTV"})}</h5>
                        <div className="nk-iv-wg5-subtitle"><span className="currency currency-usd">{t('Loan:liquidation',{defaultValue:"Liquidation"})}</span>: <strong>71.5%</strong></div>
                        </div>
                        <div className="nk-iv-wg5-ck sm">
                        <input type="text" className="knob-half" {...{/**data-fgColor="#33d895" data-bgColor="#d9e5f7" data-displayInput="false" */}} data-thickness=".07" data-width="240" data-height="125" readOnly />
                        <div className="nk-iv-wg5-ck-result">
                            <div className="knob-half">{`${(loanTicket.currentLTV * 1e2).toFixed(2)}%`}</div>
                            <div className="text-sub">{t('Common:LTV',{defaultValue:"LTV"})}</div>
                        </div>
                        <div className="nk-iv-wg5-ck-minmax"><span>0%</span><span>71.5%</span></div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg col-sm-6">
                <div className="card card-bordered h-100">
                    <div className="card-inner justify-center text-center h-100">
                    <div className="nk-iv-wg5">
                        <div className="nk-iv-wg5-head">
                        <h5 className="nk-iv-wg5-title">{t('Loan:loan_term',{defaultValue:"Loan Term"})}</h5>
                        <div className="nk-iv-wg5-subtitle"><strong>{loanTicket.loanTerm}</strong> <span className="currency currency-usd">{t('Loan:months',{defaultValue:"Months"})}</span></div>
                        </div>
                        <div className="nk-iv-wg5-ck sm">
                        <input type="text" className="knob-half" {...{/**data-fgColor="#33d895" data-bgColor="#d9e5f7" data-displayInput="false" */}} data-thickness=".07" data-width="240" data-height="125" readOnly />
                        {nextRepayment && (
                            <div className="nk-iv-wg5-ck-result">
                            <div className="knob-half">{nextRepayment.repaymentTerm === 0 ? t('Loan:terminating',{defaultValue:"Terminating"}) : (nextRepayment.repaymentTerm - 1)}</div>
                            <div className="text-sub">{t('loan_term_passed',{defaultValue:"Terms Passed"})}</div>
                            </div>
                        )}
                        {/* <div className="nk-iv-wg5-ck-minmax"><span>0%</span><span>100%</span></div> */}
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg col-sm-6">
                <div className="card card-bordered h-100">
                    <div className="card-inner justify-center text-center h-100">
                    {nextRepayment && <NextRepaymentDayRemainDisplayer nextRepayment={nextRepayment}/>}
                    </div>
                </div>
                </div>
            </div>
            <div className="row g-gs">
                <div className="col-lg col-sm-6">
                <div className="card card-bordered h-100">
                    <div className="card-inner justify-center text-center h-100">
                    <div className="nk-iv-wg5">
                        <div className="nk-iv-wg5-head" style={{width: "300px"}}>
                        <h5 className="nk-iv-wg5-title">{t('Loan:current_repayment',{defaultValue:"Repayment Paid"})}</h5>
                        <div className="nk-iv-wg5-subtitle"></div>
                        </div>
                        <div className="nk-iv-wg5-ck sm">
                        <input type="text" className="knob-half" data-thickness=".07" data-width="240" data-height="125" readOnly />
                        <div className="nk-iv-wg5-ck-result">
                            <div className="knob-half">{loanTicket.amountRepaid} {loanTicket.repaymentAsset}</div>
                            <div className="text-sub">{t('Loan:repayment_paid_amount',{defaultValue:"Paid"})}</div>
                        </div>
                        <div className="nk-iv-wg5-ck-minmax"><span>{`0 ${loanTicket.repaymentAsset}`}</span><span>{`${loanTicket.totalOutstanding} ${loanTicket.repaymentAsset}`}</span></div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg col-sm-6">
                <div className="card card-bordered h-100">
                    <div className="card-inner justify-center text-center h-100">
                    <div className="nk-iv-wg5">
                        <div className="nk-iv-wg5-head">
                        <h5 className="nk-iv-wg5-title">{t('Loan:today_termination_price',{defaultValue:"Early Termination"})}</h5>
                        <div className="nk-iv-wg5-subtitle">{t('Loan:termination_fee',{defaultValue:"Termination fee"})}<strong>{loanTicket.voidTerminationFee ? `0 (${t('Loan:void',{defaultValue:"Void"})})` : `${loanTicket.terminationFee}`}</strong> <span className="currency currency-usd">{finalRepayment.repaymentAsset}</span></div>
                        </div>
                        <div className="nk-iv-wg5-ck sm">
                        <input type="text" className="knob-half"  {...{/**data-fgColor="#816bff" data-bgColor="#d9e5f7" data-displayInput="false" */}} data-thickness=".07" data-width="240" data-height="125" readOnly />
                        <div className="nk-iv-wg5-ck-result">
                            <div className="knob-half">{loanTicket.totalPaymentForTermination} {loanTicket.repaymentAsset}</div>
                            {/* <div className="text-sub">Days remain</div> */}
                        </div>
                        {/* <div className="nk-iv-wg5-ck-minmax"><span>0</span><span>31</span></div> */}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      
      </div>
    )
}

const NextRepaymentDayRemainDisplayer = ({
    nextRepayment
}:{
    nextRepayment:Repayment
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
        <div className="nk-iv-wg5">
        <div className="nk-iv-wg5-head">
            <h5 className="nk-iv-wg5-title">{t('Loan:next_repayment',{defaultValue:"Next Payment"})}</h5>
            <div className="nk-iv-wg5-subtitle">{t('Loan:amount_due',{defaultValue:"Amount Due "})}<strong>{useFormatPrice(nextRepayment.repaymentAsset, nextRepayment.repaymentAmount, true)}</strong></div>
        </div>
        <div className="nk-iv-wg5-ck sm">
            <input type="text" className="knob-half"  {...{/**data-fgColor="#816bff" data-bgColor="#d9e5f7" data-displayInput="false" */}} data-thickness=".07" data-width="240" data-height="125" readOnly />
            <div className="nk-iv-wg5-ck-result">
            <div className="knob-half">{t('Common:Days',{defaultValue:"{{days}} Day(s)", days:CalculateDayDifference(new Date(), new Date(nextRepayment.repaymentDate))})}</div>
            <div className="text-sub">{t('Loan:days_remian',{defaultValue:"Days remain"})}</div>
            </div>
            {/* <div className="nk-iv-wg5-ck-minmax"><span>0</span><span>31</span></div> */}
        </div>
        </div>
    )
}
export default LoanDetailActiveStatus