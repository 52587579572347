import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from "react-hook-form";
import WithdrawDetails from './WithdrawDetails';
import {BalanceAsset, GetAllBalance} from '../../ApiService/user';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider'
import { number } from 'yargs';

const WithdrawPageCrypto = () => {
    const { t } = useTranslation('WithdrawPageCrypto')
    const { handleSubmit, register, errors } = useForm();
    const [balObj, setbalObj] = useState({
            BTCprice: 0,
            BTCamount: 0,
            BTChold: 0,
            BTCavailableBal: 0,
            BTCusdAmount: 0,
            ETHprice: 0,
            ETHamount: 0,
            ETHhold: 0,
            ETHavailableBal: 0,
            ETHusdAmount: 0,
            USDTprice: 0,
            USDTamount: 0,
            USDThold: 0,
            USDTavailableBal: 0,
            USDTusdAmount: 0,
            USDamount: 0,
            USDhold: 0,
            USDavailableBal: 0,
            totalUSD: 0,
        }
    )

    const [withdrawForm, setWithdrawForm] = useState({
        asset: BalanceAsset.BTC,
        destinationAddress: '',
        amount: 0,
    })

    const handleAssetChange = (e:React.FormEvent<HTMLSelectElement>) => {
        setWithdrawForm({
            ...withdrawForm,
            asset: e.currentTarget.value as BalanceAsset
        })
    }

    const handleAmountChange = (e:React.FormEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value);
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(e.currentTarget.value)
        })
    }

    const handleAddressChange = (e:React.FormEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value);
        setWithdrawForm({
            ...withdrawForm,
            destinationAddress: e.currentTarget.value
        })
    }



    const showAvaliableAmount = (asset:BalanceAsset) => {
        if (asset === 'BTC') {
            return `${t('You can withdraw up to')} ${balObj.BTCavailableBal} BTC`
        } else if (asset === 'ETH') {
            return `${t('You can withdraw up to')} ${balObj.ETHavailableBal} ETH`
        } else if (asset === 'USDT') {
            return `${t('You can withdraw up to')} ${balObj.USDTavailableBal} USDT`
        }
    }

    const handleBTCQuickAmt = (ratio:number) => {
        const newAmt = (balObj.BTCavailableBal * ratio).toFixed(8)
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(newAmt)
        })
    }

    const handleETHQuickAmt = (ratio:number) => {
        const newAmt = (balObj.ETHavailableBal * ratio).toFixed(8)
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(newAmt)
        })
    }

    const handleUSDTQuickAmt = (ratio:number) => {
        const newAmt = (balObj.USDTavailableBal * ratio).toFixed(8)
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(newAmt)
        })
    }
    // Slider set value 
    const [value, setValue] = useState(0);
    //Slider output
    const SliderShowNumber = (value:number):number => {
        var newvalue = value*100
       return newvalue;
    }
    //Slider BTC
    const SliderhandleChangeBTC = (event: any, newValue: number | number[] ) => {
      setValue(newValue as number)
      const newAmt = (balObj.BTCavailableBal * value).toFixed(8)
      setWithdrawForm({
          ...withdrawForm,
          amount: Number(newAmt)
      })
    };
    // Slider ETH 
    const SliderhandleChangeETH = (event: any, newValue: number | number[] ) => {
        setValue(newValue as number)
        const newAmt = (balObj.ETHavailableBal * value).toFixed(8)
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(newAmt)
        })
      };
    //   Slider USDT 
      const SliderhandleChangeUSDT = (event: any, newValue: number | number[] ) => {
        setValue(newValue as number)
        const newAmt = (balObj.USDTavailableBal * value).toFixed(8)
        setWithdrawForm({
            ...withdrawForm,
            amount: Number(newAmt)
        })
      };
  

  
    const showQuickAmount = (asset:BalanceAsset) => {
        if (asset === BalanceAsset.BTC) {

            return (
                <div style={{marginTop:"10px"}}>
                    <Slider value={value} onChange={SliderhandleChangeBTC} min={0} max={1} step={0.01}></Slider>
                    <h3>{SliderShowNumber(value).toFixed(0)}%</h3>                    
                <div className="invest-amount-group g-2 pt-2">
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-0" onClick={() => handleBTCQuickAmt(0.1)}/>
                        <label className="invest-amount-label" htmlFor="iv-amount-0">10%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-1"  onClick={() => handleBTCQuickAmt(0.25)}  />
                        <label className="invest-amount-label" htmlFor="iv-amount-1">25%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2"  onClick={() => handleBTCQuickAmt(0.5)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-2">50%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3"  onClick={() => handleBTCQuickAmt(0.75)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-3">75%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4"  onClick={() => handleBTCQuickAmt(1)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-4">Max</label>
                    </div>
                </div>
                </div>  
            )
        } else if (asset === BalanceAsset.ETH) {
            return (
                <div style={{marginTop:"10px"}}>
                <Slider value={value} onChange={SliderhandleChangeETH} min={0} max={1} step={0.01}></Slider>
                <h3>{SliderShowNumber(value).toFixed(0)}%</h3>                    
                <div className="invest-amount-group g-2 pt-2">
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-0" onClick={() => handleETHQuickAmt(0.1)}/>
                        <label className="invest-amount-label" htmlFor="iv-amount-0">10%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-1"  onClick={() => handleETHQuickAmt(0.25)}  />
                        <label className="invest-amount-label" htmlFor="iv-amount-1">25%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2"  onClick={() => handleETHQuickAmt(0.5)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-2">50%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3"  onClick={() => handleETHQuickAmt(0.75)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-3">75%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4"  onClick={() => handleETHQuickAmt(1)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-4">Max</label>
                    </div>
                </div>
                </div>
            )
        } else if (asset === BalanceAsset.USDT) {
            return (
                <div style={{marginTop:"10px"}}>
                <Slider value={value} onChange={SliderhandleChangeUSDT} min={0} max={1} step={0.01}></Slider>
                <h3>{SliderShowNumber(value).toFixed(0)}%</h3>                    
                <div className="invest-amount-group g-2 pt-2">
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-0" onClick={() => handleUSDTQuickAmt(0.1)}/>
                        <label className="invest-amount-label" htmlFor="iv-amount-0">10%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-1"  onClick={() => handleUSDTQuickAmt(0.25)}  />
                        <label className="invest-amount-label" htmlFor="iv-amount-1">25%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2"  onClick={() => handleUSDTQuickAmt(0.5)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-2">50%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3"  onClick={() => handleUSDTQuickAmt(0.75)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-3">75%</label>
                    </div>
                    <div className="invest-amount-item">
                        <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4"  onClick={() => handleUSDTQuickAmt(1)} />
                        <label className="invest-amount-label" htmlFor="iv-amount-4">Max</label>
                    </div>
                </div>
                </div>
            )
        }
    }


    const getData = async () => {
        try {
            const getBal = await GetAllBalance()
            if(getBal){
                let balObjStore = {
                    ...balObj,
                    BTCamount:getBal.BtcBalance.balance,
                    BTChold:getBal.BtcBalance.hold,
                    BTCavailableBal:getBal.BtcBalance.availableBalance,
                    ETHamount:getBal.EthBalance.balance,
                    ETHhold:getBal.EthBalance.hold,
                    ETHavailableBal:getBal.EthBalance.availableBalance,
                    USDTamount:getBal.UsdtBalance.balance,
                    USDThold:getBal.UsdtBalance.hold,
                    USDTavailableBal:getBal.UsdtBalance.availableBalance,
                    USDamount:getBal.UsdBalance.balance,
                    USDhold:getBal.UsdBalance.hold,
                    USDavailableBal:getBal.UsdBalance.availableBalance,
                }
                setbalObj(balObjStore)
            }


        } catch (error) {
            console.error(error)
        } 
    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="nk-content nk-content-lg nk-content-fluid">
                <div className="container-xl wide-lg">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-lg">
                                <div className="nk-block-head-content">
                                    <div className="nk-block-head-sub">
                                        <a href="/borrow" className="back-to"><em className="icon ni ni-arrow-left"></em><span>{t('Back to Dashboard')}</span></a>
                                    </div>
                                    <div className="nk-block-head-content">
                                        <h2 className="nk-block-title fw-normal">{t('Withdraw Crypto Funds')}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block invest-block">
                                <form action="#" className="invest-form">
                                    <div className="row g-gs">
                                        <div className="col-lg-7">
                                            <div className="invest-field form-group">
                                                <label className="form-label">{t('Select which asset to withdraw')}</label>
                                                <div className="form-control-wrap">
                                                    <select onChange={handleAssetChange}
                                                        className="form-control">
                                                        <option value={BalanceAsset.BTC} key={BalanceAsset.BTC}>{`${t('btc_available',{defaultValue:"Bitcoin - BTC | Available Balance:"})} ${balObj.BTCavailableBal}`}</option>
                                                        <option value={BalanceAsset.ETH} key={BalanceAsset.ETH}>{`${t('eth_available',{defaultValue:"Ethereum - ETH | Available Balance:"})} ${balObj.ETHavailableBal}`}</option>
                                                        {/* <option value={BalanceAsset.USDT} key={BalanceAsset.USDT}>{`${t('usdt_available',{defaultValue:"Tether - USDT | Available Balance:"})} ${balObj.USDTavailableBal}`}</option> */}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="invest-field form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label">{t('Amount')}</label>
                                                    <div className="form-note pt-2">{t('Note:')} {showAvaliableAmount(withdrawForm.asset)}</div>
                                                </div>
                                                <div className="form-control-group">
                                                    <div className="form-info">{withdrawForm.asset}</div>
                                                    <input type="number" min={0} placeholder={String(withdrawForm.amount)} className="form-control form-control-amount form-control-lg" id="custom-amount" onChange={(e) => handleAmountChange(e)}/>                            
                                                </div>
                                                <div className="invest-field form-group">
                                                    {showQuickAmount(withdrawForm.asset)}
                                                    <div className="form-group pt-2">
                                                        <div className="form-label-group">
                                                            <label className="form-label">{t('Destination Wallet Address')} <span className="text-danger">* {errors.annualIncomeAmount && 'Must not be empty'}</span></label>
                                                        </div>
                                                        <div className="form-control-group">
                                                            <input type="text" placeholder={withdrawForm.destinationAddress} className="form-control form-control-lg" onChange={(e) => handleAddressChange(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <WithdrawDetails avaliableBTC={balObj.BTCavailableBal} avaliableETH={balObj.ETHavailableBal} avaliableUSDT={balObj.USDTavailableBal} withdrawAddress={withdrawForm.destinationAddress} withdrawAsset={withdrawForm.asset} withdrawAmt={withdrawForm.amount}  />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WithdrawPageCrypto;