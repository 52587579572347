import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LoanTicket, Repayment } from '../../../ApiService/loan';
import DialogAddressWithCopy from './DialogAddressWithCopy';

let DialogRepayTerminationWallet = ({
  ticket,
  terminationRepayment
}: {
  ticket: LoanTicket;
  terminationRepayment: Repayment;
}) => {
  const { t } = useTranslation(['Loan','Common'])
  return (
      <div className="modal fade" tabIndex={-1} id="modalRepayTermination">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body modal-body-md text-center">
              <div className="nk-modal">
              <h4 className="nk-modal-title">{t('Loan:dialogue_termination_crypto_header',{defaultValue:"Terminate loan (#{{loanID}}) to us", loanID:ticket.loanID})}</h4>
                {/* <h4 className="nk-modal-title">Send {terminationRepayment?.repaymentAmount} {terminationRepayment?.repaymentAsset} to the following address</h4> */}
                <div className="nk-modal-text">
                  <p className="sub-text">
                    <Trans
                      t={t}
                      i18nKey="dialogue_termination_crypto_msg" // optional -> fallbacks to defaults if not provided
                      defaults="Please make a payment of <bold> {{repaymentAmount}} {{repaymentAsset}} </bold> to the following wallet address before <bold> {{repaymentDate}} </bold> as full prepayment. Your collateral <bold> {{collateralAmount}} {{collateralAsset}}</bold> will be released once we have received your payment." // optional defaultValue
                      values={{ 
                        repaymentAmount:terminationRepayment.repaymentAmount, 
                        repaymentAsset:terminationRepayment.repaymentAsset, 
                        repaymentDate:moment(terminationRepayment?.repaymentDate).format("YYYY-MM-DD hh:mm A"),
                        collateralAmount: ticket.collateralAmount,
                        collateralAsset: ticket.collateralAsset,
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
                <DialogAddressWithCopy address={terminationRepayment.depositAddress}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default DialogRepayTerminationWallet;