import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext, UserVerificationStatus } from '../../contexts/appContext';
import { Trans, useTranslation } from 'react-i18next';

const KYCPage = () => {
    const {currentUser} = useContext(AppContext)
    const { t } = useTranslation('KYC')
        // const [userObj, setuserObj] = useState(
        //     {
        //         userId: '',
        //         email: '',
        //         verificationLevel: '',
        //         verificationStatus: ''
        //     }
        // )
        // const userObjStore = {
        //     userId: '',
        //     email: '',
        //     verificationLevel: '',
        //     verificationStatus: ''
        // }

        // useEffect(() => {
        //     let fgMounted = true;
        //     var configUser = {
        //         method: 'get',
        //         url: 'api/v1/user',
        //         headers: { 
        //           'Authorization': localStorage.firebaseToken,
        //           'Content-Type': 'application/json'
        //         }
        //     };

        //     axios(configUser).then(function(res) {
        //             // console.log(res.data);
        //             userObjStore.userId = res.data.userID
        //             userObjStore.email = res.data.email
        //             userObjStore.verificationLevel = res.data.verificationLevel
        //             userObjStore.verificationStatus = res.data.verificationStatus
        //             return userObjStore;
        //         }
        //     )
        //     .then(_ => guard(fgMounted)(setuserObj)(_))
        //     .catch(function(err) {
        //         console.log(err);
        //         }   
        //     )    
        //     return () => {fgMounted=false};    
        // }, [])


        const msgDisplay = (status:UserVerificationStatus) => {
            if (status === 'verified') {
                return t('KYC_title_done',{defaultValue:'KYC Verification Done'})
            } 
             else {
                return t('KYC_title',{defaultValue:"KYC Verification"})
            }
        }

        const icon = (status:UserVerificationStatus) => {
            if (status === 'verified') {
                return "icon ni ni-check"
            } else {
                return "icon ni ni-file"
            }
        }

        const msgBody = (status:UserVerificationStatus) => {
            if (status === 'verified') {
                // return 'You have successfully verified your identity. To take out a crypto backed loan, click on the button below.'
                return <Trans  t={t} i18nKey="kycFinishMsg">
                    You have successfully verified your identity. To take out a crypto backed loan, click on the button below.
                </Trans>
            } else {
                return <Trans  t={t} i18nKey="kycStartMsg">
                    To begin verifying your identity with us, click on the button below.
                </Trans>
            }
        }

        const msgBtn = (status:UserVerificationStatus) => {
            if (status === 'verified') {
                return  <Link to="/borrow" className="btn btn-lg btn-outline-success">{t('Click here to start borrowing')}</Link>
            } else {
                return  <Link to="/kyc/application"><button className="btn btn-lg btn-primary">{t('Start Verification')}</button></Link>
            }
        }

        return(
            <React.Fragment>
                    <></>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                    <div className="container-xl wide-lg">
                        <div className="nk-content-inner">
                            {
                                currentUser && 
                                <div className="nk-content-body">
                                    <div className="kyc-app wide-sm m-auto">
                                        <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                                            <div className="nk-block-head-content text-center">
                                                <h2 className="nk-block-title fw-normal">{msgDisplay(currentUser.verificationStatus)}</h2>
                                                <div className="nk-block-des">
                                                    <Trans  t={t} i18nKey="regulation">
                                                        <p>To comply with regulation each participant will have to go through indentity verification (KYC/AML) to comply with the rules and regulations laid out by the Money Lenders Ordinance.</p>
                                                    </Trans>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-block">
                                            <div className="card card-bordered">
                                                <div className="card-inner card-inner-lg">
                                                    <div className="nk-kyc-app p-sm-2 text-center">
                                                        {/* change depends on kyc status */}
                                                        <div className="nk-kyc-app-icon">
                                                            <em className={icon(currentUser.verificationStatus)}></em>
                                                        </div>
                                                        <div className="nk-kyc-app-text mx-auto">
                                                            {/* msg as guideline to show to user */}
                                                            <p className="lead">{msgBody(currentUser.verificationStatus)}</p>
                                                        </div>
                                                        <div className="nk-kyc-app-action">
                                                            {/* button to guideuser next step */}
                                                            {msgBtn(currentUser.verificationStatus)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
export default KYCPage;