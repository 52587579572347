import * as React from 'react'

import { GetAllLoanTicketsByType, LoanTicket, LoanTicketType, Pagination } from '../../../../ApiService/loan';
declare namespace Loan {
  export interface Props {
    view: React.ComponentType<{
      tickets: LoanTicket[];
      ticketCount: number;
      pagination: Pagination;
      setPagination: React.Dispatch<React.SetStateAction<Partial<Pagination>>>;
      dispatchOffset: React.Dispatch<'increment' | 'decrement'>;
      dispatchableOffset: (action: 'increment' | 'decrement') => boolean;
    }>;
  }
  // export type Pagination = import('./common').Pagination;
  // export type LoanTicket = import('./common').LoanTicket;
  // export type LoanTicketActive = import('./common').LoanTicketActive;
  // export type LoanTicketApplication = import('./common').LoanTicketApplication;
}
export const handleSelectLoanTypeInAPI = (type :LoanTicketType) => {
  switch(type){
    case LoanTicketType.Application:
      return LoanTicketType.Application
    default:return LoanTicketType.Active
  }
}
const Loan = (type: LoanTicketType, query?: string) => {
  let _ = (props: Loan.Props) => {
    const [tickets, setTickets] = React.useState<LoanTicket[]>([]);
    const [ticketCount, setTicketCount] = React.useState(0);
    const [pagination, _setPagination] = React.useState<Pagination>({ limit: 3, offset: 0 });
    let setPagination: React.Dispatch<React.SetStateAction<Partial<Pagination>>> = val => _setPagination(Object.assign({}, pagination, val))
    let updateOffset = (offset: Pagination['offset']) => (setPagination({offset}), offset)
    const [offset, dispatchOffset] = (
      React.useReducer((acc: Pagination['offset'], action: 'increment' | 'decrement') => (updateOffset({
        increment: acc + pagination.limit < ticketCount ? acc + pagination.limit : acc,
        decrement: Math.max(acc - pagination.limit, 0)
      }[action])), pagination.offset)
    )
    const handleSelectQueryByType = (type :LoanTicketType) => {
      if(type === LoanTicketType.Application){
        return loanApplicationQuery
      }else if(type === LoanTicketType.Active){
        return activeLoanQuery
      }else if(type === LoanTicketType.Completed){
        return completedLoanQuery
      }else{
        return () => '';
      }
    }

    let loanApplicationQuery = (query = '?state=new&state=funded&state=processing&state=closed') => [
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}&${key}=${pagination[key]}`, query),
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}${key}=${pagination[key]}${i != array.length - 1 ? '&' : ''}`, '?')
    ][0];
    let activeLoanQuery = (query = '?state=active&state=refinanced&state=defaulted') => [
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}&${key}=${pagination[key]}`, query),
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}${key}=${pagination[key]}${i != array.length - 1 ? '&' : ''}`, '?')
    ][0];
    let completedLoanQuery = (query = '?state=completed&state=liquidated') => [
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}&${key}=${pagination[key]}`, query),
      (Object.keys(pagination) as (keyof Pagination)[])
        .reduce((acc, key, i, array) => `${acc}${key}=${pagination[key]}${i != array.length - 1 ? '&' : ''}`, '?')
    ][0];
    // React.useEffect(() => {
    //   let fgMounted = true;
    //   fetcher
    //     .send(`/api/v1/loan/${type}/list/?state=new&state=funded&state=processing`)
    //     .then(res => res.json())
    //     .then(res => res as ResponseGetEntries<LoanTicket>)
    //     .then(({entries}) => entries ? entries.length : 0)
    //     .then(_ => guard(fgMounted)(setTicketCount)(_))
    //     .catch(console.error)
    //   return () => {fgMounted = false};
    // }, [1])
    const getData = async() =>{
      const res = await GetAllLoanTicketsByType(handleSelectLoanTypeInAPI(type), handleSelectQueryByType(type)(query))
      if(res){
        res?.total && setTicketCount(res?.total)
        res.entries && setTickets(res.entries)
      }
    }
    React.useEffect(() => {
      let fgMounted = true;
      getData()
      // GetAllLoanTicketsByType(handleSelectLoanTypeInAPI(type), handleSelectQueryByType(type))
      // fetcher
      //   .send(`/api/v1/loan/${handleSelectLoanTypeInAPI(type)}/list/${handleSelectQueryByType(type)}`)
      //   .then(res => res.json())
      //   .then(res => {
      //     let newRes = res as ResponseGetEntries<LoanTicket>
      //     newRes.total && setTicketCount(newRes.total)
      //     newRes.entries ?  setTickets(newRes.entries) : setTickets([])
      //   })
      //   // .then(({entries}) => entries || [])
      //   // .then(_ => guard(fgMounted)(setTickets)(_))
      //   .catch(console.error)
      return () => {fgMounted = false};
    }, [pagination]);
    let View = props.view;
    return (
      <View
        tickets={tickets ? tickets : []}
        ticketCount={ticketCount}
        setPagination={setPagination}
        pagination={pagination}
        dispatchOffset={dispatchOffset}
        dispatchableOffset={action => ({
          increment: pagination.offset + pagination.limit < ticketCount,
          decrement: !(pagination.offset - pagination.limit < 0)
        }[action])}
      />
    );
  };
  return _;
};
export default Loan