import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { GetAllCoinPriceInUSDRes } from "../../ApiService/shareInfo";
import { AllAssetBalance } from "../../ApiService/user";
import { UserBalanceSummarise } from "./DashboardPage";
import { useTranslation } from "react-i18next";
import { useFormatPrice } from "../../util/jacob/components/format";
import { CollateralAsset } from "../../ApiService/loan";
import ConfigAxio from "../../config/configAxios";

const CryptoTable = ({
  userBalanceSummarise,
  userBalance,
  coinMarketPrice,
}: {
  userBalanceSummarise: UserBalanceSummarise | undefined;
  userBalance: AllAssetBalance | undefined;
  coinMarketPrice: GetAllCoinPriceInUSDRes | undefined;
}) => {
  const { currentUserSetting } = useContext(AppContext);
  const { t } = useTranslation("Dashboard");
  const [BTCprice, setBTCprice] = useState<any>();
  const [ETHprice, setETHprice] = useState<any>();

  const GetExchangeBTCRate = async () => {
    const res = await ConfigAxio.get(
      `/api/v1/loan/exchangeRate?assetBase=HKD&assetQuote=BTC`
    );
    setBTCprice(res.data.rate);
    // console.log(res.data);
  };
  const GetExchangeETHRate = async () => {
    const res = await ConfigAxio.get(
      `/api/v1/loan/exchangeRate?assetBase=HKD&assetQuote=ETH`
    );
    setETHprice(res.data.rate);
    // console.log(res.data);
  };

  useEffect(() => {
    const getExchangeRate = setInterval(async() => {
      await GetExchangeBTCRate();
      await GetExchangeETHRate();
    }, 3000);
    return () => {
      clearInterval(getExchangeRate);
    };
  }, []);
  return (
    <div className="nk-block nk-block-lg">
      <div className="card card-bordered card-preview">
        <table className="table table-tranx">
          <thead>
            <tr className="tb-tnx-head">
              <th className="tb-tnx-id">
                <span className="">{t("Asset")}</span>
              </th>
              <th className="tb-tnx-info">
                <span className="tb-tnx-desc d-none d-sm-inline-block">
                  <span>{t("Price")}</span>
                </span>
                <span className="tb-tnx-date d-md-inline-block d-none">
                  <span className="d-md-none">{t("Balance")}</span>
                  <span className="d-none d-md-block">
                    <span>{t("Amount")}</span>
                    <span>{t("Locked")}</span>
                  </span>
                </span>
              </th>
              <th className="tb-tnx-amount is-alt">
                <span className="tb-tnx-total">{t("Total Value")}</span>
                <span className="tb-tnx-status d-none d-md-inline-block">
                  {t("Status")}
                </span>
              </th>
              <th className="tb-tnx-action">
                <span>&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="tb-tnx-item">
              <td className="tb-tnx-id">
                <a href="#">
                  <em className="ni ni-sign-btc-alt"></em>{" "}
                  <span>{t("Bitcoin - BTC")}</span>
                </a>


                {/* damage casino illegal range similar guitar between success sweet slight work gravity */}
                
              </td>
              <td className="tb-tnx-info">
                <div className="tb-tnx-desc">
                  <span className="title">
                    {useFormatPrice(
                      currentUserSetting.displayCurrency,
                      coinMarketPrice === undefined ? 0 : BTCprice,
                      true
                    )}
                  </span>
                </div>
                <div className="tb-tnx-date">
                  <span className="date">
                    {useFormatPrice(
                      CollateralAsset.BTC,
                      userBalance === undefined
                        ? 0
                        : userBalance.BtcBalance.balance,
                      true
                    )}
                  </span>
                  <span className="date">
                    {useFormatPrice(
                      CollateralAsset.BTC,
                      userBalance === undefined
                        ? 0
                        : userBalance.BtcBalance.hold +
                            userBalance.BtcBalance.pendingWithdraw,
                      true
                    )}
                  </span>
                </div>
              </td>
              <td className="tb-tnx-amount is-alt">
                <div className="tb-tnx-total">
                  <span className="amount">
                    {useFormatPrice(
                      currentUserSetting.displayCurrency,
                      userBalanceSummarise === undefined
                        ? 0
                        : userBalanceSummarise.totalBtcInUSD *
                            currentUserSetting.usdToCurrency,
                      true
                    )}
                  </span>
                </div>
                <div className="tb-tnx-status">
                  <span className="badge badge-dot badge-success">
                    {t("Confirmed")}
                  </span>
                </div>
              </td>
              <td className="tb-tnx-action">
                <div className="dropdown">
                  <a
                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                    <ul className="link-list-plain">
                      <li>
                        <a href="#">Withdraw</a>
                      </li>
                      <li>
                        <a href="#">History</a>
                      </li>
                      <li>
                        <a href="#">Details</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="tb-tnx-item">
              <td className="tb-tnx-id">
                <a href="#">
                  <em className="ni ni-sign-eth-alt"></em>{" "}
                  <span>{t("Ethereum - ETH")}</span>
                </a>
              </td>

              <td className="tb-tnx-info">
                <div className="tb-tnx-desc">
                  <span className="title">
                    {useFormatPrice(
                      currentUserSetting.displayCurrency,
                      coinMarketPrice === undefined ? 0 : ETHprice,
                      true
                    )}
                  </span>
                </div>
                <div className="tb-tnx-date">
                  <span className="date">
                    {useFormatPrice(
                      CollateralAsset.ETH,
                      userBalance === undefined
                        ? 0
                        : userBalance.EthBalance.balance,
                      true
                    )}
                  </span>
                  <span className="date">
                    {useFormatPrice(
                      CollateralAsset.ETH,
                      userBalance === undefined
                        ? 0
                        : userBalance.EthBalance.hold +
                            userBalance.EthBalance.pendingWithdraw,
                      true
                    )}
                  </span>
                </div>
              </td>
              <td className="tb-tnx-amount is-alt">
                <div className="tb-tnx-total">
                  <span className="amount">
                    {useFormatPrice(
                      currentUserSetting.displayCurrency,
                      userBalanceSummarise === undefined
                        ? 0
                        : userBalanceSummarise.totalEthInUSD *
                            currentUserSetting.usdToCurrency,
                      true
                    )}
                  </span>
                </div>
                <div className="tb-tnx-status">
                  <span className="badge badge-dot badge-success">
                    {t("Confirmed")}
                  </span>
                </div>
              </td>
              <td className="tb-tnx-action">
                <div className="dropdown">
                  <a
                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                    <ul className="link-list-plain">
                      <li>
                        <a href="#">Withdraw</a>
                      </li>
                      <li>
                        <a href="#">History</a>
                      </li>
                      <li>
                        <a href="#">Details</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            {/*
                        <tr className="tb-tnx-item">
                            <td className="tb-tnx-id">
                                <a href="#"><em className="ni ni-sign-usdt-alt"></em>    <span>{t('Tether - USDT')}</span></a>
                            </td>
                            
                            <td className="tb-tnx-info">
                                <div className="tb-tnx-desc">
                                    <span className="title">{useFormatPrice(currentUserSetting.displayCurrency, coinMarketPrice === undefined ? 0 : coinMarketPrice.usdtPrice * currentUserSetting.usdToCurrency, true)}</span>
                                </div>
                                <div className="tb-tnx-date">
                                    <span className="date">{useFormatPrice(CollateralAsset.USDT, userBalance === undefined ? 0 : userBalance.UsdtBalance.balance, true)}</span>
                                    <span className="date">{useFormatPrice(CollateralAsset.USDT, userBalance === undefined ? 0 : (userBalance.UsdtBalance.hold + userBalance.UsdtBalance.pendingWithdraw), true)}</span>
                                    
                                </div>
                            </td>
                            <td className="tb-tnx-amount is-alt">
                                <div className="tb-tnx-total">
                                    <span className="amount">{useFormatPrice(currentUserSetting.displayCurrency, userBalanceSummarise === undefined ? 0 : userBalanceSummarise.totalUsdtInUSD * currentUserSetting.usdToCurrency, true)}</span>
                                </div>
                                <div className="tb-tnx-status">
                                    <span className="badge badge-dot badge-success">{t('Confirmed')}</span>
                                </div>
                            </td>
                            <td className="tb-tnx-action">
                                <div className="dropdown">
                                    <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                        <ul className="link-list-plain">
                                            <li><a href="#">Withdraw</a></li>
                                            <li><a href="#">History</a></li>
                                            <li><a href="#">Details</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CryptoTable;
