export const Tx = {
    "type":"交易種類",
    "date":"交易日期",
    "amount":"交易金額",
    "asset":"資產種類",
    "tx_ref":"交易編號",

    'deposit': '存款',
    'withdraw': '提現',
    'interest': '利息',
    'interest_repayment': '貸款利息還款',
    'loan_payout': '批出貸款',
    'loan_repayment': '貸款還款',
    'loan_termination': '貸款終止',
    'liquidation': '貸款平倉',
    'fee': '手續費',
    'fee_reverse': '返還手續費',
    'manual_entry': '人工處理',
    'collateral_lock': '貸款抵押鎖定',
    'collateral_unlock': '貸款抵押解鎖',

    'pending_deposit': '存款（未確認）',
    'pending_deposit_reverse': '存款（已確認）',

    'pending_withdraw': '提現（未確認）',
    'pending_withdraw_reverse': '提現（已確認）',

    "crypto_transactions":"虛擬貨幣交易記錄",
    "number_of_tx":"您有{{total}}條交易記錄"
} 