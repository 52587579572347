import React, { useState, useContext } from 'react';
import { AppContext } from '../../../contexts/appContext';
import firebase from '../../../config/firebaseConfig';
import moment from 'moment';
import { css } from "@emotion/core";
import { LoanTicketActive, Repayment, RepaymentState, UploadLoanRepaymentTxIdentifier } from '../../../ApiService/loan';
import FirebaseFileUploader, { UploadedFile } from '../../FirebaseFileUploader';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import FirebaseUploadFileDisplayer from '../../FirebaseUploadFileDisplayer';
import { Trans, useTranslation } from 'react-i18next';
import { MoneyLenderBankAccount } from '../../../config/bankAccount'

const override = css`
  display: block;
`;

const DialogBankTerminationDeposit = ({
  terminationRepayment,
  ticket
}:{
  terminationRepayment: Repayment,
  ticket:LoanTicketActive
}) => {
  const { t } = useTranslation(['Loan','Common'])
  const { currentUser} = useContext(AppContext);
  const [depositSlip, setDepositSlip] = useState<UploadedFile>()
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const processDepositSlip = async (fileList:UploadedFile[]) =>{

    setUploadSuccess(false)
    setUploading(false)

    if(fileList.length > 0){
      setDepositSlip(fileList[0])
    }
  }

  const handleConfirmUpload = async(e:React.MouseEvent<HTMLElement>) =>{
    e.preventDefault()
    if(depositSlip){
      setUploadSuccess(false)
      setUploading(true)
      const res = await UploadLoanRepaymentTxIdentifier(terminationRepayment.repaymentID, JSON.stringify(depositSlip))
      if(res.success){
        setUploadSuccess(true)
      }else{
        alert('Fail to upload the deposit slip. Please contact our support team.')
      }
      setUploading(false)
    }
  }
  // modalRepayTermination
  return (
    <div className="modal fade" tabIndex={-1} id="modalRepayTermination">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <a href="#" className="close" data-dismiss="modal" aria-label="Close">
            <em className="icon ni ni-cross"></em>
          </a>
          <div className="modal-header">
            <h5 className="modal-title">{t('Loan:dialogue_termination_bank_header',{defaultValue:"Terminate Loan (#{{loanID}})", loanID:terminationRepayment.loanID})}</h5>
          </div>
          <div className="modal-body">
            <p>
            <Trans
              t={t}
              i18nKey="dialogue_termination_crypto_msg" // optional -> fallbacks to defaults if not provided
              defaults="Please make a payment of <bold> {{repaymentAmount}} {{repaymentAsset}} </bold> to the following bank account before <bold> {{repaymentDate}} </bold> as full prepayment. Your collateral <bold> {{collateralAmount}} {{collateralAsset}}</bold> will be released once we have received your payment." // optional defaultValue
              values={{ 
                repaymentAmount:terminationRepayment.repaymentAmount, 
                repaymentAsset:terminationRepayment.repaymentAsset, 
                repaymentDate:moment(terminationRepayment?.repaymentDate).format("YYYY-MM-DD hh:mm A"),
                collateralAmount: ticket.collateralAmount,
                collateralAsset: ticket.collateralAsset,
              }}
              components={{ bold: <strong /> }}
            />
            </p>
            {/* <p>Please make a payment of <strong>{terminationRepayment.repaymentAmount} {terminationRepayment.repaymentAsset.toUpperCase()}</strong> to us by sending to the following bank account:</p> */}
          </div>
          <table className="table table-bordered">
            <tbody>
            <tr>
                <td>{t('Common:bank_location',{defaultValue:"Bank Location"})}</td>
                <td style={{width:"70%"}}>{MoneyLenderBankAccount.bankLocation}</td>
                {/* this 70% will limit whole table */}
              </tr>
              <tr>
                <td>{t('Common:bank_name',{defaultValue:"Bank Name"})}</td>
                <td>{MoneyLenderBankAccount.bankName}</td>
              </tr>
              <tr>
                <td>{t('Common:account_holder_name',{defaultValue:"Account Holder Name"})}</td>
                <td>{MoneyLenderBankAccount.accountHolderName}</td>
              </tr>
              <tr>
                <td>{t('Common:account_number',{defaultValue:"Account Number"})}</td>
                <td>{MoneyLenderBankAccount.accountNumber}</td>
              </tr>
              {
                MoneyLenderBankAccount.fpsID &&
                <tr>
                  <td>{t('Common:fps_number',{defaultValue:"FPS ID"})}</td>
                  <td>{MoneyLenderBankAccount.fpsID}</td>
                </tr>
              }
              <tr>
                <td>{t('Common:amount',{defaultValue:"Amount"})}</td>
                <td><strong>{terminationRepayment.repaymentAmount} {terminationRepayment.repaymentAsset.toUpperCase()}</strong></td>
              </tr>
            </tbody>
          </table>
          <div style={{justifyContent: "flex-start", flexDirection: "column"}} className="modal-footer bg-light">
            
            {
              //  && terminationRepayment.txIdentifier === ''
              (terminationRepayment.state === RepaymentState.Active || terminationRepayment.state === RepaymentState.Overdue  && terminationRepayment.txIdentifier === '') ?
              // (terminationRepayment.state === RepaymentState.Active || terminationRepayment.state === RepaymentState.Overdue || terminationRepayment.state === RepaymentState.Processing) ?
              <>
                <span className="sub-text">{t('Loan:dialogue_termination_bank_upload_reminder',{defaultValue:"Once you have made a payment to us, upload your bank transfer slip here"})}</span>
                <FirebaseFileUploader
                  multiple = {false} //use this to controll allow multiple or not 
                  processURLFunction={processDepositSlip}
                  maxNumberOfFiles={1} //if multiple, maxNo = ?, set to 0 for inf pic to upload
                  accept={".jpg,.jpeg,.png,.pdf"}
                  fileNamePrefix={`user-${currentUser?.userID}-depositSlip-${moment(Date.now()).format("YYYY-MM-DD")}`}
                  fireStorageRef={firebase.storage().ref('depositSlips')}
                  // disableDownloadURL
                  withPreview
                />

                {/* confirm upload */}
                {
                  depositSlip && 
                  <>
                    {
                      uploading ? 
                        <ClipLoader
                          css={override}
                          size={21}
                          color={"#366CD7"}
                        /> 
                      :
                      uploadSuccess ?
                        <React.Fragment>
                            <p style={{marginTop: "20px", display: "flex"}} className="text-success"> {t('Loan:dialogue_termination_bank_upload_success', {defaultValue:"Upload successful, You may close this dialog now"})}</p>
                            <button data-dismiss="modal" className="btn btn-lg btn-mw btn-primary" onClick={()=>{window.location.reload()}}>{t('Common:Close', {defaultValue:"Close"})}</button>
                        </React.Fragment>
                      :
                        <Link to="" className="btn btn-lg btn-mw btn-primary" onClick={handleConfirmUpload}>{t('Common:Confirm', {defaultValue:"Confirm"})}</Link>
                    }
                  </>
                }
                
              </>
              :
              <>
                <FirebaseUploadFileDisplayer file={terminationRepayment.txIdentifier as unknown as UploadedFile}/>
                <span className="sub-text">{t('Loan:dialogue_termination_bank_processing', {defaultValue:"Your Repayment is processing, Please come back later, Thank you."})}</span>
                <button data-dismiss="modal" className="btn btn-lg btn-mw btn-primary" >{t('Common:Close', {defaultValue:"Close"})}</button>
              </>
            }

            
          </div>
        </div>
      </div>
    </div>
  )
}

export default DialogBankTerminationDeposit;
