import ConfigAxio from '../../config/configAxios'
import { handleSelectLoanTypeInAPI } from '../../util/jacob/components/MyLoans/Loan';
import { ApiGlobalResponse, defaultApiRes, SetErrorInApiRes } from '../shareInfo';

export interface QuoteBorrowInterestRateRes{
    rate:number
}

export interface Pagination {
    limit: number;
    offset: number;
}
export enum BorrowAsset {
    USD="USD",
    HKD="HKD",
    USDT="USDT",
}
export enum CollateralAsset {
    BTC="BTC",
    ETH="ETH",
    USDT="USDT",
}
export interface GetLTVsRes{
    ltvs:LTV[]
}
export interface LTV{
    asset: BorrowAsset;
    period: number;
    ltv: number;
    rate: number;
}
export interface LoanTicketActive {
    loanID: string;
    userID: number;
    borrowAsset: BorrowAsset;
    borrowAmount: number;
    borrowAmountUsd: number;
    collateralAsset: CollateralAsset;
    collateralAmount: number;
    repaymentAsset: BorrowAsset;
    loanTerm: number;
    LTV: number;
    currentLTV: number;
    interestRate: number;
    totalInterestPayment: number;
    totalOutstanding: number;
    state: 'active' | 'completed';
    lastUpdated: string;
    depositAddress: string;
    price60PC: number;
    price70PC: number;
    price80PC: number;
    collateralPrice: number;
    fundedAmount: number;
    fundedAmountUsd: number;
    loanInitiationDate: string;
    maturityDate: string;
    fullRepayDate: string;
    requestDate: string;
    dailyInterest:number;
    amountRepaid: number;
    terminationFee: number;
    voidTerminationFee: boolean;
    totalPaymentForTermination: number;
    apr: number;



}
export enum DocuSignStatus {
    New = "new",
    Completed = "completed",
    Overdue = "overdue",
    Cancelled = "cancelled"
}
export interface LoanDocuSign {
loanID: string;
docuSignURL : string;
borrowerSignStatus: DocuSignStatus,
adminSignStatus: DocuSignStatus,
    agreementAcceptanceDate: Date,  
}
export interface LoanTicketApplication {
    loanID: string;
    userID: number;
    borrowAsset: BorrowAsset;
    borrowAmount: number;
    borrowAmountUsd: number;
    collateralAsset: CollateralAsset;
    collateralAmount: number;
    repaymentAsset: BorrowAsset;
    loanTerm: number;
    LTV: number;
    interestRate: number;
    totalInterestPayment: number;
    totalOutstanding: number;
    state: 'new' | 'funded' | 'processing' | 'processed' | 'closed';
    lastUpdated: string;
    depositAddress: string;
    price60PC: number;
    price70PC: number;
    price80PC: number;
    collateralPrice: number;
    fundedAmount: number;
    fundedAmountUsd: number;
    requestDate: string;
    approvedDate?: string;
    fundReleaseDate?: string;

    dailyInterest:number;
    terminationFee: number;
    txRef?: string;
    txProofURL?: string;
}
export type LoanTicket = LoanTicketActive | LoanTicketApplication;
export enum LoanTicketType{
    Application = "application",
    // ApplicationHistory = "applicationHistory",
    Active = "active",
    Completed = "completed",
}
export enum RepaymentState{
    Active = "active",
    Paid = "paid",
    Cancelled = "cancelled",
    Pending = "pending",
    Overdue = "overdue",
    Processing = "processing",
}
export enum RepaymentType{
    Interest = "interest",
    Principle = "principle",
    Termination = "termination",
    Early = "early",
}
export interface Repayment {

    repaymentID: string;
    repaymentUID: string;
    repaymentTerm: number;
    loanID: string;
    userID: number;
    repaymentAmountUsd: number;
    repaymentAsset: BorrowAsset;
    repaymentAmount: number;
    repaymentDate: string;
    actualRepaymentDate: string;
    actualRepaymentAmount: number;
    actualRepaymentAsset: string;
    differenceAmount: number;
    latePaymentToleranceDays: number;
    latePaymentLiquidationTimestamp: string;
    latePaymentLiquidationAsset: string;
    latePaymentLiquidationAmount: number;
    state: RepaymentState
    // depositTicketID: number;
    depositAddress: string;
    fundedAmount: number;
    repaymentType: RepaymentType
    txRef: string;
    txIdentifier: string;
}
    type AmountLedgerType = 'deposit' | 'withdraw' | 'interest' | 'interest_repayment' | 'loan_payout' | 'loan_repayment' |'loan_termination'| 'liquidation' | 'fee' | 'fee_reverse' | 'manual_entry';
    type HoldLedgerType = 'collateral_lock' | 'collateral_unlock' | 'liquidation' | 'manual_entry';
    type PendingDepositLedgerType = 'deposit' | 'deposit_reverse';
    type PendingWithdrawLedgerType = 'withdraw' | 'withdraw_reverse';
    
    export interface Transaction {
    transactionID: string;
    userID: number;
    asset: CollateralAsset;
    amount: number;
    transactionTarget: 'amount' | 'hold' | 'pending_deposit' | 'pending_withdraw';
    transactionType: AmountLedgerType | HoldLedgerType | PendingDepositLedgerType | PendingWithdrawLedgerType;
    timestamp: string;
}

// export type ResponseGetEntries<
// TEntry extends any
// > = Partial<{
// entries: TEntry[];
// total: number;
// limit: number;
// offset: number;
// }>;
export interface ResponseGetEntries<TEntry> {
    entries:TEntry[];
    total: number;
    limit: number;
    offset: number;
}

export const QuoteBorrowInterestRate = async(asset:BorrowAsset) =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/borrowInterestRate?asset=${asset}&ltv=0.5`)
        let tempRes = <QuoteBorrowInterestRateRes>res.data
        return tempRes
    } catch (error) {
        return undefined
    }
}

export const GetLTVs = async(asset:BorrowAsset) =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/ltv/?asset=${asset}`)
        let tempRes = <GetLTVsRes>res.data
        return tempRes.ltvs
    } catch (error) {
        return []
    }
}


export const GetAllLoanTicketsByType = async(type:LoanTicketType, query:string) =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/${handleSelectLoanTypeInAPI(type)}/list/${query}`)
        let data = <ResponseGetEntries<LoanTicket>>res.data
        return {
            entries: data.entries,
            total: data.total
        }
    } catch (error) {
        return undefined
    }
}

// export const GetLoanTicketByID = async(loanID:string|number, type:LoanTicketType) =>{
//     try {
//         const res = await ConfigAxio.get(`/api/v1/loan/${handleSelectLoanTypeInAPI(type)}/?loanID=${loanID}`)
//         let ticket = <Partial<LoanTicket>>res.data

//         // Partial<LoanTicket>
//         return ticket
//     } catch (error) {
//         return undefined
//     }
// }

export const GetLoanTicketByID = async(loanID:string|number, type:LoanTicketType) =>{
    let apiRes:ApiGlobalResponse<LoanTicket>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/${handleSelectLoanTypeInAPI(type)}/?loanID=${loanID}`)
        // let ticket = <Partial<LoanTicket>>res.data
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 
    } catch (error) {
        SetErrorInApiRes(error, apiRes)
    }
    return apiRes
}


export const GetDocuSignInfoByLoanID = async(loanID:string|number) =>{
    let apiRes:ApiGlobalResponse<LoanDocuSign>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/docuSign/?loanID=${loanID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 

    } catch (error) {
        SetErrorInApiRes(error, apiRes)
    }
    return apiRes
}

export const GetAllRepayments = async() =>{	
    let apiRes:ApiGlobalResponse<ResponseGetEntries<Repayment>>={
        ...defaultApiRes
    }
    try {	
        const res = await ConfigAxio.get('/api/v1/loan/repayment/list')	
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 

    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}	

export const GetAllActiveLoans = async() =>{	
    try {	
        const res = await ConfigAxio.get('/api/v1/loan/active/list')	
        // return res	
        let data = <ResponseGetEntries<LoanTicketActive>>res.data
        return data.entries
    } catch (error) {	
        return []		
    }	
}

export const GetRepaymentByRepaymentID = async(repaymentID:string|number) =>{
    let apiRes:ApiGlobalResponse<ResponseGetEntries<Repayment>>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/repayment/?repaymentID=${repaymentID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 

    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}

export const GetRepaymentsByLoanID = async(loanID:string|number) =>{
    let apiRes:ApiGlobalResponse<ResponseGetEntries<Repayment>>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/repayment/?loanID=${loanID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 

    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}

export const GetNextRepaymentByLoanID = async(loanID:string|number) =>{
    let apiRes:ApiGlobalResponse<Repayment>={
        ...defaultApiRes
    }
    try {
        // Not(map[string]interface{}{"state": []string{"paid", "cancelled"}}).
        // Get loan's next repayment that not paid / cancelled, so it will also show overdued repayment
        const res = await ConfigAxio.get(`/api/v1/loan/repayment/loan/next/?loanID=${loanID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 

    }  catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}

// export const GetFinalRepayment = async(loanID:string|number) =>{
//     try {
//         const res = await ConfigAxio.get(`/api/v1/loan/repayment/loan/final/?loanID=${loanID}`)
//         let data = <Partial<Repayment>>res.data
//         return data
//     } catch (error) {
//         return undefined
//     }
// }

export const GetTerminationRepaymentByLoanID = async(loanID:string|number) =>{
    let apiRes:ApiGlobalResponse<Repayment>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/repayment/loan/termination?loanID=${loanID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 
    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}

export const GetTransactionsByLoanID = async(loanID: string) =>{
    let apiRes:ApiGlobalResponse<ResponseGetEntries<Transaction>>={
        ...defaultApiRes
    }
    try {
        const res = await ConfigAxio.get(`/api/v1/transactions/all/list/?limit=${0}&offset=${0}&taskType=loan&taskID=${loanID}`)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 
    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}

export const UploadLoanRepaymentTxIdentifier = async(repaymentID:string|number, TxIdentifier:string) =>{
    let apiRes:ApiGlobalResponse<any>={
        ...defaultApiRes
    }
    try {
        let data = {
            repaymentID: repaymentID,
            TxIdentifier: TxIdentifier
        }
        const res = await ConfigAxio.post(`/api/v1/loan/repayment/loan/updateTxIdentifier`,data)
        apiRes.statusCode = res.status
        apiRes.apiData = res.data 
    } catch (error) {	
        SetErrorInApiRes(error, apiRes)	
    }	
    return apiRes
}
// export const GetNextRepayment = async() =>{	
//     try {	
//         const res = await ConfigAxio.get('/api/v1/loan/repayment/list')	
//         return res	
//     } catch (error) {	
//         return false	
//     }	
// }	

// export const GetActiveLoans = async() =>{	
//     try {	
//         const res = await ConfigAxio.get('/api/v1/loan/active/list')	
//         return res	
//     } catch (error) {	
//         return false	
//     }	
// }

export const RefreshDocuSignURLByLoanID = async(loanID:string|number) =>{
    try {
        const res = await ConfigAxio.post(`/api/v1/loan/docuSignNewURL/?loanID=${loanID}`)
        let info = <LoanDocuSign>res.data

        // Partial<LoanTicket>
        return info
    } catch (error) {
        return undefined
    }
}

export const ApplyLoanTermination = async(loanID:string|number) =>{
    try {
        const res = await ConfigAxio.post(`/api/v1/loan/terminate`,{
            loanID
        })
        return true
    } catch (error) {
        return undefined
    }
}