import React, { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import DialogAddressWithCopy from "./DialogAddressWithCopy";
import moment from "moment";

// import Countdown from 'react-countdown';
import QRCode from "react-qr-code";
import LoanDetailApplicationStatus from "../loanDetailComponents/LoanDetailApplicationStatus";
import {
  DocuSignStatus,
  GetLoanTicketByID,
  LoanTicketType,
  RefreshDocuSignURLByLoanID,
  GetDocuSignInfoByLoanID,
  LoanDocuSign,
  LoanTicketApplication,
} from "../../../ApiService/loan";
import Countdown from "react-countdown";

let DialogWallet = (props: {
  loanTicket: LoanTicketApplication | undefined;
  docuSignInfo: LoanDocuSign | undefined;
  // timer: any;
  fgShown: boolean;
  setFgShown: React.Dispatch<React.SetStateAction<boolean>>;
  borrowAmount: number;
  borrowAsset: string;
  collateralAmount: number;
  collateralAsset: string;
  depositAddress: string;
}) => {
  const { t } = useTranslation(["Loan", "Common"]);

  const showCounter = (datetime: string) => {
    let dueDate = new Date(datetime).getTime();
    return dueDate;
  };

  let QRCodeSize: number = 150;

  return (
    <div className="modal fade" tabIndex={-1} id="invest-plan">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body modal-body-md text-center">
            <div className="nk-modal">
              <h1>
                {props.loanTicket && props.docuSignInfo && (
                  <Countdown
                    date={
                      showCounter(
                        moment(props.loanTicket.requestDate).format()
                      ) +
                      60 * 60 * 24 * 1000
                    }
                  />
                )}
              </h1>
              <h4 className="nk-modal-title">
                {t("Loan:send_coins_to_address", {
                  defaultValue:
                    "Send {{amount}} {{coin}} to the following address",
                  amount: props.collateralAmount,
                  coin: props.collateralAsset,
                })}
              </h4>
              <div className="nk-modal-text">
                <p>
                  <Trans
                    t={t}
                    i18nKey="dialogue_wallet_msg" // optional -> fallbacks to defaults if not provided
                    defaults="Your loan <bold> {{borrowAmount}} {{borrowAsset}} </bold> will be released once we have received your payment of <bold> {{collateralAmount}} {{collateralAsset}} </bold> to this wallet address: " // optional defaultValue
                    values={{
                      borrowAmount: props.borrowAmount,
                      borrowAsset: props.borrowAsset,
                      collateralAmount: props.collateralAmount,
                      collateralAsset: props.collateralAsset,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>

              <div style={{ marginBottom: "28px" }}>
                <QRCode
                  value={props.depositAddress}
                  size={QRCodeSize}
                  style={{ maxHeight: 50, maxWidth: 50 }}
                ></QRCode>
              </div>
              <DialogAddressWithCopy address={props.depositAddress} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DialogWallet;
