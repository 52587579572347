import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { LoanTicket, LoanTicketActive, LoanTicketApplication, LoanTicketType } from "../../../ApiService/loan"
import { useFormatPrice } from "../../../util/jacob/components/format"

const LoanTicketSimple = ({
    ticket,
    loanTicketType
  }:{
    ticket:LoanTicket,
    loanTicketType:LoanTicketType
  }) =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
      <>
        <div className="nk-iv-scheme-item">
          <span className="amount" children={ticket.loanID} />
          <div className="nk-iv-scheme-icon is-running">
            <em className="icon ni ni-update"></em>
          </div>
          <div className="nk-iv-scheme-info">
            <div className="nk-iv-scheme-name">{`${ticket.borrowAsset} - ${t('Common:APR',{defaultValue:"APR"})} ${(ticket.interestRate * 1e2).toFixed(2)}% - ${t('Common:Months',{defaultValue:"{{months}} Months", months:ticket.loanTerm})}`}</div>
            <div className="nk-iv-scheme-desc">{t('Loan:borrow_amount',{defaultValue:"Borrow Amount"})} - <span className="amount">{useFormatPrice(ticket.borrowAsset, ticket.borrowAmount, true)}</span></div>
          </div>
          <div className="nk-iv-scheme-term">{
            <div className="nk-iv-scheme-order" key={"Application Status"}>
              <span className="nk-iv-scheme-label text-soft">{ticket.state}</span>
              <span className="nk-iv-scheme-value date">{t('Loan:application_status',{defaultValue:"Application Status:"})}</span>
            </div>
          }</div>
          <div className="nk-iv-scheme-amount">
            <div className="nk-iv-scheme-amount-a nk-iv-scheme-order">
              <span className="nk-iv-scheme-label text-soft">{t('Loan:total_interest',{defaultValue:"Total Interest Payment"})}</span>
              <span className="nk-iv-scheme-value amount">{useFormatPrice(ticket.borrowAsset, ticket.totalInterestPayment, true)}</span>
            </div>
            <div className="nk-iv-scheme-amount-b nk-iv-scheme-order">
              <span className="nk-iv-scheme-label text-soft">{t('Loan:total_outstanding',{defaultValue:"Total Outstanding"})}</span>
              <span className="nk-iv-scheme-value amount">{useFormatPrice(ticket.borrowAsset, ticket.totalOutstanding, true)}</span>
            </div>
          </div>
          <div className="nk-iv-scheme-more">
            <Link className="btn btn-icon btn-lg btn-round btn-trans" to={`/loan/${loanTicketType}/details/${ticket.loanID}`} children={<em className="icon ni ni-forward-ios" />} />
          </div>
          <div className="nk-iv-scheme-progress">
            <div className="progress-bar" data-progress="25"></div>
          </div>
        </div>
      </>
    )
  }
    const LoanTicketSimples = ({
        title,
        tickets,
        loanTicketType
    }:{
        title:string
        tickets:LoanTicket[]
        loanTicketType:LoanTicketType
    }) =>{
        return(
            <>
                <div className="nk-block nk-block-lg">
                    <div className="nk-block-head-sm">
                    <div className="nk-block-head-content">
                        <h4 className="nk-block-title fw-normal">{title} <span className="count text-base" children={`(${tickets.length})`} /></h4>
                    </div>
                    <div className="nk-iv-scheme-list">
                        {/* ticket list */}
                        {
                        tickets.map((ticket, i) =>{
                            return <LoanTicketSimple 
                              ticket={ticket} 
                              loanTicketType = {loanTicketType}
                              key={i}
                            />
                        })
                        }
                    </div>
                    </div>
                </div>
            </>
        )
}

export {
    LoanTicketSimple,
    LoanTicketSimples
}