export const Common = {
    "HKD":"港币",
    "USD":"美元",
    "USDT":"泰达币",
    "BTC":"比特币",
    "ETH":"以太币",
    "APR":"年利率",
    "LTV":"贷款抵押比例",
    "Yes":"是",
    "No":"否",
    "Days":"{{days}} 日",
    "Months":"{{months}} 个月",
    "Years":"{{years}} 年",
    "Confirm":"确认",
    "Close":"关闭",
    "Cancel":"取消",

    "page_go_back":"返回",
    "copy_to_clipboard":"复制到剪贴板",
    "ask_for_support":"如有问题，请联络<mailtag>{{email}}</mailtag>",

    "bank_name":"银行名称",
    "bank_location":"银行地址",
    "account_holder_name":"账户持有人名称",
    "account_number":"账户号码",
    "amount":"金额",
    "fps_number":"转数快 ID",
    
    "Terms & Conditions": "条款及细则",
}