import {
  compose,
  composePromise
} from './lambda'
export declare namespace Http {
  export type Request = Parameters<typeof fetch>[1] extends infer TRequest ? (
    TRequest extends undefined ? never : TRequest
  ) : never
  export interface RequestInterceptor {
    (req: Request): Promise<Request>;
  }
  export interface Fetcher {
    applyRequestInterceptors(
      ...interceptors: RequestInterceptor[]
    ): this;
    send: typeof fetch;
  }
  
}
export const createHttpFetcher: () => Http.Fetcher = () => {
  let reqInterceptors = [] as Http.RequestInterceptor[];
  let p: Http.Fetcher = {
    applyRequestInterceptors: (...interceptors) => (
      reqInterceptors.push.apply(reqInterceptors, interceptors),
      p
    ),
    send: (input, init) => (
      composePromise(...reqInterceptors.reverse())({})
        .then(req => req as Http.Request)
        .then(req => (
          init = init || {},
          init = Object.assign(req, init),
          fetch(input, init)
        ))
    )
  };
  return p;
};