import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import BankInfo from '../../util/jacob/components/Borrow/BankInfo'
import styles from "./detail.module.css"
import { useTranslation } from 'react-i18next';

declare namespace BankAccountDetails {
  export interface Props {
    setState: React.Dispatch<React.SetStateAction<BankInfo>>;
    complete: boolean;
  }
}

enum BankTransferMethod {
  FPS = "FPS",
  BankAccount = "BankAccount",
}
function BankAccountDetails(props: BankAccountDetails.Props) {
  const { t } = useTranslation(['Borrow','Common'])
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data: BankInfo) => {
    // console.log(data)
    if(bankTransferMethod === BankTransferMethod.FPS){
      data.accountNumber = "FPS:" + data.accountNumber
    }
    props.setState(data)
  };
  let defaultValue:BankInfo = {
    accountType: 'savings'
  }
  const [bankTransferMethod, setBankTransferMethod] = useState<BankTransferMethod>(BankTransferMethod.BankAccount)
  const disMsg = (status :boolean) => {
    if (status) {
      return (<p className="text-success">{t('Bank information has been confirmed, please proceed to obtain a loan quote and continue.')}</p>)
    }
  }
  // let setValues: typeof _setValues = newValues => _setValues(Object.assign({}, values, newValues))
  return (
    <div className="col-lg-7">
      {/* <form id="testing123" onSubmit={handleSubmit(onSubmit)}> */}
        <div className="invest-field form-group">
        <h4>{t('Borrow:hkd_borrow_page_step2_title', {defaultValue:"Step 2: Enter your Bank Account Details"})}</h4>
          <p>{t('We will release the loan to this bank account.')}</p>
          <h6 className="pt-4">{t('Bank Account Information')}</h6>
          <div className="row gy-4">

            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="default-05">{t('Bank Name')}</label>
                <div className="form-control-wrap">
                  <input 
                    className="form-control" type="text" name='bankName' placeholder="eg: Bank of Communications"
                    // onChange={event => setValues({bankName:event.target.value})}
                    ref={register({ required: true })}
                  />
                  {errors.bankName && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="default-05">{t('Bank Transfer Method')}</label>
                <div className="form-control-wrap">
                  <select
                    className="form-control"
                    name="accountType"
                    onChange={e=>setBankTransferMethod(e.currentTarget.value as BankTransferMethod)}
                    // onChange={event => setValues({accountType:event.target.value})}
                    ref={register({ required: true })}
                    defaultValue={defaultValue.accountType}
                  >
                    <option value={BankTransferMethod.BankAccount}>{t('BankAccount')}</option>
                    <option value={BankTransferMethod.FPS}>{t('FPS')}</option>
                  </select>
                  {errors.accountType && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                </div>
              </div>
            </div>

            {
              bankTransferMethod === BankTransferMethod.BankAccount &&
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="default-textarea">{t('Bank Account Number')}</label>
                  <div className="form-control-wrap">
                    <input 
                      type="text" className="form-control" name='accountNumber' placeholder="eg: 111-111-1-1111-1"
                      // onChange={event => setValues({bankAddress:event.target.value})}
                      ref={register({ required: true })}
                    />
                    {errors.accountNumber && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                  </div>
                </div>
              </div>

              
            }

            {
              bankTransferMethod === BankTransferMethod.FPS &&
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="default-textarea">{t('FPS ID / Email / Phone Number / Bank Account')}</label>
                  <div className="form-control-wrap">
                    <input 
                      type="text" className="form-control" name='accountNumber' placeholder="eg: 11112222"
                      // onChange={event => setValues({bankAddress:event.target.value})}
                      ref={register({ required: true })}
                    />
                    {errors.accountNumber && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                  </div>
                </div>
              </div>
            }


            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="default-01">{t('Account Holder Full Name (English)')}</label>
                <div className="form-control-wrap">
                  <input 
                    type="text" className="form-control" name='accountHolderName' placeholder="eg: Chan Siu Ming" 
                    // onChange={event => setValues({accountHolderName:event.target.value})}
                    ref={register({ required: true })}
                  />
                  {errors.accountHolderName && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="default-01">Communication Address</label>
                <div className="form-control-wrap">
                  <input 
                    type="text" className="form-control" name='accountHolderAddress' placeholder="eg: flat 2, 21/F, 3 Caine Road, Mid-Levels, Hong Kong" 
                    // onChange={event => setValues({accountHolderAddress:event.target.value})}
                    ref={register({ required: true })}
                  />
                  {errors.accountHolderAddress && <span className={styles.errorMsg}>This field is required</span>}
                </div>
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="default-05">{t ('Account Type')}</label>
                <div className="form-control-wrap">
                  <select
                    className="form-control"
                    name="accountType"
                    // onChange={event => setValues({accountType:event.target.value})}
                    ref={register({ required: true })}
                    defaultValue={defaultValue.accountType}
                  >
                    <option value="savings">{t('Savings')}</option>
                    <option value="current">{t('Current')}</option>
                  </select>
                  {errors.accountType && <span className={styles.errorMsg}>{t('This field is required')}</span>}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="default-05">{t('Comments / Special Instructions')}</label>
                <div className="form-control-wrap">
                  <input 
                    type="text" className="form-control" name='comments' placeholder="eg: Optional"
                    ref={register}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-iv-wg4-sub text-center bg-lighter">
            <button 
              className="btn btn-lg btn-primary ttu" 
              // onClick={event => (
              //   event.preventDefault(), 
              //   // props.setState(values)
              //   checkBankInfo()
              // )}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              disabled={props.complete}
              children={t('Confirm Bank Account Details')}
            />
            {/* <input type="submit" onClick={handleSubmit(onSubmit)}/> */}
        {disMsg(props.complete)}
        </div>
      {/* </form> */}
    </div>
  );
}
export default BankAccountDetails
export {
  BankAccountDetails
}