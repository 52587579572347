import {
  createHttpFetcher
} from './core/fetch'
const fetcher = (
  createHttpFetcher()
    .applyRequestInterceptors(
      req => {
        let token = localStorage.getItem('firebaseToken');
        req.headers = {
          'Content-Type': 'application/json',
          "Authorization": token ? `Bearer ${token}` : ''
        };
        return Promise.resolve(req);
      },
      /*
      req => {
        req.headers = {
          'Content-Type': 'application/json',
          "Authorization": 'admin'
        };
        return Promise.resolve(req);
      }
      */
    )
);
export default fetcher