import React, { useEffect, useRef, useState } from 'react';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { GetLoanTicketByID, LoanTicket, LoanTicketActive, LoanTicketApplication, LoanTicketType } from '../../ApiService/loan';
import LoansDetailsApplication from './LoanDetailsApplication';
import { Redirect } from 'react-router';
import LoanDetailsApplicationHistory from './LoanDetailsApplicationHistory';
import LoansDetailsActive from './LoanDetailsActive';
import LoansDetailsCompleted from './LoanDetailsCompleted';

const override = css`
  display: block;
`;

const LoanDetails = (props: { match: { params: { loanID: any; loanTicketType: any; }; }; }) =>{
    const loanID = props.match.params.loanID
    const loanTicketType = props.match.params.loanTicketType
    
    const [loanTicket, setLoanTicket] = useState<LoanTicket>()
    const [loading, setLoading] = useState(true)

    const getInitTicket = async() =>{
      const ticketRes = await GetLoanTicketByID(loanID, loanTicketType)
      if(ticketRes.success){
        setLoanTicket(ticketRes.apiData)
      }
      setLoading(false)
    }
  
    useEffect(()=>{
      getInitTicket()
    },[])
  
    if(loading){
        return(
            <ClipLoader
                css={override}
                size={21}
                color={"#366CD7"}
                loading={true} 
            />
        )
    }else{
        if(!loading && !loanTicket){
            // return not found
            console.log("Not Found")
            return <Redirect to="/dashboard" />
        }
        switch(loanTicketType){
            
            case LoanTicketType.Application:
                if(loanTicket?.state === 'processed' || loanTicket?.state === 'closed'){
                    return(
                        <LoanDetailsApplicationHistory 
                            loanID = {loanID}
                            loanTicket = {loanTicket as LoanTicketApplication}
                            loanTicketType = {loanTicketType}
                        />
                    )
                }else{
                    return(
                        <LoansDetailsApplication 
                            loanID = {loanID}
                            loanTicket = {loanTicket as LoanTicketApplication}
                            loanTicketType = {loanTicketType}
                        />
                    )
                }

            case LoanTicketType.Active:
                return(
                    <LoansDetailsActive 
                        loanID = {loanID}
                        loanTicket = {loanTicket as LoanTicketActive}
                        loanTicketType = {loanTicketType}
                    />
                )
            case LoanTicketType.Completed:
                return(
                    <LoansDetailsCompleted 
                        loanID = {loanID}
                        loanTicket = {loanTicket as LoanTicketActive}
                        loanTicketType = {loanTicketType}
                    />
                )
            default:
                return <Redirect to="/dashboard" />
        }
    }
}

export default LoanDetails