export const Loan = {

    // UI
    "loan_active_title":"贷款（已生效）",
    "loan_active_subtitle":"进行中",
    "loan_matured_subtitle":"已完结贷款",
    "loan_pending_title":"贷款（审核中）",
    "loan_pending_subtitle":"正在审核贷款",
    "loan_application_history_title":"贷款申请历史",
    "loan_application_processed_subtitle":"贷款申请历史（成功）",
    "loan_application_closed_subtitle":"贷款申请历史（失败 / 逾期）",
    "check_tx_button":"查看交易记录",
    "borrow_more_button":"贷款更多",
    "back_to_my_loans":"返回我的贷款",

    // Loan fund release ref
    "loan_fund_release_ref":"贷款发放证明",
    "loan_fund_release_bank_tx":"汇款编号",

    // Loan Detail
    "loan_application_time_limit_description":"贷款申请有效期: ",
    "loanID":"贷款编号",
    "loan_term":"贷款期数",
    "borrow_amount":"贷款金额",
    "total_interest":"总利息",
    "daily_interest":"日息",
    "total_repayment":"总还款",
    "total_outstanding":"总金额（含利息）",
    "request_date":"申请日期",
    "start_date":"开始日期",
    "end_date":"结束日期",
    "application_status":"申请状况：",
    "loan_information":"贷款详情",
    "collateral_total_need":"抵押保证金总金额",
    "collateral_funded":"已缴纳抵押保证金",
    "collateral_status":"抵押保证金状况：",
    "collateral_empty":"未缴纳抵押保证金",
    "collateral_not_enough":"抵押保证金不足",
    "collateral_enough":"抵押保证金缴纳完成",
    "contract_borrower_status":"借贷人合约签署状况：",
    "contract_admin_status":"贷款公司合约签署状况：",
    "margin_call":"追加抵押保证金通知",
    "liquidation":"平仓 / 终止贷款",
    
    "First step":"第一步",
    "Second step":"第二步",
    "sign_agreement":"签署或查看贷款协议",
    "signed_agreement":"贷款协议已签署",
    "non_signed_borrower":"等待管理员确认",
    "non_signed_agreement":"贷款协议未签署",
    "deposit_collateral":"按此抵押货币",
    "deposit_address":"抵押货币入账地址",
    "tx_proof":"贷款公司银行转账证明",
    "tx_reference":"贷款公司银行转账交易编号",

    "start_repayment_button":"还款方法",
    "apply_early_termination_button":"申请提前终止贷款",
    "pay_early_termination_button":"缴付提前终止贷款费用",

    // Dialogue Wallet
    "send_coins_to_address":"请转账{{amount}} {{coin}}到以下地址缴纳抵押保证金",
    "dialogue_wallet_msg":"您的 <bold> {{borrowAmount}} {{borrowAsset}} </bold> 贷款将会在我们收到 <bold> {{collateralAmount}} {{collateralAsset}} </bold> 抵押保证金后批核。请转账抵押保证金到以下地址：",
    "tx_reminder":"您可以在账户交易记录内查看最新情况。",
    "tx_crypto_reminder":"视乎区块链堵塞情况，交易将在转账10分钟至30分钟后显示到交易记录内。",
    "Please_wait_for_address":"请等待系统分配地址",

    // Dialogue termination confirm
    "dialogue_termination_confirm_header":"请确认是否提前终止贷款",
    "dialogue_termination_confirm_reminer1":"您的贷款抵押品会在我们收到您的全额还款及确认后退还到GP钱包。",

    // Dialogue Repayment Crypto
    "dialogue_repayment_crypto_header":"还款(#{{repaymentID}})",
    "dialogue_repayment_crypto_msg":"请转账<bold> {{repaymentAmount}} {{repaymentAsset}} </bold>到以下地址还款: ",

    // Dialogue Repayment Bank Deposit
    "dialogue_repayment_bank_header":"还款(#{{repaymentID}})",
    "dialogue_repayment_bank_msg":"请转账<bold> {{repaymentAmount}} {{repaymentAsset}} </bold>到以下银行账户: ",
    "dialogue_repayment_bank_upload_success":"文件上载成功，请关闭视窗",
    "dialogue_repayment_bank_processing":"您的还款文件正被审核，请稍后再查询，谢谢",
    "dialogue_repayment_bank_upload_reminder":"请在转账后将银行收据上传",

    // Dialogue Termination Crypto
    "dialogue_termination_crypto_header":"终止贷款 (#{{loanID}})",
    "dialogue_termination_crypto_msg":"如需终止贷款，请在 <bold> {{repaymentDate}}  </bold> 前转账 <bold> {{repaymentAmount}} {{repaymentAsset}} </bold> 到以下地址。 您的贷款抵押品 <bold> {{collateralAmount}} {{collateralAsset}}</bold> 会在我们收到您的全额还款及确认后退还到GP钱包。",
    
    
    // Dialogue Termination Bank Deposit
    "dialogue_termination_bank_header":"终止贷款 (#{{loanID}})",
    "dialogue_termination_bank_msg":"如需终止贷款，请在 <bold> {{repaymentDate}}  </bold> 前转账 <bold> {{repaymentAmount}} {{repaymentAsset}} </bold> 到以下银行账户。 您的贷款抵押品 <bold> {{collateralAmount}} {{collateralAsset}}</bold> 会在我们收到您的全额还款及确认后退还到GP钱包。",
    "dialogue_termination_bank_upload_success":"文件上载成功，请关闭视窗",
    "dialogue_termination_bank_processing":"您的还款文件及终止贷款申请正被审核，请稍后再查询，谢谢",
    "dialogue_termination_bank_upload_reminder":"请在转账后将银行收据上传",

    // Repayments
    "upcoming_repayments":"还款详情",
    "interest_repayment":"利息还款",
    "principle_and_interest_repayment":"利息及本金还款",
    "loan_termination_repayment":"贷款终止",
    "early_repayment":"提早还款",
    "unknown_repayment":"未知还款，请联络我们查询",
    "repayment_type":"还款种类",
    "repayment_date":"还款日期",
    "repayment_status":"还款状况",
    "repayment_paid_amount":"已付",
    "repayment_remain_amount":"需还款金额",
    "repayment_state_active":"进行中",
    "repayment_state_cancelled":"已取消",
    "repayment_state_overdue":"已逾期",
    "repayment_state_paid":"已完成",
    "repayment_state_pending":"等候中",
    "repayment_state_processing":"处理中",
    "unknown_repayment_state":"未知",

    // loan status
    // Loan:status:loan_status
    // "status":{
    //     "loan_status":"贷款状况",
    // },
    "loan_status":"贷款状况",
    "current_LTV":"现时贷款抵押比例",
    "loan_term_passed":"已完成期数",
    "next_repayment":"下期还款",
    "amount_due":"需还款 ",
    "days_remian":"距离还款日",
    "current_repayment":"已还款",
    "today_termination_price":"现时贷款终止价格",
    "termination_fee":"终止贷款手续费",
    "void":"已豁免",
    "months":"期",
    "terminating":"贷款终止中",
    // "days_remian":"距离还款日",
    // "current_LTV":"现时贷款抵押比例",

    // Transactions
    "related_transactions":"相关交易记录",

}