export const WithdrawPageCrypto = {
    "Back to Dashboard": "返回去账户版面",
    "Withdraw Crypto Funds": "提现虚拟货币",
    "Select which asset to withdraw":"选择要提现货币",
    "btc_available": "BTC | 可用余额:",
    "eth_available": "ETH | 可用余额:",
    "usdt_available": "USDT | 可用余额:",
    "Amount": "数量",
    "Note:": "注:",
    "Destination Wallet Address": "目的地钱包地址",
    "You can withdraw up to": "您最多可以提现",
    "Your Withdraw Details": "提现细节:",
    "Asset to Withdraw": "提现货币",
    "Amount to Withdraw": "数量",
    "Send to address": "钱包地址",
    "Minimum Withdraw Fee": "提现费用",
    "Network fee": "网络费用",
    "You can get": "你会得到",
    "Quote Withdraw Fee": "提现费报价",
    "Confirm proceed": "确认",
    "Withdraw Amount must be less or equal to your available balance": "提现金额必须小于或等于您的可用余额",
    "Withdraw Address is invalid, please check your destination wallet address and try again": "提现地址无效，请检查您的钱包地址，然后重试"
}