import React, { Fragment, useEffect, useState } from 'react';

import { css } from "@emotion/core";
import { Link } from "react-router-dom";

import { LoanTicketType, Repayment, GetRepaymentsByLoanID, LoanTicketActive, GetNextRepaymentByLoanID, GetTransactionsByLoanID, Transaction, GetTerminationRepaymentByLoanID, BorrowAsset } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import LoanDetailBasicInformation from './loanDetailComponents/LoanDetailBasicInformation';
import LoanDetailActiveStatus from './loanDetailComponents/LoanDetailActiveStatus';
import LoanDetailRepayments from './loanDetailComponents/LoanDetailRepayments';
import LoanDetailTransactions from './loanDetailComponents/LoanDetailTransactions';
import DialogTerminationConfirm from './components/DialogTerminationConfirm';
import DialogRepaymentBankDeposit from './components/DialogRepaymentBankDeposit';
import DialogRepaymentCrypto from './components/DialogRepaymentCrypto';
import DialogTerminationBankDeposit from './components/DialogTerminationBankDeposit';
import DialogTerminationCrypto from './components/DialogTerminationCrypto';
import { ClipLoader } from 'react-spinners';


const override = css`
  display: block;
  margin-left: 50%;
  margin-top: 20%;
`;

const LoansDetailsActive = ({
  loanID,
  loanTicketType,
  loanTicket
}:{
  loanID:string
  loanTicketType:LoanTicketType
  loanTicket:LoanTicketActive
}) =>{
  const [repayments, setRepayments] = useState<Repayment[]>([])
  const [nextRepayment, setNextRepayment] = useState<Repayment|undefined>(undefined)
  const [finalRepayment, setFinalRepayment] = useState<Repayment|undefined>(undefined)
  const [terminationRepayment, setTerminationRepayment] = useState<Repayment|undefined>(undefined)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [loading, setLoading] = useState(true)

  const GetLoanRepaymentTickets = async() =>{
    const repaymentsRes = await GetRepaymentsByLoanID(loanTicket.loanID)
    if(repaymentsRes.success){
      let totalRepayments = repaymentsRes.apiData.entries.length
      setRepayments(repaymentsRes.apiData.entries)
      if(totalRepayments > 0){
        setFinalRepayment(repaymentsRes.apiData.entries[totalRepayments - 1])
      }
    }

    const nextRepaymentRes = await GetNextRepaymentByLoanID(loanTicket.loanID)
    if(nextRepaymentRes.success){
      setNextRepayment(nextRepaymentRes.apiData)
    }
  }
  const GetTxs = async () =>{
    const txsRes = await GetTransactionsByLoanID(loanTicket.loanID)
    if(txsRes.success){
      setTransactions(txsRes.apiData.entries)
    }
  }
  const GetTermination = async() =>{
    const terminationRes = await GetTerminationRepaymentByLoanID(loanTicket.loanID)
    console.log("terminationRes", terminationRes)
    if(terminationRes.success){
      setTerminationRepayment(terminationRes.apiData)
    }
  }

  const getData = async() =>{
    await GetLoanRepaymentTickets()
    await GetTxs()
    await GetTermination()
    setLoading(false)
  }
  
  const renderRepaymentMethod = (asset:BorrowAsset, nextRepayment:Repayment) => {
    if (asset === BorrowAsset.USD || asset === BorrowAsset.HKD) {
      return <DialogRepaymentBankDeposit repayment={nextRepayment} />
    } else {
      return <DialogRepaymentCrypto repayment={nextRepayment} />
    }
  }
  const renderTerminationRepaymentMethod = (asset:BorrowAsset, terminationRepayment:Repayment) => {
    if (asset === BorrowAsset.USD || asset === BorrowAsset.HKD) {
      return <DialogTerminationBankDeposit ticket={loanTicket} terminationRepayment={terminationRepayment} />
    } else {
      return <DialogTerminationCrypto ticket={loanTicket} terminationRepayment={terminationRepayment} />
    }
  }
  
  const LoanDetailActiveHeader = () =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub"><Link to="/myloans" className="text-soft back-to"><em className="icon ni ni-arrow-left"> </em><span>{t('back_to_my_loans', {defaultValue:"Back to My Loans"})}</span></Link></div>
          <br />
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">{t('Loan:loanID',{defaultValue:"LoanID"})}: {loanTicket.loanID}</h2>
            </div>
          </div>
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title fw-normal">{loanTicket.borrowAsset} - {t('Common:APR',{defaultValue:"APR"})} {(loanTicket.interestRate * 1e2).toFixed(2)}% - {t('Common:Months',{defaultValue:"{{months}} Months", months:loanTicket.loanTerm})}</h3>
            </div>
            <div className="nk-block-head-content">
            <ul className="nk-block-tools gx-3">
              {
                !terminationRepayment && <li className="order-md-last"><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalRepayment">{t('Loan:start_repayment_button', {defaultValue:"Start Repayment"})}</button></li>
              }
              
              {
                terminationRepayment ? <li ><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalRepayTermination">{t('Loan:pay_early_termination_button', {defaultValue:"Pay Early Termination"})}</button></li>
                : <li ><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#confirm-termination">{t('Loan:apply_early_termination_button', {defaultValue:"Apply Early Termination"})}</button></li>
              }
            </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }



  useEffect(()=>{
    getData()
  },[])

  if(loading){
    return(
      <Fragment>
        <div className="nk-content nk-content-lg nk-content-fluid">
          <div className="container-xl wide-lg">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <ClipLoader
                  css={override}
                  size={21}
                  color={"#366CD7"}
                  loading={loading} 
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return (
    <React.Fragment>

      <div className="nk-content nk-content-lg nk-content-fluid">
        <div className="container-xl wide-lg">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <LoanDetailActiveHeader />
              <LoanDetailBasicInformation loanTicket={loanTicket} />
              {
                repayments && nextRepayment && finalRepayment &&
                <LoanDetailActiveStatus loanTicket={loanTicket} nextRepayment={nextRepayment} finalRepayment = {finalRepayment}/>
              }
              {
                repayments &&
                <LoanDetailRepayments repayments={repayments} />
              }
              {
                <LoanDetailTransactions transactions={transactions}/>
              }
            </div>
          </div>
        </div>
      </div>
      {
        nextRepayment && renderRepaymentMethod(loanTicket.repaymentAsset, nextRepayment)
      }
      {/* {renderRepaymentMethod(props.ticket.repaymentAsset, props.nextRepayment.repaymentID, props.ticket)} */}
      {
        terminationRepayment && renderTerminationRepaymentMethod(loanTicket.repaymentAsset,terminationRepayment)
      }
      <DialogTerminationConfirm loanTicket={loanTicket}/>
    </React.Fragment>
  );

}


export default LoansDetailsActive