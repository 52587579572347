import React, { useEffect, useState } from 'react';
import fetcher from '../../fetcher'
import {composeAny} from '../../core/lambda'
import BankInfo from './BankInfo'
import {guard} from '../../core/lambda'
import { BorrowAsset, GetLTVs, LTV } from '../../../../ApiService/loan';
declare namespace BorrowPage {
  export interface Api {
    getReqQuote: () => Partial<RequestQuote>;
    setReqQuote: (req: Partial<RequestQuote>) => void;
    safeSetReqQuote: (isMounted: () => boolean) => (req: Partial<RequestQuote>) => void;
    getResQuote: () => ResponseQuote;
    getResCreateTicket: () => ResponseCreateTicket;
    // getResGetLTVs: () => GetLTVsRes;
    quote: () => Promise<void>;
    createTicket: () => Promise<ResponseCreateTicket>;
    // getLTVs: (isMounted: () => boolean) => Promise<void>;
    setBankInfo: React.Dispatch<React.SetStateAction<BankInfo>>;
  }
  export interface Props {
    view: React.ComponentType<Api>;
  }
  export interface RequestQuote {
    borrowAsset: string,
    borrowAmount: number,
    loanTerm: number,
    LTV: number,
    collateralAsset: string,
    repaymentAsset: string,
  }
  export interface ResponseQuote extends RequestQuote {
    collateralPrice: number;
  }
  export interface ResponseCreateTicket {
    loanID?: number;
    address: string;
    collateralAsset: string;
    collateralAmount: number;
    redirectURL: string;
  }
  // export interface ResponseGetLTVs {
  //   ltvs:LTV[]
  //   // ltvs: {
  //   //   ltv: number;
  //   //   interestRate: number;
  //   // }[];
  // }
  export type Asset = AssetCrypto | AssetFiat;
  export type AssetCrypto = 'USDT';
  export type AssetFiat = 'USD' | 'HKD';
}
const BorrowPage = <
  TAsset extends BorrowPage.Asset,
  TAssetType extends (TAsset extends BorrowPage.AssetFiat ? 'fiat' : 'crypto')
>(asset: TAsset, type: TAssetType) => {
  let _ = (props: BorrowPage.Props) => {
    const [req, setReq] = React.useState<Partial<BorrowPage.RequestQuote>>({
      borrowAsset: asset
    });
    const [resQuote, setResQuote] = React.useState<BorrowPage.ResponseQuote>({} as any);
    const [resCreateTicket, setResCreateTicket] = React.useState<BorrowPage.ResponseCreateTicket>({} as any);
    // const [ltvs, setLTVs] = useState<LTV[]>([])
    // const [resGetLTVs, setResGetLTVs] = React.useState<LTV[]>([]); 
    const [bankInfo, setBankInfo] = React.useState<BankInfo>({});
    let updateReq = (isMounted: () => boolean) => (partialReq: Partial<BorrowPage.RequestQuote>) => (
      guard(isMounted())(setReq)(Object.assign({}, req, partialReq))
    );
    let quote = () => (
      fetcher
        .send('/api/v1/loan/quote', {
          method: 'POST',
          body: JSON.stringify(req)
        })
        .then(res => res.json())
        .then(res => res as BorrowPage.ResponseQuote)
        .then(setResQuote)
    );
    let createTicket = () => (
      setResCreateTicket({} as any),
      fetcher
        .send(`/api/v1/loan/application/${type}`, {
          method: 'POST',
          body: JSON.stringify({
            ...req,
            ...bankInfo,
          })
        })
        .then(res => {
          console.log("createTicket", res)
          return res.json()
        })
        .then(async res => {
          let convertRes = res as BorrowPage.ResponseCreateTicket
          await setResCreateTicket(convertRes)
          return convertRes
        })
        .catch(err=>{
          throw err
        })
        
        // .then(_ => guard(true)(composeAny(
        //   setResCreateTicket,
        //   //res => (alert(JSON.stringify(res, null, 2)), res),
        // ))(_)).then()
    );
    // let getLTVs = async () => {
    //   const ltvs = await GetLTVs(asset as BorrowAsset)
    //   setLTVs(ltvs)
    //   // return ltvs
    //   // let getInterestRate = (ltv: number) => (
    //   //   fetcher
    //   //     .send(`/api/v1/loan/borrowInterestRate/?asset=${asset}&ltv=${ltv}`)
    //   //     .then(res => res.json())
    //   //     .then(res => res as { rate: number; })
    //   //     .then(res => res.rate)
    //   // );
    //   // let withInterestRate = (ltv: number) => (
    //   //   Promise.resolve(ltv)
    //   //     .then(ltv => (
    //   //       getInterestRate(ltv)
    //   //         .then(rate => ({ ltv, interestRate: rate }))
    //   //     ))
    //   // )
    //   // return (
    //   //   fetcher
    //   //     .send(`/api/v1/loan/ltv/?asset=${asset}`)
    //   //     .then(res => res.json())
    //   //     .then(res => res as { ltvs: number[]; })
    //   //     .then(res => Promise.all(res.ltvs.map(withInterestRate)))
    //   //     .then(ltvs => ({ltvs} as BorrowPage.ResponseGetLTVs))
    //   //     .then(_ => guard(isMounted())(setResGetLTVs)(_))
    //   // );
    // };
    useEffect(()=>{
    },[])
    let View = props.view;
    return (
      <View 
        getReqQuote={() => req} 
        setReqQuote={updateReq(() => true)} 
        safeSetReqQuote={updateReq} 
        getResQuote={() => resQuote} 
        getResCreateTicket={() => resCreateTicket}
        // getResGetLTVs={() => resGetLTVs}
        quote={quote}
        createTicket={createTicket}
        // getLTVs={getLTVs}
        setBankInfo={setBankInfo}
      />
    );
  }
  return _;
};
export default BorrowPage