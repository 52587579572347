import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const KYCSuccess = () => {
    const { t } = useTranslation('KYC')
        return(
            <React.Fragment>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                    <div className="container-xl wide-lg">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="kyc-app wide-sm m-auto">
                                    <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                                        <div className="nk-block-head-content text-center">
                                            <h2 className="nk-block-title fw-normal">{t('KYC_success_page_title', {defaultValue:"Thank you for verifying your identity with us."})}</h2>
                                            {/* <div className="nk-block-des">
                                                <p>{t('KYC_success_page_description', {defaultValue:"We are currently processing your verification, please check back with us in a moment."})}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="card card-bordered">
                                            <div className="card-inner card-inner-lg">
                                                <div className="nk-kyc-app p-sm-2 text-center">
                                                    <div className="nk-kyc-app-icon">
                                                        <em className="icon ni ni-check "></em>
                                                    </div>
                                                    <div className="nk-kyc-app-text mx-auto">
                                                        <p className="lead">{t('KYC_success_page_under_review',{defaultValue:"Your identity verification is currently under review. Please hold on and check back in about 5 minutes."})}</p>
                                                    </div>
                                                    <div className="nk-kyc-app-action">
                                                        <Link to="/borrow" className="btn btn-lg btn-outline-success">{t('KYC_success_page_start_borrow_button',{defaultValue:"Click here to start borrowing"})}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center pt-4">
                                            <p>{t('askForSupport', {defaultValue:"If you have any question, please contact our support team "})}<a href="mailto:support@grandprospects.com">support@grandprospects.com</a></p>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
export default KYCSuccess;