import ConfigAxio from '../../config/configAxios'
import { User } from "../../contexts/appContext"

export const SignInUser = async(firebaseToken:string) =>{
    try {
        const res = await ConfigAxio.post('/api/v1/auth/signin',{
            firebaseToken
        })
        let user = res.data as User
        return user
    } catch (error) {
        return undefined
    }
}

export const ValidateUser = async() =>{
    try {
        const res = await ConfigAxio.get('/api/v1/user')
        let user = res.data as User
        return user
    } catch (error) {
        return undefined
    }
}

export const RegisterUser = async(firebaseToken:string) =>{
    try {
        await ConfigAxio.post('/api/v1/auth/signup',{
            firebaseToken
        })
    } catch (error) {
        return undefined
    }
}

export const SignOutUser = async() =>{
    try {
        await ConfigAxio.post('/api/v1/auth/signout')
    } catch (error) {
        return undefined
    }
}
