export const Profile = {
    "my_profile":"个人页面",
    "account_information":"帐号资讯",
    "userID":"用户ID",
    "email":"电邮地址",
    "verification_level":"实名认证等级",
    "verification_status":"实名认证状况",
    "personal_information":"个人资讯",
    "english_full_name":"英文全称",
    "chinese_full_name":"中文全称",
    "phone":"电话号码",
    "dob":"出生日期",
    "nationality":"国籍",
    "address":"地址",
}