import React, { useContext, useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ConfigFirebase from '../../config/firebaseConfig';
import { Link, Redirect, useHistory } from "react-router-dom";
import { AppContext, Language } from '../../contexts/appContext';
import { ClipLoader } from 'react-spinners';
import { css } from "@emotion/core";
import { useCookies } from 'react-cookie';
import { RegisterUser, SignInUser } from '../../ApiService/auth';
import { Trans, useTranslation } from 'react-i18next';


const override = css`
display: block;
margin-left: 40%;
`;

const LoginPage = (props:any) => {
    const [cookies, setCookie, removeCookie] = useCookies(['emailForSignIn','token']);
    const { t } = useTranslation('LoginPage')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { isSignin, setSignin, setCurrentUser, setLanguage, currentUserSetting} = useContext(AppContext)
    const successUrl = '/dashboard'
    let history = useHistory()

    const LanguageDisplayer = (language:Language) =>{
        switch(language){
            case Language.zh_CN:
                return "简体中文"
            case Language.zh_TW:
                return "繁體中文"
            default:
                return "English"
        }
    }
    async function checkUserRedirect(authResult: any){

        ConfigFirebase.auth().currentUser?.getIdToken(true)
        .then(async(token: string)=>{
            let user;
            user = await SignInUser(token)
            console.log("user", user)
            if(!user){
                // console.log("Register User")
                await RegisterUser(token)
                user = await SignInUser(token)
            }
            
            if(user){
                setSignin(true)
                setCurrentUser(user)
                localStorage.clear()
                sessionStorage.clear();
                removeCookie('emailForSignIn')
                setIsLoading(false)
                history.push(successUrl)
            }else{
                setIsLoading(false)
            }
        })
    }

    function checkLoginOrSigninWithAuthResult(authResult: any, redirectUrl: any):boolean{
        // console.log("signin success With Auth callback", authResult)
        setIsLoading(true)
        if(authResult){
            checkUserRedirect(authResult)
        }
        // console.log("Pass check")
        return false
    }

    const uiConfig = {
    signInFlow: ConfigFirebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
    // signInFlow : 'popup',

    callbacks: {
        signInSuccessWithAuthResult: checkLoginOrSigninWithAuthResult
    },
    signInOptions: [
        // {
        //     provider: ConfigFirebase.auth.EmailAuthProvider.PROVIDER_ID,
        //     signInMethod: ConfigFirebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        // },
        {
            provider: ConfigFirebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: ConfigFirebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        },
        ConfigFirebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    };

    if(!isSignin){
        return (
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <div className="nk-split nk-split-page nk-split-md">
                        <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                            <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                                <a href="#" className="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em className="icon ni ni-info"></em></a>
                            </div>
                            <div className="nk-block nk-block-middle nk-auth-body">
                                <div className="brand-logo pb-5">
                                    <a href="html/index.html" className="logo-link">
                                        <img className="logo-light logo-img logo-img-lg" src="./images/logo.png" srcSet="./images/logo2x.png 2x" alt="logo" />
                                        <img className="logo-dark logo-img logo-img-lg" src="./images/logo-dark.png" srcSet="./images/logo-dark2x.png 2x" alt="logo-dark" />
                                    </a>
                                </div>
                                <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                        <h5 className="nk-block-title">{t('Sign-In')} / {t('Sign-Up')}</h5>
                                        <div className="nk-block-des">
                                        <Trans t={t} i18nKey="guideline1" >
                                            <p>Access the GP Crypto Backed Loan platform using your favourite SSO sign in options.</p>
                                        </Trans>
                                        </div>
                                    </div>
                                </div>
                                <ClipLoader
                                css={override}
                                size={50}
                                color={"#366CD7"}
                                loading={isLoading}
                                />
                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={ConfigFirebase.auth()}/>
                                {/* <div className="text-center pt-4 pb-3">
                                    <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
                                </div> */}
                                <div className="form-note-s2 pt-4">{t("New on our platform?")}<br></br>{t("Click sign in with email or Google as well, its free.")}</div>
                            </div>
                            <div className="nk-block nk-auth-footer">
                                <div className="nk-block-between">
                                    <ul className="nav nav-sm">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/terms">{t("Terms & Condition")}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/terms">{t("Privacy Policy")}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/terms">{t("Help")}</Link>
                                        </li>
                                        <li className="nav-item dropup">
                                            <a className="dropdown-toggle dropdown-indicator has-indicator nav-link" data-toggle="dropdown" data-offset="0,10"><small>{LanguageDisplayer(currentUserSetting.language)}</small></a>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <ul className="language-list">
                                                    <li>
                                                        <a href="#" className="language-item" onClick = {() => setLanguage(Language.en_US)}>
                                                            <img src="./images/flags/english.png" alt="" className="language-flag" />
                                                            <span className="language-name">English</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="language-item" onClick = {() => setLanguage(Language.zh_CN)}>
                                                            <img src="./images/flags/china.png" alt="" className="language-flag" />
                                                            <span className="language-name">简体中文</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="language-item" onClick = {() => setLanguage(Language.zh_TW)}>
                                                            <img src="./images/flags/china.png" alt="" className="language-flag" />
                                                            <span className="language-name">繁體中文</span>
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="#" className="language-item">
                                                            <img src="./images/flags/french.png" alt="" className="language-flag" />
                                                            <span className="language-name">Français</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="language-item">
                                                            <img src="./images/flags/turkey.png" alt="" className="language-flag" />
                                                            <span className="language-name">Türkçe</span>
                                                        </a>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <p>&copy; {new Date().getFullYear()} Grand Prospects. Powered by <a href="#">DLO Digital Loan</a></p>
                                    <p style={{marginBottom: '0px'}}>Money Lender's Licence No. 放債人牌照編號：1677/2020</p>
                                    <p style={{marginBottom: '0px'}}>Enquiry Hotline 查詢熱線：+852 5511 0147</p>
                                    <p>Complain Hotline 投訴熱線：＋852 2195 1676</p>
                                    <p>在Complain Hotline下面加上  "辦公時間：10:00 - 18:00 (星期一至星期五, 公眾假期除外)"</p>

                                    <p style={{marginBottom: '0px'}}>忠告: 借錢梗要還，咪俾錢中介</p>
                                    <p style={{marginBottom: '0px'}}>Warning: You have to repay your loans. Don’t pay any intermediaries</p>
                                </div>
                            </div>
                        </div>
                        <div className="nk-split-content nk-split-stretch bg-abstract"></div>
                    </div>
                </div>
            </div>
        )
    }else{
        return <Redirect to={successUrl} />
    }

}
export default LoginPage
