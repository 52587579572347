import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Repayment, RepaymentState, RepaymentType } from '../../../ApiService/loan'
import { useFormatPrice } from '../../../util/jacob/components/format'
const LoanDetailRepayments = ({
    repayments
}:{ 
    repayments:Repayment[]
}) =>{
    const { t } = useTranslation(['Loan','Common'])

    // state: 'active' | 'paid' | 'cancelled' | 'pending' | 'overdue';
    return(
        <div className="nk-block nk-block-lg">
            <div className="nk-block-head">
            <h5 className="nk-block-title">{t("Loan:upcoming_repayments", {defaultValue:"Upcoming Repayments"})}</h5>
            </div>
            <div className="card card-bordered">
                <table className="table table-iv-tnx">
                    <thead className="thead-light">
                    <tr>
                        <th className="tb-col-type"><span className="overline-title">{t("Loan:repayment_type", {defaultValue:"Repayment Type"})}</span></th>
                        <th className="tb-col-date"><span className="overline-title">{t("Loan:repayment_date", {defaultValue:"Date"})}</span></th>
                        <th className="tb-col-type"><span className="overline-title">{t("Loan:repayment_status", {defaultValue:"Status"})}</span></th>
                        <th className="tb-col-time tb-col-end"><span className="overline-title">{t("Loan:repayment_paid_amount", {defaultValue:"Paid"})} / {t("Loan:repayment_remain_amount", {defaultValue:"Amount"})}</span></th>
                    </tr>
                    </thead>
                    <tbody>{
                    repayments
                        .map((ticket, i) => (
                            <RepaymentDisplayer repayment={ticket} key={i}/>
                        ))
                    }</tbody>
                </table>
            </div>
      </div>
    )
}

const RepaymentDisplayer = ({
    repayment
}:{
    repayment:Repayment
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    const RepaymentTypeDisplayer = (repaymentType:RepaymentType) =>{
        switch(repaymentType){
            case RepaymentType.Interest:
                return t("Loan:interest_repayment", {defaultValue:"Interest Repayment"})
            case RepaymentType.Principle:
                return t("Loan:principle_and_interest_repayment", {defaultValue:"Principle & Interest Repayment"})
            case RepaymentType.Termination:
                return t("Loan:loan_termination_repayment", {defaultValue:"Loan Termination Repayment"})
            case RepaymentType.Early:
                return t("Loan:early_repayment", {defaultValue:"Early Repayment"})
            default:
                return t("Loan:unknown_repayment", {defaultValue:"Unknown Repayment, Please contact us to check"})
        }
    }
    const RepaymentStateDisplayer = (repaymentType:RepaymentState) =>{
        switch(repaymentType){
            case RepaymentState.Active:
                return t("Loan:repayment_state_active", {defaultValue:"Active"})
            case RepaymentState.Cancelled:
                return t("Loan:repayment_state_cancelled", {defaultValue:"Cancelled"})
            case RepaymentState.Overdue:
                return t("Loan:repayment_state_overdue", {defaultValue:"Overdue"})
            case RepaymentState.Paid:
                return t("Loan:repayment_state_paid", {defaultValue:"Paid"})
            case RepaymentState.Pending:
                return t("Loan:repayment_state_pending", {defaultValue:"Pending"})
            case RepaymentState.Processing:
                return t("Loan:repayment_state_processing", {defaultValue:"Processing"})
            default:
                return t("Loan:unknown_repayment_state", {defaultValue:"Unknown"})
        }
    }
    return(
        <tr>
            <td className="tb-col-type"><span className="sub-text">{RepaymentTypeDisplayer(repayment.repaymentType)}</span></td>
            <td className="tb-col-date"><span className="sub-text">{moment(repayment.repaymentDate).format("YYYY-MM-DD")}</span></td>
            <td className="tb-col-type"><span className="sub-text">{RepaymentStateDisplayer(repayment.state)}</span></td>
            <td className="tb-col-time tb-col-end"><span className="lead-text">{useFormatPrice(repayment.repaymentAsset, repayment.fundedAmount, false)} / {useFormatPrice(repayment.repaymentAsset, repayment.repaymentAmount, true)}</span></td>
        </tr>
    )
}

export default LoanDetailRepayments