export const Borrow = {
    "borrow_page_title":"請選擇需要的貸款",
    "borrow_page_type":"港幣",
    "borrow_button":"貸款",
    "borrow_go_back":"返回",

    "hkd_release_method":"貸款將直接發放到銀行賬戶",

    // USDT Borrow
    "hkd_borrow_page_title":"申請港幣貸款",
    "hkd_borrow_page_step1_title":"第1步：貸款表格",
    "hkd_borrow_page_step1_description":"請選擇貸款金額、貸款期、貸款比例、和抵押貨幣",
    "You will get": "你會得到",
    "Your loan will arrive in your HKD bank account": "您的貸款將存入您的港元銀行帳戶",
    "Choose your interest rate / LTV Ratio": "選擇您的利率 / 貸款抵押價值比",
    "Choose Collateral Asset": "選擇您的保證金抵押品",
    "Bitcoin - BTC": "比特幣 - BTC",
    "Ethereum - ETH": "以太幣 - ETH",
    "USDT - USDT": "USDT",
    "Choose Loan Duration": "貸款期限",
    "Months": "個月",
    "Choose Quick Amount to Borrow": "選擇貸款金額",
    "Or Enter Your Amount": "或輸入貸款金額",
    "Minimum borrow 5,000 HKD and up to 2,000,000 HKD": "最低借貸 5,000 港幣, 最高至 2,000,000 港幣",

    // USDT Borrow
    "Apply for a USDT Loan":"申請 USDT 貸款",
    "Your loan will arrive in your GP wallet": "您的貸款將發送到您的GP錢包中",
    "Minimum borrow 700 USDT and upto 250,000 USDT": "最低借貸 700 USDT, 最高至 250,000 USDT",

    // HKD Bank Info
    "Bank information has been confirmed, please proceed to obtain a loan quote and continue.": "銀行信息已確認，請繼續獲取貸款報價並繼續",
    "hkd_borrow_page_step2_title": "步驟2：輸入您的銀行帳戶詳細信息",
    "We will release the loan to this bank account.": "我們會將貸款釋放到該銀行帳戶",
    "Bank Account Information": "銀行賬戶信息",
    "Bank Name": "銀行名稱",
    "This field is required": "這是必填欄",
    "Bank Transfer Method": "銀行轉賬方式",
    "BankAccount": "銀行賬戶",
    "FPS": "FPS - 快速支付系統",
    "Bank Account Number": "銀行帳號",
    "FPS ID / Email / Phone Number / Bank Account": "FPS ID / 電子郵件 / 電話號碼 / 銀行帳戶",
    "Account Holder Full Name (English)": "帳戶持有人全名（英文）",
    "Account Type": "帳戶類型",
    "Savings": "儲蓄賬戶",
    "Current": "往來賬戶",
    "Comments / Special Instructions": "特別說明",
    "Confirm Bank Account Details": "確認銀行帳戶",

    // Borrow Details
    "Your Loan Details": "貸款詳細信息",
    "Borrow Asset": "借入資產",
    "Loan Duration": "貸款期限",
    "Month(s)": "個月",
    "Borrow Amount": "借入金額",
    "Interest Rate %": "利率 ％",
    "Interest Per Month": "每月利息",
    "Total Interest": "總利息",
    "Early Termination Fee": "提前終止貸款費",
    "Collateral Asset": "抵押資產",
    "Loan-To-Value Ratio": "貸款抵押價值比",
    "Collateral Asset Value": "抵押品價值",
    "Amount to be locked": "抵押品鎖定金額",
    "Margin call at": "追加保證金價",
    "Liquidation at": "清算價",
    "Total Loan": "總貸款",
    "Total Repayment": "總還款額",
    "Total Collateral": "抵押總額",
    "I agree the": "我同意",
    "terms and conditions.": "條款和條件",
    "Get Quote": "報價",
    "Confirm & proceed": "確認並繼續",
    "Please agree the terms & condition": "請同意條款和條件",
    "Please verify identity first": "請先驗證身份",

    // Borrow Dialog
    "Application error, please contact support": "申請錯誤，請聯繫支持團隊",
    "Excepted error occured, please contact support": "發生錯誤，請與支持團隊聯繫",
    "Please confirm your loan application.": "請確認您的貸款申請",
    "1. You will be redirected to DocuSign to sign loan agreement after clicking the confirm button.": "1. 單擊確認按鈕後，您將被重定向到貸款詳情頁面",
    "2. After Signing the Loan Agreement via DocuSign, please proceed to deposit collateral to your wallet address.": "2. 通過 DocuSign 簽署貸款協議後，請繼續將抵押品存入您的錢包地址",
    "3. Once we have received your collateral deposit, your loan will be released to your GP Wallet or Bank Account.": "3. 我們收到您的抵押存款後，您的貸款將被釋放到您的GP錢包或銀行帳戶中",
    "4. Please confirm your loan application within 24 hours.":"4. 請在24小時內確認你的貸款申請",
    "Confirm": "確認",
    "Redirecting to DocuSign": "進入貸款詳情頁面",
    "Applying loan": "申請貸款中",
    "Cancel": "取消",

    "usdt_release_method":"貸款將發放到系統錢包，需要進行提現",
    // "hkd_min_period":"1 日",
    // "hkd_max_period":"最高還款期",
    // "hkd_can_early_repayment":"最高還款期",
    // "hkd_can_early_termination":"最高還款期",

    "min_period":"最低還款期",
    "max_period":"最高還款期",
    "can_early_repayment":"提早還款",
    "can_early_termination":"提早終止貸款",
} 