import React from 'react';
import { useFormatPrice } from '../../util/jacob/components/format';
import btc from '../product-icons/btc.svg';
import eth from '../product-icons/eth.svg';
import usdt from '../product-icons/usdt.svg';


const CryptoTransactionItem = (props) => {
    const displayIcons = (asset) => {
        if (asset === 'BTC') {
            return btc
        } else if (asset === 'ETH') {
            return eth
        } else if (asset === 'USDT') {
            return usdt
        }
    }
const filterTxTarget = (type, target) => {
    if (type === 'Deposit' && target === "Amount") {
        return 'Deposit Confirmed'
    } else if (type === 'Pending Deposit' && target === "Pending Deposit") {
        return 'Unconfirmed Deposit'
    } else if (type === 'Withdraw' && target === "Amount") {
        return 'Withdraw Confirmed'
    } else if (type === 'Fee' && target === "Amount") {
        return 'Withdraw Fee'
    } else if (type === 'Pending Withdraw' && target === "Pending withdraw") {
        return 'Withdraw Hold'
    } else if (type === 'Liquidation' && target === "Amount") {
        return 'Asset sold for liquidation'
    } else if (type === 'Liquidation' && target === "Hold") {
        return 'Asset released for liquidation'
    } else if (type === 'Loan Termination' && target === "Amount") {
        return 'Loan Repayment'
    } else if (type === 'Collateral Unhold' && target === "Hold") {
        return 'Loan Collateral Unlock'
    } else if (type === 'Collateral Hold' && target === "Hold") {
        return 'Loan Collateral Lock'
    } else if (type === 'Loan Payout' && target === "Amount") {
        return 'Payout from loan'
    } else {
        return target
    }
}
    // console.log(props.asset)
    return (
        <div className="nk-tb-item">
            <div className="nk-tb-col">
                <div className="nk-tnx-type">
                    <div className="nk-tnx-type-icon bg-success-dim text-success">
                        <img src={displayIcons(props.asset)} />
                        {/*<em className="icon ni ni-arrow-up-right"></em>*/}
                    </div>
                    <div className="nk-tnx-type-text">
                        <span className="tb-lead">{filterTxTarget(props.transactionType, props.transactionTarget)} - {props.transactionType}</span>
                        {/* <span className="tb-date">{props.timestamp}</span> */}
                    </div>
                </div>
            </div>
            <div className="nk-tb-col tb-col-lg">
                <span className="tb-lead-sub">{props.asset}</span>
            </div>
            <div className="nk-tb-col tb-col-lg">
                <span className="tb-date">{props.timestamp}</span>
            </div>
            <div className="nk-tb-col tb-col-lg">
                <span className="tb-sub">{props.transactionRef}</span>
            </div>
            <div className="nk-tb-col">
                <span className="tb-amount">{useFormatPrice(props.asset, props.amount, true)}</span>
            </div>
            {/* <div className="nk-tb-col text-right">
                <span className="tb-amount">{props.amount} <span>{props.asset}</span></span>
            </div> */}
        </div>
    )
}

export default CryptoTransactionItem;