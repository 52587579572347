import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { GetAllLoanTicketsByType, LoanTicketActive, LoanTicketType } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import { LoanTicketSimples } from './components/LoanSimple';

const LoanActivePage = (props: {}) => {
  const { t } = useTranslation(['Loan','Common'])
  const [loanActiveTicket, setLoanProcessedTicket] = useState<LoanTicketActive[]>([])
  const [loanMaturedTicket, setLoanClosedTicket] = useState<LoanTicketActive[]>([])
  const getData = async() => {
    const loanActiveTicketRes = await GetAllLoanTicketsByType(LoanTicketType.Active,"?state=active&state=refinanced&state=defaulted")
    const loanMaturedTicketRes = await GetAllLoanTicketsByType(LoanTicketType.Active,"?state=completed&state=liquidated")
    if(loanActiveTicketRes){
      setLoanProcessedTicket(loanActiveTicketRes.entries  as LoanTicketActive[])
    }
    if(loanMaturedTicketRes){
      setLoanClosedTicket(loanMaturedTicketRes.entries  as LoanTicketActive[])
    }
  }
  useEffect(()=>{
    getData()
  },[])

  return (
  <React.Fragment>
    <div className="nk-content nk-content-lg nk-content-fluid">
      <div className="container-xl wide-lg">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head">
              <div className="nk-block-head-content">
                <div className="nk-block-head-sub"><span></span></div>
                <div className="nk-block-between-md g-4">
                  <div className="nk-block-head-content">
                    <h2 className="nk-block-title fw-normal">{t('loan_active_title',{defaultValue:"Activated Loans"})}</h2>
                  </div>
                  <div className="nk-block-head-content">
                    <ul className="nk-block-tools gx-3">
                      <li><Link to="/transactions/crypto" className="btn btn-primary"><span> {t('check_tx_button',{defaultValue:"View Transactions"})}</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block"></em></Link></li>
                      <li><Link to="/borrow" className="btn btn-white btn-light"><span>{t('borrow_more_button',{defaultValue:"Borrow More"})}</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block"></em></Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LoanTicketSimples 
              loanTicketType = {LoanTicketType.Active}
              title={t('Loan:loan_active_subtitle',{defaultValue:'Active Loans'})}
              tickets={loanActiveTicket}
            />
            <LoanTicketSimples 
              loanTicketType = {LoanTicketType.Active}
              title={t('Loan:loan_matured_subtitle',{defaultValue:'Matured Loans'})}
              tickets={loanMaturedTicket}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)};
export default LoanActivePage;