import { TopNav } from './TopNav'
import { Dashboard } from './Dashboard'
import { LoginPage } from './LoginPage'
import { Footer } from './Footer'
import { KYC } from './KYC'
import { FirebaseUploader } from './FirebaseUploader'
import { WithdrawPageCrypto } from './WithdrawPageCrypto'
import { Borrow } from './Borrow'
import { Loan } from './Loan'
import { Tx } from './Tx'
import { Profile } from './Profile'
import { Common } from './Common'
export const Translation_zh_TW = {
    Common,
    TopNav,
    Footer,
    Dashboard,
    LoginPage,
    KYC,
    FirebaseUploader,
    WithdrawPageCrypto,
    Borrow,
    Loan,
    Tx,
    Profile
}