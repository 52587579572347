import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { css } from "@emotion/core";
import { ApplyLoanTermination, LoanTicketActive } from '../../../ApiService/loan';
import { useTranslation } from 'react-i18next';


const override = css`
  display: block;
`;


let DialogTerminationConfirm = ({
  loanTicket
}:{
  loanTicket:LoanTicketActive
}) => {
  const { t } = useTranslation(['Loan','Common'])
  const [loading, setLoading] = useState<boolean>(false)
  const [applyTerminationSuccess, SetApplyTerminationSuccess] = useState<boolean>(false)
  const closeButtonRef = useRef<HTMLAnchorElement>(null)

  const handleConfirmTermination = async (e:React.MouseEvent<HTMLElement>) =>{
    e.preventDefault();
    setLoading(true)
    try {
      const res = await ApplyLoanTermination(loanTicket.loanID)
      if(res){
        SetApplyTerminationSuccess(res)
      }
      // console.log("res", res)
      setLoading(false)
      if(closeButtonRef.current !== null){
        closeButtonRef.current.click()
      }
      // /myloans/details/?type=active&loanID=1
      // history.push(`/myloans/details/?type=active&loanID=${loanTicket.loanID}`)
      window.location.reload()
      
      setLoading(false)
    } catch (error) {
      setLoading(false)
      SetApplyTerminationSuccess(false)
    }

    

  }
  return (
    // tabIndex={-1}
    <div className="modal fade"  id="confirm-termination">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <a href="" className="close" data-dismiss="modal" ref={closeButtonRef} onClick={()=>false}><em className="icon ni ni-cross-sm"></em></a>
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              {
                applyTerminationSuccess && <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
              }
              <h4 className="nk-modal-title">{t('Loan:dialogue_termination_confirm_header',{defaultValue:"Please confirm your loan termination application"})}</h4>
              <div className="nk-modal-text">
                <p className="sub-text">{t('Loan:dialogue_termination_confirm_reminer1',{defaultValue:"Collateral will be released to your Grand Prospects Wallet once you do full repayment and approved by our staff."})}</p>
                <p className="sub-text">{t('Loan:tx_reminder',{defaultValue:"Transaction will appear on the Transactions Page once received."})}</p>
              </div>
              <div className="nk-modal-action-lg">
                <ul className="btn-group flex-wrap justify-center g-4">
                  <li>
                    {!loading && <Link to="" className="btn btn-lg btn-mw btn-primary" onClick={handleConfirmTermination}>{t('Common:Confirm', {defaultValue:"Confirm"})}</Link>}
                    <ClipLoader
                      css={override}
                      size={21}
                      color={"#366CD7"}
                      loading={loading}
                    /> 
                  </li>
                  <li data-dismiss="modal"><Link to="" className="btn btn-lg btn-mw btn-primary">{t('Common:Cancel', {defaultValue:"Cancel"})}</Link></li>
                  {/*<li data-dismiss="modal"><Link to="/transactions/crypto" className="btn btn-lg btn-mw btn-dim btn-primary"><em className="icon ni ni-reports"></em><span>View Transactions</span></Link></li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogTerminationConfirm;