import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Repayment } from '../../../ApiService/loan';
import DialogAddressWithCopy from './DialogAddressWithCopy';


let DialogRepayWallet = ({repayment}:{repayment:Repayment}) => {
  const { t } = useTranslation(['Loan','Common'])

  return (
      <div className="modal fade" tabIndex={-1} id="modalRepayment">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body modal-body-md text-center">
              <div className="nk-modal">
                <h4 className="nk-modal-title">{t('Loan:dialogue_repayment_crypto_header',{defaultValue:"Make a payment(#{{repaymentID}}) to us", repaymentID:repayment.repaymentID})}</h4>
                <div className="nk-modal-text">
                  <p>
                    <Trans
                      t={t}
                      i18nKey="dialogue_repayment_crypto_msg" // optional -> fallbacks to defaults if not provided
                      defaults="Please make a payment of <bold> {{repaymentAmount}} {{repaymentAsset}} </bold> to the following wallet address: " // optional defaultValue
                      values={{ repaymentAmount:repayment.repaymentAmount, repaymentAsset:repayment.repaymentAsset}}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
                <DialogAddressWithCopy address={repayment.depositAddress}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default DialogRepayWallet;