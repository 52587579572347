export const Dashboard = {
    "Asset": "資產",
    "Price": "最新價格",
    "Balance":"全部",
    "Amount": "總金額",
    "Locked": "借款凍結",
    "Total Value": "總錢包價值",
    "Status": "錢包狀態",
    "Bitcoin - BTC": "比特幣 - BTC",
    "Confirmed": "已確認",
    "Withdraw": "提現",
    "History": "檢閱歷史",
    "Details": "了解更多",
    "Ethereum - ETH": "以太坊 - ETH",
    "Tether - USDT": "USDT",
    "Welcome back!": "你好!",
    "4 steps to take out a Crypto-backed loan": "申請貸款的4個步驟",
    "Verify Identity": "驗證身份",
    "Once verified, you will be able to apply for a loan": "驗證後，您將可以申請貸款",
    "Apply Loan": "申請貸款",
    "Get a quote and submit your loan application": "獲取報價並提交您的貸款申請",
    "Sign Agreement": "簽署協議",
    "Sign loan agreement electronically": "電子簽署貸款協議",
    "Deposit Collateral": "存款抵押",
    "Send coins to our wallet to release funds": "將抵押幣發送到我們的錢包以釋放貸款資金",
    "Account Overview": "我的賬戶:",
    "Total Portfolio Balance": "帳戶總金額",
    "No Repayment": "沒有生效貸款",
    " Active Loans": " 已生效貸款",
    "Available Loan Amount": "可用貸款額",
    "Balance in Account": "帳戶餘額",
    "Available Funds": "可用資金",
    "Locked Funds": "借款凍結",
    "Pending Withdraw Funds": "待提款",
    "Total": "總金額",
    "Withdraw Funds": "提款",
    "Next Interest Repayment": "下次還款",
    "Payment Due": "還款金額",
    "Payment Currency": "還款貨幣",
    "Due Date": "還款日",
    "Repayment Status": "還款狀態",
    "Repay early to avoid <Link to={`/myloans/details/?type=active&loanID=${nextRepayment?.loanID}`} className='link link-dark'> Late Fees!</Link>": "儘早還款以避免<Link to={`/myloans/details/?type=active&loanID=${nextRepayment?.loanID}`} className='link link-dark'>費用</Link>",
    "Repay Interest Now": "立即償還利息",
    "My Active Loans": "我的貸款",
    "See Loan Status": "貸款狀態",
    "Verify Identity (KYC)": "實名認證",
} 