import React from 'react';
import { css } from "@emotion/core";
import { LoanTicketType, LoanTicketApplication } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import LoanDetailBasicInformation from './loanDetailComponents/LoanDetailBasicInformation';
import { Link } from 'react-router-dom';
import LoanDetailApplicationTxRef from './loanDetailComponents/LoanDetailApplicationTxRef';

const LoanDetailsApplicationHistory = ({
  loanID,
  loanTicketType,
  loanTicket
}:{
  loanID:string
  loanTicketType:LoanTicketType
  loanTicket:LoanTicketApplication
}) =>{
  const { t } = useTranslation(['Loan','Common'])

  return (
    <React.Fragment>

      <div className="nk-content nk-content-lg nk-content-fluid">
        <div className="container-xl wide-lg">
          <div className="nk-content-inner">
            <div className="nk-content-body">

              {/* Header start */}
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <div className="nk-block-head-sub"><Link to="/myloans" className="text-soft back-to"><em className="icon ni ni-arrow-left"> </em><span>{t('back_to_my_loans', {defaultValue:"Back to My Loans"})}</span></Link></div>
                  <br />
                  <div className="nk-block-between-md g-4">
                    <div className="nk-block-head-content">
                    <h2 className="nk-block-title fw-normal">{t('Loan:loanID',{defaultValue:"LoanID"})}: {loanTicket.loanID}</h2>
                    </div>
                  </div>
                  <div className="nk-block-between-md g-4">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title fw-normal">{loanTicket.borrowAsset} - {t('Common:APR',{defaultValue:"APR"})} {(loanTicket.interestRate * 1e2).toFixed(2)}% - {t('Common:Months',{defaultValue:"{{months}} Months", months:loanTicket.loanTerm})}</h3>
                    </div>
                    <div className="nk-block-head-content">
                      <ul className="nk-block-tools gx-3">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Header End */}

              {/* Basic Info Start */}
              <LoanDetailBasicInformation loanTicket={loanTicket}/>
              {/* Basic Info End */}

              <LoanDetailApplicationTxRef loanTicket={loanTicket}/>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}


export default LoanDetailsApplicationHistory