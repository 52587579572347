import React, { useEffect, useState } from 'react';
import Details from './Details'
import BorrowPageWrapper from '../../util/jacob/components/Borrow/BorrowPage'
import { BankAccountDetails } from './BankAccountDetails'
import { pipe } from '../../util/jacob/core/lambda';
import DialogConfirm from './DialogConfirm';
import hkd from '../product-icons/hkd.svg';
import { Link } from 'react-router-dom';
import { BorrowAsset, CollateralAsset, GetLTVs, LTV } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import { useFormatPrice } from '../../util/jacob/components/format';
import Slider from '@material-ui/core/Slider'

let BorrowHKDPageWrapper = BorrowPageWrapper('HKD', 'fiat');
let View = (props: BorrowPageWrapper.Api) => {
  const { t } = useTranslation(['Borrow','Common'])
  const [requireBankInfo, setRequireBankInfo] = React.useState(true);
  const [ltvs, setLTVs] = useState<LTV[]>([])
  const [availableLoanTerm, setAvailableLoanTerm] = useState<number[]>([])
  const [currentLoanTerm, setCurrentLoanTerm] = useState<number>(6)
  const [customborrowAmount, setCustomBorrowAmount] = React.useState<number>(0)

  const getData = async () =>{
    const ltvs = await GetLTVs(BorrowAsset.HKD)
    setLTVs(ltvs)

    let tempAvailableLoanTerm:number[] = []
    ltvs.map(ltv=>{
      if(!tempAvailableLoanTerm.includes(ltv.period)){
        tempAvailableLoanTerm.push(ltv.period)
      }
    })
    
    setAvailableLoanTerm(tempAvailableLoanTerm)
    if(tempAvailableLoanTerm && tempAvailableLoanTerm.length > 0  && ltvs.length > 0){
      setCurrentLoanTerm(tempAvailableLoanTerm[0])
      props.setReqQuote({
        loanTerm:ltvs[0].period,
        LTV:ltvs[0].ltv,
        borrowAsset: ltvs[0].asset,
        collateralAsset:CollateralAsset.BTC,
        repaymentAsset:BorrowAsset.HKD,
      })
    }

  }
  useEffect(()=>{
    getData()
  },[])
  

  const clearBorrowAmountRadio = () =>{
    var ele = document.getElementsByName("iv-amount")
    for(var i=0;i<ele.length;i++){
      let selectE = ele[i] as HTMLInputElement
      selectE.checked = false;
    }
  }
  const converToNumber = (value:string) =>{
    let calValue = parseFloat(value)
    if(typeof calValue === 'number' && isFinite(calValue)){
      // if(calValue < 5000)calValue = 5000
      if(calValue > 2000000) calValue = 2000000
      return calValue
    }else{
      return 0
    }
  }
  // const Timer = ()=>{
  //   return <Countdown date={Date.now() + 1000 * 60 * 60}  autoStart={false}/>
  // }
  // const [timer , setTimer] = useState(Date.now() + 1000 * 60 * 60)

  // slider settings 
  const [value, setValue] = React.useState<number>(0);
  
  // slider function 
  const SliderhandleChange = (event: any, newValue: number | number[] ) => {
    setValue(newValue as number)
    setCustomBorrowAmount(newValue as number)
  };
// slider marks 
  const marks = [
    {
      value: 0,
      label: '$0 HKD',
    },
    {
      value: 1000000,
      label: '$1M HKD',
    },
    {
      value: 2000000,
      label: '$2M HKD',
    },
  ];

  React.useEffect(() => {
    let oldValue = props.getReqQuote()
    props.setReqQuote({
      // ...initReq,
      ...oldValue,
      borrowAmount: customborrowAmount
    })
    // props.setReqQuote({ borrowAmount: customborrowAmount })
    // console.log("oldValue",oldValue)
  }, [customborrowAmount]);
  return (
    <React.Fragment>
        <div className="nk-content nk-content-lg nk-content-fluid">
          <div className="container-xl wide-lg">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-lg">
                  <div className="nk-block-head-content">
                    <div className="nk-block-head-sub"><Link to="/borrow" className="back-to"><em className="icon ni ni-arrow-left"></em><span>{t('Common:page_go_back',{defaultValue:"Back to plan"})}</span></Link></div>
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title fw-normal">{t('Borrow:hkd_borrow_page_title',{defaultValue:"Apply a HKD Loan"})}</h2>
                    </div>
                  </div>
                </div>
                <div className="nk-block invest-block">
                  <form action="#" className="invest-form">
                    <div className="row g-gs">
                      <div className="col-lg-12">
                      <h4>{t('Borrow:hkd_borrow_page_step1_title',{defaultValue:"Step 1: Enter Your Loan Detials"})}</h4>
                      <p>{t('Borrow:hkd_borrow_page_step1_description',{defaultValue:"Select how much you would like to borrow and collateral asset for the loan"})}</p>
                      <div className="row g-gs">
                        <div className="col-lg-6">
                        <div className="invest-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">{t('You will get')}</label>
                          </div>
                          <input type="hidden" value="silver" name="iv-plan" id="invest-choose-plan" />
                          <div className="dropdown invest-cc-dropdown">
                            <div className="invest-cc-choosen" data-toggle="dropdown">
                              <div className="coin-item">
                                <div className="coin-icon">
                                  <img src={hkd} className="icon" />
                                </div>
                                <div className="coin-info">
                                  <span className="coin-name">HKD</span>
                                  <span className="coin-text">{t('Your loan will arrive in your HKD bank account')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invest-field form-group">
                            <div className="form-group">
                              <label className="form-label" htmlFor="default-06">{t('Choose your interest rate / LTV Ratio')}</label>
                              <div className="form-control-wrap ">
                                <div className="form-control-select">
                                  <select
                                    className="form-control"
                                    id="default-06 ltv-ratio"
                                    onChange = {e=>{
                                      let ltv = ltvs[e.currentTarget.value as unknown as number]
                                      props.setReqQuote({
                                        LTV:ltv.ltv,
                                        loanTerm:ltv.period,
                                        borrowAsset: ltv.asset
                                      })
                                    }}
                                  >{
                                    ltvs.map((ltv, ltvIndex)=>{
                                      if(ltv.period !== currentLoanTerm)return
                                      return (
                                        <option
                                          value={ltvIndex}
                                          children={`${ltv.period} months - ${((ltv.rate * 1e2)).toFixed(2)}% APR @ ${(ltv.ltv * 1e2)}% LTV`}
                                          key={ltvIndex}
                                        />  
                                      )
                                    })
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="invest-field form-group">
                            <label className="form-label">{t('Choose Collateral Asset')}</label>
                            <div className="form-control-wrap">
                              <select
                                className="form-control"
                                onChange={event => (
                                  props.setReqQuote({ collateralAsset: event.target.value })
                                )}
                                defaultValue={CollateralAsset.BTC}
                              >
                                <option value="BTC">{t('Bitcoin - BTC')}</option>
                                <option value="ETH">{t('Ethereum - ETH')}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="invest-field form-group">
                          <label className="form-label">{t('Choose Loan Duration')}</label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control"
                              onChange={(event) => {
                                let tempLoanTerm = parseInt(event.currentTarget.value)
                                
                                setCurrentLoanTerm(tempLoanTerm)
                                let currentLTV = ltvs.find(e=>e.period === tempLoanTerm)
                                props.setReqQuote({
                                  loanTerm:  tempLoanTerm,
                                  LTV: currentLTV?.ltv
                                })
                              }}
                            >
                              {
                                availableLoanTerm.map((term, termIndex)=>{
                                  return(
                                    <option 
                                      value = {term}
                                      key = {termIndex}
                                      children={`${term} ${t('Months')}`}
                                    />
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>

                        <div className="invest-field form-group">
{/*  */}
                          {/* <div className="form-label-group">
                            <label className="form-label">{t('Choose Quick Amount to Borrow')}</label>
                          </div>
                          <div className="invest-amount-group g-2">
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-1" onClick={() => props.setReqQuote({ borrowAmount: 5000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-1">{useFormatPrice(BorrowAsset.HKD, 5000, true, false)}</label>
                            </div> */}
                            
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2" onClick={() => props.setReqQuote({ borrowAmount: 10000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-2">$ 10,000</label>
                            </div> */}
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3" onClick={() => props.setReqQuote({ borrowAmount: 50000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-3">{useFormatPrice(BorrowAsset.HKD, 50000, true, false)}</label>
                            </div> */}
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4" onClick={() => props.setReqQuote({ borrowAmount: 100000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-4">$ 100,000</label>
                            </div> */}
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-5" onClick={() => props.setReqQuote({ borrowAmount: 500000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-5">{useFormatPrice(BorrowAsset.HKD, 500000, true, false)}</label>
                            </div> */}
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-6" onClick={() => props.setReqQuote({ borrowAmount: 1000000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-6">{useFormatPrice(BorrowAsset.HKD, 1000000, true, false)}</label>
                            </div> */}
                            {/* <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-7" onClick={() => props.setReqQuote({ borrowAmount: 2000000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-7">{useFormatPrice(BorrowAsset.HKD, 2000000, true, false)}</label>
                            </div>
                          </div>
 */}
                          <div style={{marginTop:"13px"}}>
                          <label className="form-label">{t('Choose Quick Amount to Borrow')}</label>
                            <Slider  min={5000} max={2000000} step={5000} onChange={SliderhandleChange} marks={marks} ></Slider>
                            </div>
                        </div>
                        <div className="invest-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">{t('Or Enter Your Amount')}</label>
                          </div>
                          <div className="form-control-group">
                            <div className="form-info">HKD</div>
                            <input type="text" className="form-control form-control-amount form-control-lg" id="custom-amount" value={customborrowAmount} onClick = {clearBorrowAmountRadio} onChange = {e => setCustomBorrowAmount(converToNumber(e.target.value))}/>

                            {/*<input type="text" className="form-control form-control-amount form-control-lg" id="custom-amount" value="100.00" />*/}
                            <div className="form-range-slider" id="amount-step"></div>
                          </div>
                          <div className="form-note pt-2">{t('Minimum borrow 5,000 HKD and up to 2,000,000 HKD')}</div>
                        </div>
                        </div>
                        </div>
                        {/* <div className="invest-field form-group">
                          <div style={{"marginLeft": "35px"}} className="custom-control custom-control-xs custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="checkbox" />
                            <label className="custom-control-label" htmlFor="checkbox">I agree the <a href="#">terms and conditions.</a></label>
                          </div>
                        </div> */}
                      </div>
                      <BankAccountDetails
                        setState={pipe(props.setBankInfo, () => setRequireBankInfo(false))}
                        complete={!requireBankInfo}
                      />
                      <Details
                        {...{ ...props.getReqQuote(), ...props.getResQuote() }}
                        quote={props.quote}
                        createTicket={props.createTicket}
                        requireBankInfo={requireBankInfo}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      <DialogConfirm
        {...{...props}}
      />
    </React.Fragment>
  );
};
const BorrowHKDPage = (props: {}) => (
  <BorrowHKDPageWrapper
    view={View}
  />
)
export default BorrowHKDPage