import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import icons
import whatsapp from "./assets/images/whatsapp.png";
import wechat from "./assets/images/wechat.png";
import wechatQR from "./assets/images/wechat_qr.jpeg";

import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation("Common");
  return (
    <div className="nk-footer nk-footer-fluid bg-lighter">
      <div className="container-xl wide-lg">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {" "}
            &copy; {new Date().getFullYear()} Grand Prospects. Powered by{" "}
            <a href="#">DLO Digital Loan</a>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <Link className="nav-link" to="/terms">
                <li className="nav-item">{t("Terms & Conditions")}</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-xl wide-lg">
        <div className="nk-footer-links">
          <div className="nk-footer-wrap">
            <div>
              <Trans
                t={t}
                i18nKey="ask_for_support" // optional -> fallbacks to defaults if not provided
                defaults={`Support team: <mailtag>{{email}}</mailtag>`} // optional defaultValue
                values={{ email: process.env.REACT_APP_SUPPORT_MAIL }}
                components={{
                  mailtag: (
                    <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`} />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl wide-lg">
        <Row>
          <Col id="box1">
            <div className="nk-footer-links">
              <div className="nk-footer-wrap">
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    Money Lender's Licence No. 放債人牌照編號：1677/2020
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    Enquiry Hotline 查詢熱線：+852 5511 0147
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    Complain Hotline 投訴熱線：＋852 2195 1676
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    辦公時間：10:00 - 18:00
                    (星期一至星期五, 公眾假期除外)
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    忠告: 借錢梗要還，咪俾錢中介
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    Warning: You have to repay your loans. Don’t pay any
                    intermediaries
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col id="box2">
            <p style={{ marginBottom: "3px" }}>
              <img
                src={whatsapp}
                id="icon"
                style={{ marginRight: "7px", borderRadius: "8px" }}
              ></img>
              Whatsapp : +852 5511 0147
            </p>
            <p style={{ marginBottom: "3px" }}>
              <img
                src={wechat}
                id="icon"
                style={{ marginRight: "7px", borderRadius: "8px" }}
              ></img>
              <img
                src={wechatQR}
                style={{
                  height: "110px",
                  borderRadius: "12px",
                  marginLeft: "25px",
                }}
              ></img>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
// class Footer extends React.Component {

//     render() {

//     }
// }

export default Footer;
