import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
const DialogAddressWithCopy = ({
    address
}:{
    address:string
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    const addressRef = useRef<HTMLInputElement>(null);
    const copyToClipboard = () =>{
      if(addressRef.current){
        addressRef.current.select()
        document.execCommand('copy')
      }
    }
    return(
        <>
            <div className="nk-modal-form">
                <div className="form-group">
                    <input type="text" ref={addressRef} value={address} className="form-control form-control-password-samll text-center" readOnly />
                </div>
                <div className="nk-modal-action">
                    <a  onClick={copyToClipboard} className="btn btn-lg btn-mw btn-outline-primary">{t('Common:copy_to_clipboard',{defaultValue:"Copy to clipboard"})}</a>
                </div>
                <div className="nk-modal-action">
                    <div className="sub-text sub-text-alt mt-3 mb-4">{t('Loan:tx_reminder',{defaultValue:"Transaction will appear on the Transactions Page once received."})}</div>
                </div>
            </div>

        </>
    )
}

export default DialogAddressWithCopy