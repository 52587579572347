import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { AppContext, useAppContext } from "./contexts/appContext";
import DashboardPage from "./components/Dashboard/DashboardPage";
import LoanApplicationPage from "./components/MyLoans/LoanApplicationPage";

// import DialogWallet from "./components/MyLoans/components/DialogWallet";

import BorrowPage from "./components/Borrow/BorrowPage";
import BorrowHKDPage from "./components/Borrow/BorrowHKDPage";
import BorrowUSDTPage from "./components/Borrow/BorrowUSDTPage";
import ProfilePage from "./components/Profile/ProfilePage";
import LoginPage from "./components/Login/LoginPage";
import CryptoTransactionsPage from "./components/Transactions/CryptoTransactionsPage";
import WithdrawPageCrypto from "./components/Withdraw/WithdrawPageCrypto";
import KYCPage from "./components/KYC/KYCPage";
import KYCApplication from "./components/KYC/KYCApplication";
import KYCSuccess from "./components/KYC/KYCSuccess";
import KYCError from "./components/KYC/KYCError";
import TnCPage from "./components/Notices/TncPage";
// import ConfigFirebase from './config/firebaseConfig'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import createHistory from "./history";

import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import TopNav from "./layout/TopNav";
import TopNavOut from "./layout/TopNavOut";
import Footer from "./layout/Footer";
import { ValidateUser } from "./ApiService/auth";
import LoanDetails from "./components/MyLoans/LoanDetails";
import LoanApplicationHistoryPage from "./components/MyLoans/LoanApplicationHistoryPage";
import LoanActivePage from "./components/MyLoans/LoanActivePage";
import Docusign from "./components/Notifications/Docusign";

const WithContextApp = () => {
  const defaultContextValue = useAppContext();
  return (
    <AppContext.Provider value={defaultContextValue}>
      <App />
    </AppContext.Provider>
  );
};
const override = css`
  display: block;
  margin-left: 50%;
  margin-top: 20%;
`;
const validateRoute = [
  /\/login(?=\/|\b)/,
  "/dashboard",
  "/kyc",
  "/kyc/application",
  /\/kyc\/success/,
  /\/kyc\/error/,
  "/myloans",
  // /\/myloans\/details(?=\/|\b)/,
  /\/loan\/application/,
  /\/loan\/active/,
  /\/loan\/completed/,

  /\/loan\/application\/detail\/(?=\/|\b)/,
  /\/loan\/active\/detail\/(?=\/|\b)/,
  /\/loan\/completed\/detail\/(?=\/|\b)/,
  "/borrow",
  "/borrow/USDT",
  "/borrow/USD",
  "/profile",
  "/transactions/crypto",
  "/withdraw/crypto",
  "/docusign?event=signing_complete",
  "/docusign?event=cancel",
  "/docusign?event=viewing_complete",
  "/terms",
];

function App() {
  // auto login if token valid
  const { setCurrentUser, setSignin, isSignin, currentUser } =
    useContext(AppContext);
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [testing, setTesting] = useState<boolean>(true);
  let openner = false;
  const checkLogin = async (url: string) => {
    setIsValidating(true);
    const user = await ValidateUser();
    if (user !== undefined) {
      setCurrentUser(user);
      setSignin(true);
      setIsValidating(false);
      createHistory.push(url);
    } else {
      // failed to validate, faillback to login page
      setSignin(false);
      setIsValidating(false);
      if (/\/login(?=\/|\b)/.test(url)) {
        return createHistory.push(url);
      }
      createHistory.push("/login");
    }
  };

  useEffect(() => {
    let url = `${window.location.pathname}${window.location.search}`;
    console.log("original url", url);
    if (
      !validateRoute.some((route) =>
        typeof route === "string" ? route === url : route.test(url)
      )
    ) {
      //if(url === "/login" || !validateRoute.includes(url)){
      url = "/dashboard";
    }
    setTimeout(() => {
      setTesting(true);
      // openner = true;
    }, 100);
    checkLogin(url);
    // return unregisterAuthObserver;
  }, [isSignin]);
  return (
    <div className="App">
      <div className="nk-app-root">
        <Router history={createHistory}>
          <div className="nk-wrap">
            <Switch>
              {!isValidating && isSignin && (
                <>
                  <TopNav />
                  <Route path="/dashboard">
                    <DashboardPage />
                  </Route>
                  {/* <Route path="/testing">
                    <DialogWallet
                        timer={}
                      fgShown={true}
                      setFgShown={setTesting}
                      borrowAmount={10000}
                      borrowAsset={"USD"}
                      collateralAmount={123123123123}
                      collateralAsset={"wehfwefgbi"}
                      depositAddress={"qwrfiuyqwegfg"}
                    />
                  </Route> */}
                  <Route exact path="/kyc">
                    <KYCPage />
                  </Route>
                  <Route path="/kyc/application">
                    <KYCApplication />
                  </Route>
                  <Route path="/kyc/success">
                    <KYCSuccess />
                  </Route>
                  <Route path="/kyc/error">
                    <KYCError />
                  </Route>
                  <Route exact path="/myloans">
                    <LoanActivePage />
                  </Route>
                  <Route exact path="/loan/application">
                    <LoanApplicationPage />
                  </Route>
                  <Route exact path="/loan/application/history">
                    {/* <ApplicationHistoryPage /> */}
                    <LoanApplicationHistoryPage />
                  </Route>

                  <Route
                    exact
                    path="/loan/:loanTicketType/details/:loanID"
                    component={LoanDetails}
                  ></Route>
                  <Route exact path="/borrow">
                    <BorrowPage />
                  </Route>
                  <Route path="/docusign">
                    <Docusign />
                  </Route>
                  <Route path="/borrow/USDT">
                    <BorrowUSDTPage />
                  </Route>
                  <Route path="/borrow/HKD">
                    <BorrowHKDPage />
                  </Route>
                  <Route path="/profile">
                    <ProfilePage />
                  </Route>
                  <Route path="/transactions/crypto">
                    <CryptoTransactionsPage />
                  </Route>
                  <Route path="/withdraw/crypto">
                    <WithdrawPageCrypto />
                  </Route>
                  <Route path="/terms">
                    <TnCPage />
                  </Route>
                  <Route>
                    <Redirect to="/dashboard" />
                  </Route>
                  <ClipLoader
                    css={override}
                    size={50}
                    color={"#366CD7"}
                    loading={isValidating}
                  />
                  <Footer />
                </>
              )}
              {!isValidating && !isSignin && (
                <>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Route>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/terms">
                    <TopNavOut />
                    <TnCPage />
                    <Footer />
                  </Route>
                </>
              )}
              {isValidating && (
                <>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <ClipLoader
                    css={override}
                    size={50}
                    color={"#366CD7"}
                    loading={isValidating}
                  />
                </>
              )}
            </Switch>
          </div>
        </Router>
      </div>
    </div>
    // <div className="App">
    //     <div className="nk-app-root">
    //         {
    //             !isValidating &&
    //             <Router>
    //                 <Switch>
    //                     {
    //                         isSignin &&
    //                             <>
    //                                 <Route path="/dashboard">
    //                                     <DashboardPage />
    //                                 </Route>
    //                                 <Route path="/KYC">
    //                                     <KYCPage />
    //                                 </Route>
    //                                 <Route exact path="/myloans">
    //                                     <LoanActivePage />
    //                                 </Route>
    //                                 <Route path="/myloans/details">
    //                                     <LoanDetails />
    //                                 </Route>
    //                                 <Route exact path="/borrow">
    //                                     <BorrowPage />
    //                                 </Route>
    //                                 <Route path="/borrow/USDT">
    //                                     <BorrowUSDTPage />
    //                                 </Route>
    //                                 <Route path="/borrow/USD">
    //                                     <BorrowUSDPage />
    //                                 </Route>
    //                                 <Route path="/profile">
    //                                     <ProfilePage />
    //                                 </Route>
    //                                 <Route path="/transactions/crypto">
    //                                     <CryptoTransactionsPage />
    //                                 </Route>
    //                                 <Route path="/kyc">
    //                                     <DashboardPage />
    //                                 </Route>
    //                                 <Route>
    //                                     <Redirect to="/dashboard" />
    //                                 </Route>
    //                             </>
    //                     }
    //                     {
    //                         !isSignin &&
    //                         <>
    //                             <Route path="/login">
    //                                 <LoginPage />
    //                             </Route>
    //                             <Route>
    //                                 <Redirect to="/login" />
    //                             </Route>
    //                         </>
    //                     }

    //                 </Switch>
    //             </Router>
    //         }
    //         {
    //             isValidating &&
    //                 <ClipLoader
    //                 css={override}
    //                 size={50}
    //                 color={"#366CD7"}
    //                 loading={isValidating}
    //             />

    //         }
    //         </div>
    //     </div>
  );
}

export default WithContextApp;