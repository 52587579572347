//config firebase, should be env variable later

import * as firebase from 'firebase/app';
import 'firebase/firebase-auth'
import 'firebase/firebase-storage'
const firebaseConfig = {
    apiKey: "AIzaSyDENoCMp7WKsraiMARQSr1izuo2XWgjVK4",
    authDomain: "gp-clme-prod.firebaseapp.com",
    projectId: "gp-clme-prod",
    storageBucket: "gp-clme-prod.appspot.com",
    messagingSenderId: "1005402467321",
    appId: "1:1005402467321:web:0421600513d9423d0b6b28",
    measurementId: "G-7HE9GLNY2M"
  };

firebase.initializeApp(firebaseConfig);

export default firebase
// Configure Firebase.
 