import ConfigAxio from '../../config/configAxios'
import { UserVerificationStatus } from '../../contexts/appContext'

export enum BalanceAsset{
    ETH = "ETH",
    USD = "USD",
    USDT = "USDT",
    BTC = "BTC",
}

export interface AssetBalance{
    userID: number;
    availableBalance: number;
    balance: number;
    hold: number;
    pendingDeposit: number;
    pendingWithdraw: number;
    assetType: 'btc'|'eth'|'usd'|'usdt';
}
export interface AllAssetBalance{
    BtcBalance:AssetBalance,
    EthBalance:AssetBalance,
    UsdBalance:AssetBalance,
    UsdtBalance:AssetBalance,
}

export interface UserInfo{
    userID: number;
    verificationLevel: number;
    verificationStatus: UserVerificationStatus;
    verificationInformationStatus: UserVerificationStatus;
    verificationDocumentStatus: UserVerificationStatus;
    verificationJumioStatus: UserVerificationStatus;
}


export const GetUserShortByUserID = async() =>{
    try {
        const res = await ConfigAxio.get('/api/v1/user/')
        let data = <UserInfo>res.data
        return data
    } catch (error) {
        return undefined
    }
}

export const GetAllBalance = async() =>{
    try {
        const res = await ConfigAxio.get('/api/v1/balance/all')
        return res.data as unknown as AllAssetBalance
    } catch (error) {
        return undefined
    }
}


export const GetSingleAssetBalance = async(asset:BalanceAsset) =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/balance?asset=${asset}`)
        return res.data as unknown as AssetBalance
    } catch (error) {
        return undefined
    }
}

export interface UserKYCIdentifier{
    addressProofIdentifier?:string;
    bankStatementIdentifier?:string;
    proofOfIncomeIdentifier?:string;
    idDocIdentifier?:string;
}
export const UpdateUserKYCDoc = async(identiferData: UserKYCIdentifier) =>{
    try {
        await ConfigAxio.post(`/api/v1/user/kycDoc`,identiferData)
        return true
    } catch (error) {
        return false
    }
}