import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { DocuSignStatus, GetDocuSignInfoByLoanID, LoanDocuSign, LoanTicket } from '../../../ApiService/loan'

const LoanDetailApplicationStatus = ({
    loanTicket,
    docuSignInfo
}:{
    loanTicket:LoanTicket,
    docuSignInfo:LoanDocuSign
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    const showCounter = (datetime :string) => {
        let dueDate = new Date(datetime).getTime();
        return dueDate
    }

    return(
        <div className="nk-block nk-block-lg">

            <div className="nk-block-head">
                <h5 className="nk-block-title">{t('Loan:application_status',{defaultValue:"Loan Application Status: "})}<span className="badge badge-outline badge-primary"> {loanTicket.state}</span></h5>
            </div>

            <div className="row g-gs">
                <div className="col-lg col-sm-6">
                    <div className="card card-bordered h-100">
                        <div className="card-inner justify-center text-center h-100">
                            <div className="nk-iv-wg5">
                                <div className="nk-iv-wg5-head">
                                    <h5 className="nk-iv-wg5-title">{t('Loan:collateral_status',{defaultValue:"Loan Collateral Status"})}</h5>
                                    <p>{t('Loan:loan_application_time_limit_description',{defaultValue:"Please deposit collateral in:"})} <Countdown date={showCounter(moment(loanTicket.requestDate).format()) + (60*60*24*1000)} /></p>
                                </div>
                                <div className="nk-iv-wg5-ck sm">
                                    <input type="text" className="knob-half" data-thickness=".07" data-width="240" data-height="125" readOnly />
                                    <div className="nk-iv-wg5-ck-result">
                                        <div className="knob-half">
                                            {
                                            loanTicket.fundedAmount === 0 ?
                                                <span className="badge badge-sm badge-dim badge-outline-danger  d-md-inline-flex">{t('Loan:collateral_empty',{defaultValue:"Collateral Empty"})}</span>
                                            :
                                            loanTicket.fundedAmount < loanTicket.collateralAmount ? 
                                                <span className="badge badge-sm badge-dim badge-outline-warning  d-md-inline-flex">{t('Loan:collateral_not_enough',{defaultValue:"Collateral Not Enough"})}</span>
                                            :
                                                <span className="badge badge-sm badge-dim badge-outline-success  d-md-inline-flex">{t('Loan:collateral_enough',{defaultValue:"Completed"})}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg col-sm-6">
                    <div className="card card-bordered h-100">
                        <div className="card-inner justify-center text-center h-100">
                            <div className="nk-iv-wg5">
                                <div className="nk-iv-wg5-head">
                                    <h5 className="nk-iv-wg5-title">{t('Loan:contract_borrower_status',{defaultValue:"DocuSign Borrower Status"})} </h5>
                                </div>
                                <div className="nk-iv-wg5-ck sm">
                                    <input type="text" className="knob-half" data-thickness=".07" data-width="240" data-height="125" readOnly />
                                    <div className="nk-iv-wg5-ck-result">
                                        <div className="knob-half">
                                            {
                                                docuSignInfo && docuSignInfo.borrowerSignStatus === DocuSignStatus.New &&
                                                <span className="badge badge-sm badge-dim badge-outline-danger d-md-inline-flex">{t('Loan:non_signed_borrower',{defaultValue:"Confirming"})}</span>
                                            }
                                            {
                                                docuSignInfo && docuSignInfo.borrowerSignStatus === DocuSignStatus.Completed &&
                                                <span className="badge badge-sm badge-dim badge-outline-success d-md-inline-flex">{t('Loan:signed_agreement',{defaultValue:"Borrower Signed"})}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg col-sm-6">
                    <div className="card card-bordered h-100">
                        <div className="card-inner justify-center text-center h-100">
                            <div className="nk-iv-wg5">
                                <div className="nk-iv-wg5-head">
                                    <h5 className="nk-iv-wg5-title">{t('Loan:contract_admin_status',{defaultValue:" DocuSign Admin Status"})}</h5>
                                </div>
                                <div className="nk-iv-wg5-ck sm">
                                    <input type="text" className="knob-half" data-thickness=".07" data-width="240" data-height="125" readOnly />
                                    <div className="nk-iv-wg5-ck-result">
                                        <div className="knob-half">
                                            {
                                                docuSignInfo && docuSignInfo.adminSignStatus === DocuSignStatus.New &&
                                                <span className="badge badge-sm badge-dim badge-outline-danger  d-md-inline-flex">{t('Loan:non_signed_agreement',{defaultValue:"Admin Not Signed"})}</span>
                                            }
                                            {
                                                docuSignInfo && docuSignInfo.adminSignStatus === DocuSignStatus.Completed &&
                                                <span className="badge badge-sm badge-dim badge-outline-success  d-md-inline-flex">{t('Loan:signed_agreement',{defaultValue:"Admin Signed"})}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                  
            </div>

        </div>   
    )
}

export default LoanDetailApplicationStatus