import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoanTicket, LoanTicketApplication } from '../../../ApiService/loan'
import moment from 'moment';
import { useFormatPrice } from '../../../util/jacob/components/format';
import FirebaseUploadFileDisplayer from '../../FirebaseUploadFileDisplayer';

export const LoanDetailApplicationTxRef = ({
    loanTicket
}:{
    loanTicket:LoanTicketApplication
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
        <div className="nk-block">
        <div className="nk-block-head">
          <h5 className="nk-block-title">{t('Loan:loan_fund_release_ref',{defaultValue:"Loan Fund Release Ref: "})}</h5>
        </div>
        <div className="card card-bordered">
          <div id="schemeDetails" className="nk-iv-scheme-details">
          <ul className="nk-iv-wg3-list">
            <li>
              <div className="sub-text" >{t('Loan:loan_fund_release_bank_tx',{defaultValue:"Fund Tx"})}</div>
              <div className="lead-text" >{loanTicket.txRef}</div>
            </li>
            <li>
              {
                loanTicket.txProofURL && loanTicket.txProofURL !== null &&
                <FirebaseUploadFileDisplayer file={loanTicket.txProofURL}  width={500}/>
              }
            </li>
          </ul>
          </div>
        </div>
      </div>
    )
}

export default LoanDetailApplicationTxRef