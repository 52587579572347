export const Profile = {
    "my_profile":"個人頁面",
    "account_information":"帳號資訊",
    "userID":"用戶ID",
    "email":"電郵地址",
    "verification_level":"實名認證等級",
    "verification_status":"實名認證狀況",
    "personal_information":"個人資訊",
    "english_full_name":"英文全稱",
    "chinese_full_name":"中文全稱",
    "phone":"電話號碼",
    "dob":"出生日期",
    "nationality":"國籍",
    "address":"地址",
} 