export const TopNav = {
    "Dashboard": "账户",
    "Apply Loan":"申请贷款",
    "Loans": "贷款详情",
    "Active Loans": "贷款（已生效）",
    "Pending Applications": "贷款（申请中）",
    "Applications History": "贷款申请历史",
    "Withdraw": "提现",
    "Withdraw Crypto": "提现虚拟货币",
    "User ID": "用户ID",
    "Email": "电邮",
    "My Profile": "个人页面",
    "KYC Verification": "实名认证",
    "View Transactions":"交易记录",
    "Sign out":"登出"
}