import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader';
import { BorrowAsset, QuoteBorrowInterestRate } from '../../ApiService/loan';
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next';


const override = css`
  display: block;
  margin-left: 50%;
  margin-top: 20%;
`;

const Borrow = (prop:any) =>{
    const { t } = useTranslation(['Borrow','Common'])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hkdInterestRate, setHKDInterestRate] = useState<string>("Loading")
    const [usdtInterestRateRes, setUSDTInterestRateRes] = useState<string>("Loading")
    const getData = async() =>{
        // load from db
        // setIsLoading(true)
        // const hkdInterestRateRes = await QuoteBorrowInterestRate(BorrowAsset.HKD)
        // hkdInterestRateRes !== undefined && setHKDInterestRate(`${(hkdInterestRateRes.rate * 100).toFixed(2)}%`)
        // const usdtInterestRateRes = await QuoteBorrowInterestRate(BorrowAsset.USDT)
        // usdtInterestRateRes !== undefined && setUSDTInterestRateRes(`${(usdtInterestRateRes.rate * 100).toFixed(2)}%`)
        // setIsLoading(false)

        // direct show in page for a range
        setIsLoading(true)
        setHKDInterestRate(`10.44%-12.5%`)
        setUSDTInterestRateRes(`9.9%-11%`)
        setIsLoading(false)
    }

    useEffect(()=>{
        
        getData()
    },[])   
    return (
        
        <React.Fragment>

            <div className="nk-content nk-content-lg nk-content-fluid">
                <div className="container-xl wide-lg">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head text-center">
                                <div className="nk-block-head-content">
                                    {/* <div className="nk-block-head-sub"><span>Choose an Option</span></div> */}
                                    <div className="nk-block-head-content">
                                        <h2 className="nk-block-title fw-normal">{t('Borrow:borrow_page_title',{defaultValue:"What would you like to borrow?"})}</h2>
                                        <div className="nk-block-des">
                                            <p>{t('Borrow:borrow_page_type',{defaultValue:"Borrow HKD"})}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {

                                !isLoading ? 
                                <div className="nk-block">
                                    <div className={"row"} style={{justifyContent:'center',alignItems:'center'}}>
                                        <div className="plan-item col-lg-4">
                                            <div className="plan-item-card">
                                                <div className="plan-item-head">
                                                    <div className="plan-item-heading">
                                                        <h4 className="plan-item-title card-title title">{t('Common:HKD',{defaultValue:"HKD"})}HKD</h4>
                                                        <p className="sub-text">{t('Borrow:hkd_release_method',{defaultValue:"Your loan will be sent directly to your bank account"})}</p>
                                                    </div>
                                                    <div className="plan-item-summary card-text">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <span className="lead-text" style={{fontSize:"1.7rem"}}>{hkdInterestRate}</span>
                                                                <span className="sub-text">{t('Common:APR',{defaultValue:"APR"})}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="lead-text" style={{fontSize:"1.7rem"}}>35%-50%</span>
                                                                <span className="sub-text">{t('Common:LTV',{defaultValue:"LTV"})}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="plan-item-body">
                                                    <div className="plan-item-desc card-text">
                                                        <ul className="plan-item-desc-list">
                                                            <li><span className="desc-label">{t('Borrow:min_period',{defaultValue:"Min Period"})}</span> - <span className="desc-data">{t('Common:Days',{defaultValue:"{{days}} Days", days:1})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:max_period',{defaultValue:"Max Period"})}</span> - <span className="desc-data">{t('Common:Years',{defaultValue:"{{years}} Years", years:1})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:can_early_repayment',{defaultValue:"Early Repayment"})}</span> - <span className="desc-data">{t('Common:Yes',{defaultValue:"Yes"})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:can_early_termination',{defaultValue:"Early Termination"})}</span> - <span className="desc-data">{t('Common:Yes',{defaultValue:"Yes"})}</span></li>
                                                        </ul>
                                                        <div className="plan-item-action">
                                                            <Link className="btn btn-outline-secondary" to="/borrow/HKD">{t('Borrow:borrow_button',{defaultValue:"Borrow"})}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="plan-item col-lg-4">
                                            <div className="plan-item-card">
                                                <div className="plan-item-head">
                                                    <div className="plan-item-heading">
                                                        <h4 className="plan-item-title card-title title">{t('Common:USDT',{defaultValue:"USDT"})}USDT</h4>
                                                        <p className="sub-text">{t('Borrow:usdt_release_method',{defaultValue:"Your loan will arrive in your GP account"})}</p>
                                                    </div>
                                                    <div className="plan-item-summary card-text">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <span className="lead-text">{usdtInterestRateRes}</span>
                                                                <span className="sub-text">{t('Common:APR',{defaultValue:"APR"})}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="lead-text">30%-50%</span>
                                                                <span className="sub-text">{t('Common:LTV',{defaultValue:"LTV"})}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="plan-item-body">
                                                    <div className="plan-item-desc card-text">
                                                        <ul className="plan-item-desc-list">
                                                            <li><span className="desc-label">{t('Borrow:min_period',{defaultValue:"Min Period"})}</span> - <span className="desc-data">{t('Common:Days',{defaultValue:"{{days}} Days", days:1})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:max_period',{defaultValue:"Max Period"})}</span> - <span className="desc-data">{t('Common:Years',{defaultValue:"{{years}} Years", years:2})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:can_early_repayment',{defaultValue:"Early Repayment"})}</span> - <span className="desc-data">{t('Common:Yes',{defaultValue:"Yes"})}</span></li>
                                                            <li><span className="desc-label">{t('Borrow:can_early_termination',{defaultValue:"Early Termination"})}</span> - <span className="desc-data">{t('Common:Yes',{defaultValue:"Yes"})}</span></li>
                                                        </ul>
                                                        <div className="plan-item-action">
                                                            <Link className="btn btn-outline-secondary" to="/borrow/USDT">{t('Borrow:borrow_button',{defaultValue:"Borrow"})}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                :
                                <ClipLoader
                                css={override}
                                size={50}
                                color={"#366CD7"}
                                loading={isLoading}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default Borrow;