export const KYC = {
    "KYC_title":"實名認證",
    "KYC_title_done":"實名認證完成",
    "KYC_start_button":"實名認證",
    "kycFinishMsg":"你已完成實名認證，請點擊下面按鈕開始貸款。",
    "kycStartMsg":"點擊下面按鈕開始實名認證",
    "Start Verification":"開始認證",
    "Click here to start borrowing":"開始貸款",
    "regulation":"基於法律規定，所有貸款客戶均需完成實名認證",

    "KYC_none":"未實名認證",
    "KYC_pending":"等候實名認證中",
    "KYC_underReview":"實名認證審核中",
    "KYC_verified":"已完成實名認證",
    "KYC_unknown":"未知實名認證狀態",
    "unverified":"未認證",
    "verified":"已認證",

    "KYCFormStep1_not_empty":"必填",

    "KYCFormStep1_title":"個人資料、地址、工作",
    "KYCFormStep1_title_description":"實名認證需要您的個人資料、地址、工作、收入",
    "KYCFormStep1_guideline1":"請小心填寫您的個人資料，提交後將不能修改。",
    "KYCFormStep1_full_name_english":"英文全名（與身份證相同）",
    "KYCFormStep1_full_name_chinese":"中文全名（與身份證相同）",
    "KYCFormStep1_residential_address":"住址",
    "KYCFormStep1_mobile_phone":"手提電話",
    "KYCFormStep1_mobile_phone_length_limit":"必填，電話號碼必須為8位數字",
    "KYCFormStep1_source_of_income":"收入來源",
    "KYCFormStep1_saving":"儲蓄",
    "KYCFormStep1_salary":"薪金",
    "KYCFormStep1_investment_proceed":"投資",
    "KYCFormStep1_inheritance":"遺產",
    "KYCFormStep1_gift_from_family":"家人贈送",
    "KYCFormStep1_others":"其他",
    "KYCFormStep1_name_of_employer":"工作公司名稱",
    "KYCFormStep1_year_of_employment":"工作年資",
    "KYCFormStep1_income_currency":"收入貨幣種類",
    "KYCFormStep1_annual_inome":"工作年薪",
    "KYCFormStep1_finish_msg":"步驟1已完成，請繼續填寫下面資料",
    "KYCFormStep1_submit_button":"提交步驟1",
    "KYCFormStep1_verifying":"您的個人資料、地址、工作審核中，請繼續填寫其他資料",
    "KYCFormStep1_verified":"您的個人資料、地址、工作審核已通過，請繼續填寫其他資料",

    "KYCFormStep2_title":"上傳證明文件",
    "KYCFormStep2_title_description":"請上傳以下文件的電子副本供貸款審核。",
    "KYCFormStep2_guideline1":"請準備以下文件的電子副本：",
    "KYCFormStep2_id_card":"身份證",
    "KYCFormStep2_address_proof":"住址證明",
    "KYCFormStep2_bank_statement":"銀行月結單",
    "KYCFormStep2_income_statement":"收入證明",
    "KYCFormStep2_guideline2":"為了盡快通過您的申請，請確保以下事項：",
    "KYCFormStep2_guideline2_not_expire":"所有證明文件均需處於有效期內。",
    "KYCFormStep2_guideline2_visible":"文件需完整及內容清晰可見。",
    "KYCFormStep2_guideline2_no_light_glare":"文件沒有任何反光。",
    "KYCFormStep2_guideline2_image_format":"只接受jpeg, jpg, png, pdf格式的文件。",
    "KYCFormStep2_upload_id_card_title":"上傳身份證（上限：{{uploadIdCardLimit}} 張）",
    "KYCFormStep2_upload_bank_statement_title":"上傳銀行月結單（上限：{{uploadBankStatementLimit}} 張）",
    "KYCFormStep2_upload_income_statement_title":"上傳收入證明（上限：{{uploadIncomeStatementLimit}} 張）",
    "KYCFormStep2_upload_address_proof_title":"上傳地址證明（上限：{{uploadAddressProofLimit}} 張）",
    "KYCFormStep2_finish_msg":"所有文件上傳完成，請繼續完成認證",
    "KYCFormStep2_verifying":"您的證明文件審核中，請繼續填寫其他資料",
    "KYCFormStep2_verified":"您的證明文件審核已通過，請繼續填寫其他資料",

    
    "KYCForm_confirm_read_terms":"我已閱讀<1>條款</1>及<3>私隱政策</3>",
    "KYCForm_confirm_all_info_correct":"我確認以上資料及文件均為正確",
    "KYCForm_continue_verification_button":"繼續認證",

    "KYC_success_page_title":"實名認證已完成",
    // "KYC_success_page_description":"我們正在處理您的申請，請於稍後再次登錄網站查看最新實名認證狀態，或留意郵箱。",
    "KYC_success_page_under_review":"您的身份正在被核實，過程大約5-10分鐘，請於稍後再次登錄網站查看最新實名認證狀態，或留意郵箱。",
    "KYC_success_page_start_borrow_button":"開始貸款",

    "KYC_error_page_title":"實名認證失敗",
    "KYC_error_page_fail_msg":"我們無法辨認你的身份，請點擊下面按鈕重新進行人臉識別實名認證",
    "KYC_error_page_restart_button":"重新認證",
} 