import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { GetAllLoanTicketsByType, LoanTicketApplication, LoanTicketType } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import { LoanTicketSimples } from './components/LoanSimple';

const LoanApplicationHistoryPage = (props: {}) => {
  const { t } = useTranslation(['Loan','Common'])
  const [loanProcessedTicket, setLoanProcessedTicket] = useState<LoanTicketApplication[]>([])
  const [loanClosedTicket, setLoanClosedTicket] = useState<LoanTicketApplication[]>([])
  const getData = async() => {
    const loanProcessedTicketRes = await GetAllLoanTicketsByType(LoanTicketType.Application,"?state=processed")
    const loanClosedTicketRes = await GetAllLoanTicketsByType(LoanTicketType.Application,"?state=closed")
    if(loanProcessedTicketRes){
      setLoanProcessedTicket(loanProcessedTicketRes.entries  as LoanTicketApplication[])
    }
    if(loanClosedTicketRes){
      setLoanClosedTicket(loanClosedTicketRes.entries  as LoanTicketApplication[])
    }
  }
  useEffect(()=>{
    getData()
  },[])

  return (
  <React.Fragment>
    <div className="nk-content nk-content-lg nk-content-fluid">
      <div className="container-xl wide-lg">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head">
              <div className="nk-block-head-content">
                <div className="nk-block-head-sub"><span></span></div>
                <div className="nk-block-between-md g-4">
                  <div className="nk-block-head-content">
                    <h2 className="nk-block-title fw-normal">{t('loan_application_history_title',{defaultValue:"Loan Application History"})}</h2>
                  </div>
                  <div className="nk-block-head-content">
                    <ul className="nk-block-tools gx-3">
                      <li><Link to="/transactions/crypto" className="btn btn-primary"><span> {t('check_tx_button',{defaultValue:"View Transactions"})}</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block"></em></Link></li>
                      <li><Link to="/borrow" className="btn btn-white btn-light"><span>{t('borrow_more_button',{defaultValue:"Borrow More"})}</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block"></em></Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LoanTicketSimples 
              loanTicketType = {LoanTicketType.Application}
              title={t('Loan:loan_application_processed_subtitle',{defaultValue:'Processed Loan Applications'})}
              tickets={loanProcessedTicket}
            />
            <LoanTicketSimples 
              loanTicketType = {LoanTicketType.Application}
              title={t('Loan:loan_application_closed_subtitle',{defaultValue:'Closed Loan Applications'})}
              tickets={loanClosedTicket}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)};
export default LoanApplicationHistoryPage;