import ConfigAxio from '../../config/configAxios'
import { User, UserKYC } from '../../contexts/appContext'
export const GetUserData = async() =>{
    try {
        const res = await ConfigAxio.get('/api/v1/user')
        let user = <User>res.data
        return user
    } catch (error) {
        return undefined
    }
}

export const GetUserKYC = async() =>{
    try {
        const res = await ConfigAxio.get('/api/v1/user/kyc/info')
        let user = <UserKYC>res.data
        return user
    } catch (error) {
        return undefined
    }
}
