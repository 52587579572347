import React, { useEffect, useState } from 'react';

import { css } from "@emotion/core";
import { Link } from "react-router-dom";

import { LoanTicketType, Repayment, GetRepaymentsByLoanID,GetTransactionsByLoanID, Transaction, LoanTicketActive} from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import LoanDetailBasicInformation from './loanDetailComponents/LoanDetailBasicInformation';
import LoanDetailRepayments from './loanDetailComponents/LoanDetailRepayments';
import LoanDetailTransactions from './loanDetailComponents/LoanDetailTransactions';


const override = css`
  display: block;

`;

const LoansDetailsCompleted = ({
  loanID,
  loanTicketType,
  loanTicket
}:{
  loanID:string
  loanTicketType:LoanTicketType
  loanTicket:LoanTicketActive
}) =>{
  const { t } = useTranslation(['Loan','Common'])
  const [repayments, setRepayments] = useState<Repayment[]>([])
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const GetLoanRepaymentTickets = async() =>{
    const repaymentsRes = await GetRepaymentsByLoanID(loanTicket.loanID)
    if(repaymentsRes.success){
      setRepayments(repaymentsRes.apiData.entries)
    }
  }
  const GetTxs = async () =>{
    const txsRes = await GetTransactionsByLoanID(loanTicket.loanID)
    if(txsRes.success){
      setTransactions(txsRes.apiData.entries)
    }
  }
  
  const LoanDetailCompletedHeader = () =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub"><Link to="/myloans" className="text-soft back-to"><em className="icon ni ni-arrow-left"> </em><span>{t('back_to_my_loans', {defaultValue:"Back to My Loans"})}</span></Link></div>
          <br />
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">{t('Loan:loanID',{defaultValue:"LoanID"})}: {loanTicket.loanID}</h2>
            </div>
          </div>
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title fw-normal">{loanTicket.borrowAsset} - {t('Common:APR',{defaultValue:"APR"})} {(loanTicket.interestRate * 1e2).toFixed(2)}% - {t('Common:Months',{defaultValue:"{{months}} Months", months:loanTicket.loanTerm})}</h3>
            </div>
            <div className="nk-block-head-content">
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(()=>{
    GetLoanRepaymentTickets()
    GetTxs()
  },[])

  return (
    <React.Fragment>

      <div className="nk-content nk-content-lg nk-content-fluid">
        <div className="container-xl wide-lg">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <LoanDetailCompletedHeader />
              <LoanDetailBasicInformation loanTicket={loanTicket} />
              {
                repayments &&
                <LoanDetailRepayments repayments={repayments} />
              }
              {
                <LoanDetailTransactions transactions={transactions}/>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}


export default LoansDetailsCompleted