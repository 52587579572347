import { AxiosResponse } from 'axios'
import ConfigAxio from '../../config/configAxios'
import { BorrowAsset, CollateralAsset } from '../loan'

export interface ApiGlobalResponse<T> {
    success:boolean;
    statusCode:number;
    errMessage:string;
    apiData: T
}
export const defaultApiRes:ApiGlobalResponse<any> ={
    success:true,
    statusCode:200,
    errMessage:"",
    apiData:"",
}
export const SetErrorInApiRes = (error:any, apiRes:ApiGlobalResponse<any>):void =>{
    let errorRes:AxiosResponse = error.response as AxiosResponse
    console.log("SetErrorInApiRes errorRes",errorRes)
    apiRes.success = false
    if(errorRes){
        apiRes.statusCode = errorRes.status
        apiRes.errMessage = errorRes.data.message
    }
}

export const GetDefinedParam = (from:any) =>{
    let newData:{[key:string]:any} = {};
    (Object.keys(from)as Array<keyof typeof from>).forEach(e => {
        if(from[e] != undefined){
            newData[String(e)] = from[e]
        }
    });
    return newData
}

export interface Pagination {
    itemPerPage:number
    currentPage:number
}
export const PaginationToQuery = (p:Pagination) =>{
    let limit = p.itemPerPage <= 0 ? 1 :  (p.itemPerPage > 100 ? 100 : p.itemPerPage) //limit always between 1 to 100
    let offset = p.currentPage <= 0 ? 0 : (p.currentPage - 1) * limit //offset always 0 or limit * (page number - 1)
    return { limit, offset}
}
// export const defaultApiGlobalResponse = (config:ApiGlobalResponse<any>) => {
    
// }
export interface ResponseGetEntries<TEntry> {
    entries:TEntry[];
    total: number;
    limit: number;
    offset: number;
}

export interface GetAllCoinPriceInUSDRes {
    btcPrice:number;
    ethPrice:number;
    usdtPrice:number;
}

export const GetExchangeRate = async(borrowAsset:BorrowAsset, collateralAsset:CollateralAsset) =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/exchangeRate/?assetBase=${borrowAsset}&assetQuote=${collateralAsset}`)
        let data = res.data as {rate?:number}
        return data.rate
    } catch (error) {
        return undefined
    }
}

export const GetAllCoinPriceInUSD = async() =>{
    try {
        const res = await ConfigAxio.get(`/api/v1/loan/allCoinPriceInUSD`)
        let info = <GetAllCoinPriceInUSDRes>res.data
        return info
    } catch (error) {
        return undefined
    }
}

export const CalculateDayDifference =(date1:Date, date2:Date)=>{
    // To calculate the time difference of two dates 
  var Difference_In_Time = date2.getTime() - date1.getTime(); 
    
  // To calculate the no. of days between two dates 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

  return Math.floor(Difference_In_Days)
}
