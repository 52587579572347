import React, { Fragment, useEffect, useRef, useState } from 'react';

import DialogWallet from './components/DialogWallet'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import { DocuSignStatus, GetLoanTicketByID, LoanTicketType, RefreshDocuSignURLByLoanID, GetDocuSignInfoByLoanID, LoanDocuSign, LoanTicketApplication } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import LoanDetailApplicationStatus from './loanDetailComponents/LoanDetailApplicationStatus';
import LoanDetailBasicInformation from './loanDetailComponents/LoanDetailBasicInformation';

const override = css`
  display: block;
  margin-left: 50%;
  margin-top: 20%;
`;

const LoansDetailsApplication = ({
  loanID,
  loanTicketType,
  loanTicket
}:{
  loanID:string
  loanTicketType:LoanTicketType
  loanTicket:LoanTicketApplication
}) =>{
  const { t } = useTranslation(['Loan','Common'])

  const [fgWalletDialog, setFgWalletDialog] = React.useState(false);
  const [newDepositAddress, setNewDepositAddress] = useState<string>("")
  const [docuSignLoading, setDocuSignLoading] = React.useState(false);
  const intervalRef = useRef<NodeJS.Timeout>()
  const [docuSignInfo, setDocuSignInfo] = useState<LoanDocuSign | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const handleRefreshAddress = async () =>{
    const ticketRes = await GetLoanTicketByID(loanTicket.loanID, LoanTicketType.Application)
    if(ticketRes.success){
      if(ticketRes.apiData.depositAddress && ticketRes.apiData.depositAddress!== "asigning address"){
        setNewDepositAddress(ticketRes.apiData.depositAddress)
        clearInterval(intervalRef.current as unknown as number);
      }
    }
  }
  const GetDocusign = async () =>{
    const docuSignInfoRes = await GetDocuSignInfoByLoanID(loanTicket.loanID)
    if(docuSignInfoRes.success){
      setDocuSignInfo(docuSignInfoRes.apiData)
    }
  }
  const handleGetDocuSignURL = async()=>{
    setDocuSignLoading(true)
    const docuSignRes = await RefreshDocuSignURLByLoanID(loanTicket.loanID)
    window.open (docuSignRes?.docuSignURL,"Crypto Loan DocuSign","menubar=1,resizable=1,width=1024,height=768");
    setDocuSignLoading(false)
  }

  const getData = async() =>{
    await GetDocusign()
    setLoading(false)
  }

  useEffect(()=>{
    getData()
  },[])

  useEffect(()=>{
    
    if(!(loanTicket.depositAddress && loanTicket.depositAddress!== "asigning address")){
      intervalRef.current = setInterval(() => {
        handleRefreshAddress()
      },10000)
      return () => clearInterval(intervalRef.current as unknown as number);
    }else{
      setNewDepositAddress(loanTicket.depositAddress)
    }
  },[loanTicket])

  const LoanDetailApplicationHeader = () =>{
    return(
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub"><Link to="/myloans" className="text-soft back-to"><em className="icon ni ni-arrow-left"> </em><span>{t('back_to_my_loans', {defaultValue:"Back to My Loans"})}</span></Link></div>
          <br />
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">{t('Loan:loanID',{defaultValue:"LoanID"})}: {loanTicket.loanID}</h2>
            </div>
          </div>
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title fw-normal">{loanTicket.borrowAsset} - {t('Common:APR',{defaultValue:"APR"})} {(loanTicket.interestRate * 1e2).toFixed(2)}% - {t('Common:Months',{defaultValue:"{{months}} Months", months:loanTicket.loanTerm})}</h3>
            </div>
            <div>
              {/* middle contant  */}
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools gx-3">
                {
                  docuSignInfo && docuSignInfo.borrowerSignStatus === DocuSignStatus.New &&
                  (
                    !docuSignLoading ? 
                    <li className="order-md-last">
                      <button type="button" className="btn btn-primary" onClick={event => {
                        event.preventDefault();
                        handleGetDocuSignURL()
                      }} style={{"fontSize":"120%","padding":"10px","lineHeight":"180%"}}>{t('Loan:First step')}<br></br>{t('Loan:sign_agreement',{defaultValue:"Sign Loan Agreement"})}</button>
                    </li>
                  :
                    <ClipLoader
                      css={css`
                      display: block;
                    `}
                      size={21}
                      color={"#366CD7"}
                      loading={true} 
                    />
                  )


                }
                {
                  docuSignInfo && docuSignInfo.borrowerSignStatus === DocuSignStatus.Completed &&
                  <li className="order-md-last">
                      <button type="button" className="btn btn-primary" disabled={true}>{t('Loan:signed_agreement',{defaultValue:"Agreement signed"})}</button>
                  </li>
                }
                
                <li className="order-md-last">
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#invest-plan" onClick={event => {
                    event.preventDefault();
                    setFgWalletDialog(true);
                  }} style={{"fontSize":"120%","padding":"10px","lineHeight":"180%"}}>{t('Loan:Second step')}<br></br>{t('Loan:deposit_collateral',{defaultValue:"Deposit Collateral"})}</button>
                </li>
                {/* <li><a href="#" className="btn btn-icon btn-light"><em className="icon ni ni-reload"></em></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }


//   const loanDetailApplicationStatus = (docuSignInfo:LoanDocuSign)=>{

//     return<LoanDetailApplicationStatus loanTicket = {loanTicket} docuSignInfo={docuSignInfo}/>

// }

  if(loading){
    return(
      <Fragment>
        <div className="nk-content nk-content-lg nk-content-fluid">
          <div className="container-xl wide-lg">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <ClipLoader
                  css={override}
                  size={21}
                  color={"#366CD7"}
                  loading={loading} 
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return (
    <React.Fragment>

      <div className="nk-content nk-content-lg nk-content-fluid">
        <div className="container-xl wide-lg">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <LoanDetailApplicationHeader />
              {
                docuSignInfo &&
                <LoanDetailApplicationStatus loanTicket = {loanTicket} docuSignInfo={docuSignInfo}/>
              }
              <LoanDetailBasicInformation loanTicket={loanTicket} />
  
            </div>
          </div>
        </div>
      </div>

      <DialogWallet
        loanTicket={loanTicket}
        docuSignInfo={docuSignInfo}
        // timer={loanDetailApplicationStatus(docuSignInfo)} 
        fgShown={fgWalletDialog} 
        setFgShown={setFgWalletDialog}
        {
          ...{
            ...loanTicket,
            depositAddress:newDepositAddress
          }
        } 
      />
    </React.Fragment>
  );

}


export default LoansDetailsApplication