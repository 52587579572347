export const Dashboard = {
    "Asset": "资产",
    "Price": "最新价格",
    "Balance":"全部",
    "Amount": "总金额",
    "Locked": "借款冻结",
    "Total Value": "总钱包价值",
    "Status": "钱包状态",
    "Bitcoin - BTC": "比特币 - BTC",
    "Confirmed": "已确认",
    "Withdraw": "提现",
    "History": "检阅历史",
    "Details": "了解更多",
    "Ethereum - ETH": "以太坊 - ETH",
    "Tether - USDT": "USDT",
    "Welcome back!": "你好!",
    "4 steps to take out a Crypto-backed loan": "申请贷款的4个步骤",
    "Verify Identity": "验证身份",
    "Once verified, you will be able to apply for a loan": "验证后，您将可以申请贷款",
    "Apply Loan": "申请贷款",
    "Get a quote and submit your loan application": "获取报价并提交您的贷款申请",
    "Sign Agreement": "签署协议",
    "Sign loan agreement electronically": "电子签署贷款协议",
    "Deposit Collateral": "存款抵押",
    "Send coins to our wallet to release funds": "将抵押币发送到我们的钱包以释放贷款资金",
    "Account Overview": "我的账户:",
    "Total Portfolio Balance": "帐户总金额",
    "No Repayment": "没有生效贷款",
    " Active Loans": " 已生效贷款",
    "Available Loan Amount": "可用贷款额",
    "Balance in Account": "帐户余额",
    "Available Funds": "可用资金",
    "Locked Funds": "借款冻结",
    "Pending Withdraw Funds": "待提款",
    "Total": "总金额",
    "Withdraw Funds": "提款",
    "Next Interest Repayment": "下次还款",
    "Payment Due": "还款金额",
    "Payment Currency": "还款货币",
    "Due Date": "还款日",
    "Repayment Status": "还款状态",
    "Repay early to avoid <Link to={`/myloans/details/?type=active&loanID=${nextRepayment?.loanID}`} className='link link-dark'> Late Fees!</Link>": "尽早还款以避免<Link to={`/myloans/details/?type=active&loanID=${nextRepayment?.loanID}`} className='link link-dark'>费用</Link>",
    "Repay Interest Now": "立即偿还利息",
    "My Active Loans": "我的贷款",
    "See Loan Status": "贷款状态",
    "Verify Identity (KYC)": "开始认证",
}