import React, { useState, useEffect, useContext } from 'react';
import CryptoTable from './CryptoTable'
import './dashboard.css';
import { AppContext, UserVerificationStatus } from '../../contexts/appContext';
import {
    Link
  } from "react-router-dom";
// import {guard} from '../../util/jacob/core/lambda'
import moment from 'moment';
import { GetAllBalance } from '../../ApiService/user';
import {GetAllActiveLoans, GetAllRepayments,LoanTicketActive, Repayment, RepaymentState } from '../../ApiService/loan';
import { GetAllCoinPriceInUSD } from '../../ApiService/shareInfo';
import { GetAllCoinPriceInUSDRes } from '../../ApiService/shareInfo';
import { AllAssetBalance } from '../../ApiService/user';
import { useTranslation } from 'react-i18next';
import { useFormatPrice } from '../../util/jacob/components/format';
import ConfigAxio from '../../config/configAxios'
export interface UserBalanceSummarise {
    totalBtcInUSD:number;
    totalEthInUSD:number;
    totalUsdtInUSD:number;
    totalCryptoInUSD: number;
    totalAvailableLoanUSD: number
}
const DashboardPage = () => {
    const { currentUser, currentUserSetting } = useContext(AppContext);
    // const [referalURL, setReferalURL] = useState<string>("https://grandprospects.com/?ref=4945KD48")
    const [userBalance, setUserBalance] = useState<AllAssetBalance | undefined>(undefined)
    const [userBalanceSummarise, setUserBalanceSummarise] = useState<UserBalanceSummarise | undefined>(undefined)
    const [coinMarketPrice, setCoinMarketPrice] = useState<GetAllCoinPriceInUSDRes | undefined>(undefined)
    const [nextRepayment, setNextRepayment] = useState<Repayment | undefined>(undefined)
    const [activeLoans, setActiveLoans] = useState<LoanTicketActive[]>([])
    const { t } = useTranslation('Dashboard')

   const GetExchangeRate = async(assetBase: string, assetQuote: string): Promise<{rate: number}> =>{
        const res = await ConfigAxio.get(`/api/v1/loan/exchangeRate?assetBase=${assetBase}&assetQuote=${assetQuote}`)
        // console.log(res.data)
        return res.data;
    }
    // GetExchangeRate('HKD','BTC')
    const getData = async () => {

            const pricesRes = await GetAllCoinPriceInUSD()
            if(pricesRes === undefined){
                return
            }
            setCoinMarketPrice(pricesRes)

            const balanceRes = await GetAllBalance()
            if(balanceRes === undefined){
                return
            }
            setUserBalance(balanceRes)

            let userSummarise:UserBalanceSummarise = {
                totalBtcInUSD:pricesRes.btcPrice * balanceRes.BtcBalance.balance,
                totalEthInUSD:pricesRes.ethPrice * balanceRes.EthBalance.balance,
                totalUsdtInUSD:pricesRes.usdtPrice * balanceRes.UsdtBalance.balance,
                totalCryptoInUSD:0,
                totalAvailableLoanUSD:0
            }
            userSummarise.totalCryptoInUSD = userSummarise.totalBtcInUSD * 1 + userSummarise.totalEthInUSD * 1 + userSummarise.totalUsdtInUSD * 1
            userSummarise.totalAvailableLoanUSD = ((pricesRes.btcPrice * balanceRes.BtcBalance.availableBalance) + (pricesRes.ethPrice * balanceRes.EthBalance.availableBalance) + (pricesRes.usdtPrice * balanceRes.UsdtBalance.availableBalance)) / 2
            
            setUserBalanceSummarise(userSummarise)

            const nextRepaymentRes = await GetAllRepayments()
            if(nextRepaymentRes.success){
                if(nextRepaymentRes.apiData.total > 0){
                    let filtered = nextRepaymentRes.apiData.entries.filter(e=>(e.state !== RepaymentState.Cancelled && e.state !== RepaymentState.Paid))
                    // setNextRepayment(filtered[0])
                    if(filtered.length > 0){
                        setNextRepayment(filtered[0])
                    }
                }
            }

            const activeLoans = await GetAllActiveLoans()
            if(activeLoans?.length > 0){
                setActiveLoans(activeLoans)
            }

    }
    useEffect(() => {
        getData()
    }, [])
    
    return (
        <React.Fragment>
            <div className="nk-content nk-content-lg nk-content-fluid">
                <div className="container-xl wide-lg">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head">
                                <div className="nk-block-between-md g-3">
                                    <div className="nk-block-head-content">
                                        <div className="nk-block-head-sub"><span>{t('Welcome back!')}</span></div>
                                        <div className="align-center flex-wrap pb-2 gx-4 gy-3">
                                            <div>
                                                <h4 className="nk-block-title fw-normal">{currentUser?.email}</h4>
                                            </div>
                                            {
                                                currentUser?.verificationStatus !== UserVerificationStatus.Verified && 
                                                <div><Link to="/KYC" className="btn btn-white btn-light">{t('Verify Identity (KYC)')}<em className="icon ni ni-arrow-long-right ml-2"></em></Link></div>
                                            }
                                            {/* <div><Link to="/KYC" className="btn btn-white btn-light">Verify Identity (KYC)<em className="icon ni ni-arrow-long-right ml-2"></em></Link></div> */}
                                            {/* <div><Link to="/withdraw/crypto" className="btn btn-white btn-light">Withdraw<em className="icon ni ni-arrow-long-right ml-2"></em></Link></div> */}
                                        </div>
                                    </div> 
                                </div>
                            </div>{/*
                            <div className="nk-block">
                                <div className="nk-news card card-bordered">
                                    <div className="card-inner">
                                        <div className="nk-news-list">
                                            <a className="nk-news-item" href="#">
                                                <div className="nk-news-icon">
                                                    <em className="icon ni ni-card-view"></em>
                                                </div>
                                                <div className="nk-news-text">
                                                    <p>You have no margin call at the moment</p>
                                                    <em className="icon ni ni-external"></em>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="nk-block">
                                <h2 style={{"marginBottom": "10px"}} className="nk-block-title fw-normal">{t('4 steps to take out a Crypto-backed loan')}</h2>
                                <div className="row gy-gs">
                                    <div className="col-sm-12 col-md-4 col-lg-3">
                                        <div className="card card-bordered">
                                            <div className="nk-kycfm">
                                                <div className="nk-kycfm-head">
                                                    <div className="nk-kycfm-title">
                                                        <div style={{"marginBottom": "5px"}} className="nk-kycfm-count">01</div>
                                                        <h5 className="title">{t('Verify Identity')}</h5>
                                                        <p style={{"minHeight": "60px"}} className="sub-title">{t('Once verified, you will be able to apply for a loan')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-3">
                                        <div className="card card-bordered">
                                            <div className="nk-kycfm">
                                                <div className="nk-kycfm-head">
                                                    <div className="nk-kycfm-title">
                                                        <div style={{"marginBottom": "5px"}} className="nk-kycfm-count">02</div>
                                                        <h5 className="title">{t('Apply Loan')}</h5>
                                                        <p style={{"minHeight": "60px"}} className="sub-title">{t('Get a quote and submit your loan application')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-3">
                                        <div  className="card card-bordered">
                                            <div className="nk-kycfm">
                                                <div className="nk-kycfm-head">
                                                    <div className="nk-kycfm-title">
                                                        <div style={{"marginBottom": "5px"}} className="nk-kycfm-count">03</div>
                                                        <h5 className="title">{t('Sign Agreement')}</h5>
                                                        <p style={{"minHeight": "60px"}} className="sub-title">{t('Sign loan agreement electronically')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-3">
                                    <div className="card card-bordered">
                                            <div className="nk-kycfm">
                                                <div className="nk-kycfm-head">
                                                    <div className="nk-kycfm-title">
                                                        <div style={{"marginBottom": "5px"}} className="nk-kycfm-count">04</div>
                                                        <h5 className="title">{t('Deposit Collateral')}</h5>
                                                        <p style={{"minHeight": "60px"}} className="sub-title">{t('Send coins to our wallet to release funds')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            <div className="nk-block">
                                <div style={{"marginBottom": "10px"}} className="nk-block-des">
                                    <h3 className="fw-normal">{t('Account Overview')}</h3>
                                </div>
                                <div className="row gy-gs">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="nk-wg-card is-dark card card-bordered">
                                            <div className="card-inner">
                                                <div className="nk-iv-wg2">
                                                    <div className="nk-iv-wg2-title">
                                                        <h6 className="title">{t('Total Portfolio Balance')} <em className="icon ni ni-info"></em></h6>
                                                    </div>
                                                    <div className="nk-iv-wg2-text">
                                                        <div className="nk-iv-wg2-amount">{useFormatPrice(currentUserSetting.displayCurrency,userBalanceSummarise === undefined ? 0 : userBalanceSummarise.totalCryptoInUSD * currentUserSetting.usdToCurrency,true)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="nk-wg-card is-s1 card card-bordered">
                                            <div className="card-inner">
                                                <div className="nk-iv-wg2">
                                                    <div className="nk-iv-wg2-title">
                                                        <h6 className="title">{t('Available Loan Amount')}<em className="icon ni ni-info"></em></h6>
                                                    </div>
                                                    <div className="nk-iv-wg2-text">
                                                        <div className="nk-iv-wg2-amount">{useFormatPrice(currentUserSetting.displayCurrency,userBalanceSummarise === undefined ? 0 : userBalanceSummarise.totalAvailableLoanUSD * currentUserSetting.usdToCurrency,true)}<span className="change"><span className="sign"></span>@50% LTV</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div> 
                                <CryptoTable
                                    // balance = {balObj}
                                    userBalance = {userBalance}
                                    userBalanceSummarise = {userBalanceSummarise}
                                    coinMarketPrice = {coinMarketPrice}
                                />
                            <div className="nk-block">
                                <div className="row gy-gs">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="nk-wg-card card card-bordered h-100">
                                            <div className="card-inner h-100">
                                                <div className="nk-iv-wg2">
                                                    <div className="nk-iv-wg2-title">
                                                        <h6 className="title">{t('Balance in Account')}</h6>
                                                    </div>
                                                    <div className="nk-iv-wg2-text">
                                                        <div className="nk-iv-wg2-amount ui-v2">{useFormatPrice(currentUserSetting.displayCurrency,userBalance === undefined ? 0 : userBalance.UsdBalance.balance * currentUserSetting.usdToCurrency,true)}</div>
                                                        <ul className="nk-iv-wg2-list">
                                                            <li>
                                                                <span className="item-label">{t('Available Funds')}</span>
                                                                <span className="item-value">{useFormatPrice(currentUserSetting.displayCurrency,userBalance === undefined ? 0 : userBalance.UsdBalance.availableBalance * currentUserSetting.usdToCurrency,true)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="item-label">{t('Locked Funds')}</span>
                                                                <span className="item-value">{useFormatPrice(currentUserSetting.displayCurrency,userBalance === undefined ? 0 : userBalance.UsdBalance.hold * currentUserSetting.usdToCurrency,true)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="item-label">{t('Pending Withdraw Funds')}</span>
                                                                <span className="item-value">{useFormatPrice(currentUserSetting.displayCurrency,userBalance === undefined ? 0 : userBalance.UsdBalance.pendingWithdraw * currentUserSetting.usdToCurrency,true)}</span>
                                                            </li>
                                                            {/* <li className="total">
                                                                <span className="item-label">{t('Total')}</span>
                                                                <span className="item-value">${userBalance ? userBalance?.UsdBalance.balance * currentUserSetting.usdToCurrency : 'Loading'}</span>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="nk-iv-wg2-cta">
                                                        <Link to="/withdraw/crypto" className="btn btn-primary btn-lg btn-block">{t('Withdraw Funds')}</Link>
                                                        {/* <a href="#" className="btn btn-trans btn-block">Deposit Funds</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-6 col-lg-4">
                                        <div className="nk-wg-card card card-bordered h-100">
                                            <div className="card-inner h-100">
                                                <div className="nk-iv-wg2">
                                                    <div className="nk-iv-wg2-title">
                                                        <h6 className="title">{t('Next Interest Repayment')} <em className="icon ni ni-info text-primary"></em></h6>
                                                    </div>
                                                    <div className="nk-iv-wg2-text">
                                                        <div className="nk-iv-wg2-amount ui-v2">{useFormatPrice(nextRepayment?.repaymentAsset,nextRepayment === undefined ? 0 : nextRepayment.repaymentAmount,true)}</div>
                                                        <ul className="nk-iv-wg2-list">
                                                            <li>
                                                                <span className="item-label">{t('Payment Due')}</span>
                                                                <span className="item-value">{useFormatPrice(nextRepayment?.repaymentAsset,nextRepayment === undefined ? 0 : nextRepayment.repaymentAmount,true)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="item-label">{t('Payment Currency')}</span>
                                                                <span className="item-value">{nextRepayment? nextRepayment.repaymentAsset : ''}</span>
                                                            </li>
                                                            <li>
                                                                <span className="item-label">{t('Due Date')}</span>
                                                                {/* <span className="item-value">{balObj.paymentDue? balObj.paymentDue : ''}</span> */}
                                                                <span className="item-value">{nextRepayment? moment(nextRepayment.repaymentDate).format("YYYY-MM-DD hh:mm A") : ''}</span>
                                                                
                                                            </li>
                                                            <li className="total">
                                                                <span className="item-label">{t('Repayment Status')}</span>
                                                                <span className="item-value">{nextRepayment? nextRepayment.state : ''}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="nk-iv-wg2-cta">
                                                        {/*<div className="cta-extra">Repay early to avoid <Link to={`/myloans/details/?type=active&loanID=${nextRepayment?.loanID}`} className="link link-dark"> Late Fees!</Link></div>*/}
                                                        <Link to={`/loan/active/details/${nextRepayment?.loanID}`} className="btn btn-primary btn-lg btn-block">{t('Repay Interest Now')}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-12 col-lg-4">
                                        <div className="nk-wg-card card card-bordered h-100">
                                            <div className="card-inner h-100">
                                                <div className="nk-iv-wg2">
                                                    <div className="nk-iv-wg2-title">
                                                        <h6 className="title">{t('My Active Loans')}</h6>
                                                    </div>
                                                    <div className="nk-iv-wg2-text">
                                                        <div className="nk-iv-wg2-amount ui-v2">{activeLoans ? activeLoans.length + t(` Active Loans`) : 'No Active Loans' }</div>
                                                        <ul className="nk-iv-wg2-list">
                                                            {
                                                                activeLoans && activeLoans.map((item) => {
                                                                    return <CurrentActiveLoan loan={item} key={item.loanID}/>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="nk-iv-wg2-cta">
                                                        <Link to="/myloans" className="btn btn-primary btn-lg btn-block">{t('See Loan Status')}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                            {/*
                            <div className="nk-block">
                                <div className="card card-bordered">
                                    <div className="nk-refwg">
                                        <div className="nk-refwg-invite card-inner">
                                            <div className="nk-refwg-head g-3">
                                                <div className="nk-refwg-title">
                                                    <h5 className="title">Refer Us & Earn</h5>
                                                    <div className="title-sub">Use the bellow link to invite your friends.</div>
                                                </div>
                                                <div className="nk-refwg-action">
                                                    <a href="#" className="btn btn-primary">Invite</a>
                                                </div>
                                            </div>
                                            <div className="nk-refwg-url">
                                                <div className="form-control-wrap">
                                                    <div className="form-clip clipboard-init" data-clipboard-target="#refUrl" data-success="Copied" data-text="Copy Link"><em className="clipboard-icon icon ni ni-copy"></em> <span className="clipboard-text">Copy Link</span></div>
                                                    <div className="form-icon">
                                                        <em className="icon ni ni-link-alt"></em>
                                                    </div>
                                                    <input type="text" className="form-control copy-text" id="refUrl" readOnly value={referalURL}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-refwg-stats card-inner bg-lighter">
                                            <div className="nk-refwg-group g-3">
                                                <div className="nk-refwg-name">
                                                    <h6 className="title">My Referral <em className="icon ni ni-info" data-toggle="tooltip" data-placement="right" title="Referral Informations"></em></h6>
                                                </div>
                                                <div className="nk-refwg-info g-3">
                                                    <div className="nk-refwg-sub">
                                                        <div className="title">394</div>
                                                        <div className="sub-text">Total Joined</div>
                                                    </div>
                                                    <div className="nk-refwg-sub">
                                                        <div className="title">548.49</div>
                                                        <div className="sub-text">Referral Earn</div>
                                                    </div>
                                                </div>
                                                <div className="nk-refwg-more dropdown mt-n1 mr-n1">
                                                    <a href="#" className="btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                    <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                                        <ul className="link-list-plain sm">
                                                            <li><a href="#">7 days</a></li>
                                                            <li><a href="#">15 Days</a></li>
                                                            <li><a href="#">30 Days</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="nk-refwg-ck">
                                                <canvas className="chart-refer-stats" id="refBarChart"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const CurrentActiveLoan = ({
    loan
}:{
    loan:LoanTicketActive
}) =>{
    return(
        <li>
        <span className="item-label"><a href="#">{loan.borrowAsset}</a> <small>- @{(loan.interestRate * 100).toFixed(2)}% for {loan.loanTerm} Months</small></span>
        <span className="item-value">{useFormatPrice(loan.borrowAsset, loan.borrowAmount,true)}</span>
        </li>
    )
}
export default DashboardPage;