import React, { useEffect, useRef, useState } from 'react';
import Details from './Details'
// import DialogWallet from './DialogWallet'
// import DialogConfirm from './DialogConfirm'
import BorrowPageWrapper from '../../util/jacob/components/Borrow/BorrowPage'
import DialogConfirm from './DialogConfirm';
import usdt from '../product-icons/usdt.svg'
import { BorrowAsset, CollateralAsset, GetLTVs, LTV } from '../../ApiService/loan';
import { useTranslation } from 'react-i18next';
import { useFormatPrice } from '../../util/jacob/components/format';


let BorrowUSDTPageWrapper = BorrowPageWrapper('USDT', 'crypto');
let View = (props: BorrowPageWrapper.Api) => {
  const { t } = useTranslation(['Borrow','Common'])
  const [ltvs, setLTVs] = useState<LTV[]>([])
  const [availableLoanTerm, setAvailableLoanTerm] = useState<number[]>([])
  const [currentLoanTerm, setCurrentLoanTerm] = useState<number>(6)
  const [customborrowAmount, setCustomBorrowAmount] = React.useState<number>(0)
  const agreeRef = useRef(null)
  // const handleCustomChange = async(e: React.FormEvent<HTMLInputElement>) => {
  //   let result = converToNumber(e.currentTarget.value)
  //   console.log("result", result)
  //   await setCustomBorrowAmount(result)
  // }
  const clearBorrowAmountRadio = () =>{
    var ele = document.getElementsByName("iv-amount")
    for(var i=0;i<ele.length;i++){
      let selectE = ele[i] as HTMLInputElement
      selectE.checked = false;
    }
  }
  const converToNumber = (value:string):number =>{
    let calValue = parseFloat(value)
    console.log("calValue", calValue)
    if(typeof calValue === 'number' && isFinite(calValue)){
      // if(calValue < 700)calValue = 700
      if(calValue > 250000) calValue = 250000
      return calValue
    }else{
      return 0
    }
  }

  const getData = async () =>{
    const ltvs = await GetLTVs(BorrowAsset.USDT)
    setLTVs(ltvs)

    let tempAvailableLoanTerm:number[] = []
    ltvs.map(ltv=>{
      if(!tempAvailableLoanTerm.includes(ltv.period)){
        tempAvailableLoanTerm.push(ltv.period)
      }
    })
    
    setAvailableLoanTerm(tempAvailableLoanTerm)
    if(tempAvailableLoanTerm && tempAvailableLoanTerm.length > 0  && ltvs.length > 0){
      setCurrentLoanTerm(tempAvailableLoanTerm[0])
      props.setReqQuote({
        loanTerm:ltvs[0].period,
        LTV:ltvs[0].ltv,
        borrowAsset: ltvs[0].asset,
        collateralAsset:CollateralAsset.BTC,
        repaymentAsset:BorrowAsset.USDT,
      })
    }

  }
  useEffect(()=>{
    getData()
  },[])

  useEffect(() => {
    let oldValue = props.getReqQuote()
    props.setReqQuote({
      ...oldValue,
      borrowAmount: customborrowAmount
    })
    return
  }, [customborrowAmount]);
  return (
    <React.Fragment>

        <div className="nk-content nk-content-lg nk-content-fluid">
          <div className="container-xl wide-lg">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-lg">
                  <div className="nk-block-head-content">
                    <div className="nk-block-head-sub"><a href="/borrow" className="back-to"><em className="icon ni ni-arrow-left"></em><span>{t('Common:page_go_back',{defaultValue:"Back to plan"})}</span></a></div>
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title fw-normal">{t('Apply for a USDT Loan')}</h2>
                    </div>
                  </div>
                </div>
                <div className="nk-block invest-block">
                  <form action="#" className="invest-form">
                    <div className="row g-gs">
                      <div className="col-lg-7">
                        <div className="invest-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">{t('You will get')}</label>
                          </div>
                          <input type="hidden" value="silver" name="iv-plan" id="invest-choose-plan" />
                          <div className="dropdown invest-cc-dropdown">
                            <div className="invest-cc-choosen" data-toggle="dropdown">
                              <div className="coin-item">
                                <div className="coin-icon">
                                  <img src={usdt} className="icon" />
                                </div>
                                <div className="coin-info">
                                  <span className="coin-name">USDT</span>
                                  <span className="coin-text">{t('Your loan will arrive in your GP wallet')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invest-field form-group">
                          <label className="form-label">{t('Choose Loan Duration')}</label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control"
                              onChange={(event) => {
                                let tempLoanTerm = parseInt(event.currentTarget.value)
                                
                                setCurrentLoanTerm(tempLoanTerm)
                                let currentLTV = ltvs.find(e=>e.period === tempLoanTerm)
                                props.setReqQuote({
                                  loanTerm:  tempLoanTerm,
                                  LTV: currentLTV?.ltv
                                })
                              }}
                            >
                              {
                                availableLoanTerm.map((term, termIndex)=>{
                                  return(
                                    <option 
                                      value = {term}
                                      key = {termIndex}
                                      children={`${term} ${t('Months')}`}
                                    />
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="invest-field form-group">
                          <div className="form-group">
                            <label className="form-label" htmlFor="default-06">{t('Choose your interest rate / LTV Ratio')}</label>
                            <div className="form-control-wrap ">
                              <div className="form-control-select">
                                <select
                                  className="form-control"
                                  id="default-06 ltv-ratio"
                                  onChange = {e=>{
                                    let ltv = ltvs[e.currentTarget.value as unknown as number]
                                    props.setReqQuote({
                                      LTV:ltv.ltv,
                                      loanTerm:ltv.period,
                                      borrowAsset: ltv.asset
                                    })
                                  }}
                                >{
                                  ltvs.map((ltv, ltvIndex)=>{
                                    if(ltv.period !== currentLoanTerm)return
                                    return (
                                      <option
                                        value={ltvIndex}
                                        children={`${ltv.period} months - ${((ltv.rate * 1e2))}% APR @ ${(ltv.ltv * 1e2)}% LTV`}
                                        key={ltvIndex}
                                      />  
                                    )
                                  })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invest-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">{t('Choose Quick Amount to Borrow')}</label>
                          </div>
                          <div className="invest-amount-group g-2">
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-0" onClick={() => props.setReqQuote({ borrowAmount: 1 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-0">{useFormatPrice(BorrowAsset.USDT, 1, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2" onClick={() => props.setReqQuote({ borrowAmount: 700 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-2">{useFormatPrice(BorrowAsset.USDT, 700, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3" onClick={() => props.setReqQuote({ borrowAmount: 5000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-3">{useFormatPrice(BorrowAsset.USDT, 5000, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4" onClick={() => props.setReqQuote({ borrowAmount: 10000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-4">{useFormatPrice(BorrowAsset.USDT, 10000, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-5" onClick={() => props.setReqQuote({ borrowAmount: 50000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-5">{useFormatPrice(BorrowAsset.USDT, 50000, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-6" onClick={() => props.setReqQuote({ borrowAmount: 100000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-6">{useFormatPrice(BorrowAsset.USDT, 100000, true, false)}</label>
                            </div>
                            <div className="invest-amount-item">
                              <input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-7" onClick={() => props.setReqQuote({ borrowAmount: 250000 })} />
                              <label className="invest-amount-label" htmlFor="iv-amount-6">{useFormatPrice(BorrowAsset.USDT, 250000, true, false)}</label>
                            </div>
                          </div>
                        </div>
                        <div className="invest-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">{t('Or Enter Your Amount')}</label>
                          </div>
                          <div className="form-control-group">
                            <div className="form-info">USDT</div>
                            {/* onClick = {clearBorrowAmountRadio} */}
                            <input type="text" min={0} step={1} className="form-control form-control-amount form-control-lg" id="custom-amount" value={customborrowAmount}  onClick = {clearBorrowAmountRadio} onChange = {e => setCustomBorrowAmount(converToNumber(e.target.value))}/>
                            
                            {/* <input type="text" className="form-control form-control-amount form-control-lg" id="custom-amount" value={100} /> */}
                            <div className="form-range-slider" id="amount-step"></div>
                          </div>
                          <div className="form-note pt-2">{t('Minimum borrow 700 USDT and upto 250,000 USDT')}</div>
                        </div>
                        <div className="invest-field form-group">
                          <label className="form-label">{t('Choose Collateral Asset')}</label>
                          <div className="form-control-wrap">
                            <select 
                              className="form-control"
                              onChange={event => (
                                props.setReqQuote({ collateralAsset: event.target.value })
                              )} 
                              defaultValue={CollateralAsset.BTC}
                            >
                              <option value="BTC">{t('Bitcoin - BTC')}</option>
                              <option value="ETH">{t('Ethereum - ETH')}</option>
                              {/* <option value="USDT">Tether - USDT</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                      <Details
                        {...{...props.getReqQuote(),...props.getResQuote()}}
                        quote={props.quote}
                        createTicket={props.createTicket}
                        requireBankInfo={false}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      <DialogConfirm
        {...{...props}}
      />
    </React.Fragment>
  );
};
const BorrowUSDTPage = (props: {}) => (
  <BorrowUSDTPageWrapper
    view={View}
  />
)
export default BorrowUSDTPage