import axios from 'axios';
//interceptors to put firebase token in Axios request
axios.interceptors.request.use(async(req) => {
    
    req.withCredentials = true //use only when cookie mode 

    // const token = await localStorage.getItem('firebaseToken');
    req.headers = {
      'Content-Type': 'application/json',
      // "Authorization": token ? `Bearer ${token}` : ''
    }
    console.log(`${req.method} ${req.url}`);
    // Important: request interceptors **must** return the request.
    return req;
  });

export default axios;