import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { User, UserKYC, UserVerificationStatus } from '../../contexts/appContext';
import { GetUserData, GetUserKYC } from './service';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
    const { t } = useTranslation('Profile') 
    const [userData, setUserData] = useState<User|undefined>(undefined)
    const [userKYCData, setUserKYCData] = useState<UserKYC|undefined>(undefined)

    function translateVerificationStatus(status:UserVerificationStatus){
        switch(status){
            case UserVerificationStatus.None:
                return t('KYC:KYC_none',{defaultValue:"Not verify"})
            case UserVerificationStatus.Pending:
                return t('KYC:KYC_pending',{defaultValue:"Verification Pending"})
            case UserVerificationStatus.UnderReview:
                return t('KYC:KYC_underReview',{defaultValue:"UnderReview"})
            case UserVerificationStatus.Verified:
                return t('KYC:KYC_verified',{defaultValue:"Verified"})
            default:
                return t('KYC:KYC_unknown',{defaultValue:"Unknown"})
        }
    }
    
    const getData = async() =>{
        const resUserData = await GetUserData()
        const resUserKYCData = await GetUserKYC()
        console.log("resUserData", resUserData)
        console.log("resUserKYCData", resUserKYCData)
        setUserData(resUserData)
        setUserKYCData(resUserKYCData)
    }
    useEffect(()=>{
        getData()
    },[])

        const displayVerificationLevel = (level:number) => {
            if(level === 1) {
                return t('KYC:unverified',{defaultValue:"Unverified"})
            } else {
                return t('KYC:verified',{defaultValue:"Verified"})
            }
        }

        return (
            <React.Fragment>

                <div className="nk-content nk-content-lg nk-content-fluid">
                <div className="container-xl wide-lg">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    {/* <div className="nk-block-head-sub"><span>{t('Profile:my_profile',{defaultValue:"My Profile"})}</span></div> */}
                                    <h2 className="nk-block-title fw-normal">{t('Profile:my_profile',{defaultValue:"My Profile"})}</h2>
                                    {/* <h2 className="nk-block-title fw-normal">{t('Profile:account_information', {defaultValue:"Account Info"})}</h2> */}
                                    {/* <div className="nk-block-des">
                                        <p>You have full control to manage your own account setting. <span className="text-primary"><em className="icon ni ni-info"></em></span></p>
                                    </div> */}
                                </div>
                            </div>
                            <ul className="nk-nav nav nav-tabs">
                            </ul>
                            <div className="nk-block">
                            <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                        <h5 className="nk-block-title">{t('Profile:account_information', {defaultValue:"Account Info"})}</h5>
                                        {/* <div className="nk-block-des">
                                            <p>Account information like your user ID, email and verification status on GP Loan Platform.</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card card-bordered">
                                    <div className="nk-data data-list">
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:userID', {defaultValue:"User ID"})}</span>
                                                <span className="data-value">{userData ? userData.userID : "Loading"}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:email', {defaultValue:"Email"})}</span>
                                                <span className="data-value">{userData ? userData.email : "Loading"}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:verification_level', {defaultValue:"Verification Level"})}</span>
                                                <span className="data-value">{userData ? displayVerificationLevel(userData?.verificationLevel) : "Loading"}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:verification_status', {defaultValue:"Verification Status"})}</span>
                                                <span className="data-value">{userData ? translateVerificationStatus(userData.verificationStatus) : "Loading"}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                        <h5 className="nk-block-title">{t('Profile:personal_information', {defaultValue:"Personal Information"})}</h5>
                                        {/* <div className="nk-block-des">
                                            <p>Basic info, like your name and address, that you use on GP Loan Platform.</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card card-bordered">
                                    <div className="nk-data data-list">
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:english_full_name', {defaultValue:"Full Name (English)"})}</span>
                                                <span className="data-value">{userKYCData? userKYCData.firstName ? (userKYCData.firstName + ' ' + userKYCData.lastName) : "N/A" : 'Loading'}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:chinese_full_name', {defaultValue:"Full Name (Chinese)"})}</span>
                                                <span className="data-value">{userKYCData? userKYCData.fullChineseName ? (userKYCData.fullChineseName) : "N/A" : 'Loading'}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:phone', {defaultValue:"Phone Number"})}</span>
                                                <span className="data-value text-soft">{userKYCData? userKYCData.mobileNumber ? (userKYCData.mobileNumber) : "N/A" : 'Loading'}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:dob', {defaultValue:"Date of Birth"})}</span>
                                                <span className="data-value">{userKYCData ? userKYCData.dob? moment(userKYCData.dob).format("YYYY-MM-DD") : 'N/A' : "Loading"}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:nationality', {defaultValue:"Nationality"})}</span>
                                                <span className="data-value">{userKYCData? userKYCData.idCountry ? (userKYCData.idCountry) : "N/A" : 'Loading'}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                        <div className="data-item" data-toggle="modal" data-target="#profile-edit" data-tab-target="#address">
                                            <div className="data-col">
                                                <span className="data-label">{t('Profile:address', {defaultValue:"Address"})}</span>
                                                <span className="data-value">{userKYCData? userKYCData.residentialAddress ? (userKYCData.residentialAddress) : "N/A" : 'Loading'}</span>
                                            </div>
                                            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }


export default ProfilePage;