export const WithdrawPageCrypto = {
    "Back to Dashboard": "返回去賬戶版面",
    "Withdraw Crypto Funds": "提現虛擬貨幣",
    "Select which asset to withdraw":"選擇要提現貨幣",
    "btc_available": "BTC | 可用餘額:",
    "eth_available": "ETH | 可用餘額:",
    "usdt_available": "USDT | 可用餘額:",
    "Amount": "數量",
    "Note:": "注:",
    "Destination Wallet Address": "目的地錢包地址",
    "You can withdraw up to": "您最多可以提現",
    "Your Withdraw Details": "提現細節:",
    "Asset to Withdraw": "提現貨幣",
    "Amount to Withdraw": "數量",
    "Send to address": "錢包地址",
    "Minimum Withdraw Fee": "提現費用",
    "Network fee": "網絡費用",
    "You can get": "你會得到",
    "Quote Withdraw Fee": "提現費報價",
    "Confirm proceed": "確認",
    "Withdraw Amount must be less or equal to your available balance": "提現金額必須小於或等於您的可用餘額",
    "Withdraw Address is invalid, please check your destination wallet address and try again": "提現地址無效，請檢查您的錢包地址，然後重試"
} 