import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Translation_zh_CN } from "./locales/zh_CN";
import { Translation_zh_TW } from "./locales/zh_TW";
import { Translation_en_US } from "./locales/en_US";
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    zh_CN: Translation_zh_CN,
    zh_TW: Translation_zh_TW,
    en_US: Translation_en_US,

  };




i18n
//   .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

  export default i18n;