import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import BorrowPageWrapper from "../../util/jacob/components/Borrow/BorrowPage";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { GetDocusignUrl } from "../../ApiService/docusign";

const override = css`
  display: block;
  margin-right: 10px;
`;

let DialogConfirm = (props: any) => {
  const { t } = useTranslation(["Borrow", "Common"]);
  let history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationSuccess, setApplicationSuccess] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const closeButtonRef = useRef<HTMLAnchorElement>(null);
  const timerRef = useRef<any>(null);
  const handleConfirmLoan = async () => {
    setErrMsg("");
    setLoading(true);
    // console.log(props);
    try {
      let ticket =
        (await props.createTicket()) as BorrowPageWrapper.ResponseCreateTicket;

      // setTimeout(() => {
      //   if (!ticket || !ticket.loanID) {
      //     handleConfirmLoan();
      //     return;
      //   }
        if (ticket && ticket.loanID) {
          setApplicationSuccess(true);
          // console.log(localStorage.getItem('GoSigned'))
          // setLoading(false)
          // if(closeButtonRef.current !== null){
          //   closeButtonRef.current.click()
          // }
          // history.push(`/myloans/details/?type=application&loanID=${ticket.loanID}`)
          // history.push(ticket.redirectURL)
          // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/loan/application/details/${ticket.loanID}`;
          window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/loan/application`;

          localStorage.setItem("log", `${ticket.loanID}`);
          localStorage.setItem("log3", `${ticket.redirectURL}`);
          // window.location.replace(ticket.redirectURL);
        } else {
          setLoading(false);
          setErrMsg(t("Application error, please contact support"));
        }
      // }, 10000);

      // setLoading(false)
    } catch (error) {
      await new Promise<string>((resolve, reject) => {
        // (function getUrl(i = 9) {
        (function getUrl(i = 30) {
          GetDocusignUrl()
            .then(resolve)
            .catch((err) => {
              if (0 < i) {
                new Promise((r) => setTimeout(r, 30e3))
                  .then(() => i - 1)
                  .then(getUrl, () => {});
              } else {
                reject(err);
              }
            });
        })();
      })
        .then(
          (url) => (setApplicationSuccess(true), window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/loan/application`,localStorage.setItem("log2",`${url}`)
          ),
        )
        .catch(
          () => (
            setLoading(false),
            setErrMsg(t("Excepted error occured, please contact support"))
          )
        );
    }
  };
  // const timerRef = useRef<any>();
  useEffect(() => {
    // console.log(props.timer)
    // timerRef.current.start();

    return () => {};
  }, []);
  return (
    // tabIndex={-1}
    <div className="modal fade" id="confirm-invest">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <a
            href="#"
            className="close"
            data-dismiss="modal"
            ref={closeButtonRef}
          >
            <em className="icon ni ni-cross-sm"></em>
          </a>
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              {applicationSuccess && (
                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
              )}

              <h1>
                {/* {props.timer} */}
                {/* <Countdown ref={timerRef} date={time}  autoStart={true}/> */}
                {/* {
                  open?
                  :null
                } */}
              </h1>
              <h4 className="nk-modal-title">
                {t("Please confirm your loan application.")}
              </h4>
              <div className="nk-modal-text">
                <p className="sub-text">
                  {t(
                    "1. You will be redirected to DocuSign to sign loan agreement after clicking the confirm button."
                  )}
                </p>
                <p className="sub-text">
                  {t(
                    "2. After Signing the Loan Agreement via DocuSign, please proceed to deposit collateral to your wallet address."
                  )}
                </p>
                <p className="sub-text">
                  {t(
                    "3. Once we have received your collateral deposit, your loan will be released to your GP Wallet or Bank Account."
                  )}
                </p>
                <p className="sub-text">
                  {t(
                    "4. Please confirm your loan application within 24 hours."
                  )}
                </p>
              </div>
              {errMsg && (
                <div className="nk-modal-text">
                  <p className="sub-text" style={{ color: "red" }}>
                    {errMsg}
                  </p>
                </div>
              )}

              <div className="nk-modal-action-lg">
                <ul className="btn-group flex-wrap justify-center g-4">
                  {/* <li data-dismiss="modal"><Link to="/myloans" className="btn btn-lg btn-mw btn-primary">Confirm</Link></li> */}
                  {/* <li className="btn btn-lg btn-mw btn-primary" onClick={handleConfirmLoan}>Confirm</li> */}
                  <li>
                    <button
                      className="btn btn-lg btn-mw btn-primary"
                      disabled={loading}
                      onClick={handleConfirmLoan}
                    >
                      <ClipLoader
                        css={override}
                        size={21}
                        color={"#366CD7"}
                        loading={loading}
                      />
                      {!loading
                        ? t("Confirm")
                        : applicationSuccess
                        ? t("Redirecting to DocuSign")
                        : t("Applying loan")}
                    </button>
                  </li>
                  <li data-dismiss="modal">
                    <Link to="#" className="btn btn-lg btn-mw btn-primary">
                      {t("Cancel")}
                    </Link>
                  </li>
                  {/*<li data-dismiss="modal"><Link to="/transactions/crypto" className="btn btn-lg btn-mw btn-dim btn-primary"><em className="icon ni ni-reports"></em><span>View Transactions</span></Link></li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogConfirm;