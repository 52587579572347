import React, { useContext, useState } from 'react';
import {
    Link
  } from "react-router-dom";
import { AppContext, Language, UserVerificationStatus } from '../contexts/appContext';
import { useTranslation } from 'react-i18next';
import "./TopNav.css"
  
const TopNav = () => {
    const { currentUser, signOut, currentUserSetting, setLanguage} = useContext(AppContext)
    const [ sidebar, setSidebar ] = useState(false);
    const [ dropDown, setDropDown ] = useState(false);
    const { t } = useTranslation(['TopNav','KYC'])

    
    const toggleNav = () => {
        // console.log('aaa');
        setSidebar(!sidebar);
    }

    const toggleDrop = () => {
        // console.log('aaa');
        setDropDown(!dropDown);
    }

    const mobileNavClick = (toggleStatus: boolean) => {
        if (toggleStatus === true) {
            toggleNav();
        }
    }

    function TranslateVerificationStatus(status:UserVerificationStatus){
        switch(status){
            case UserVerificationStatus.None:
                return t('KYC:KYC_none',{defaultValue:"Not verify"})
            case UserVerificationStatus.Pending:
                return t('KYC:KYC_pending',{defaultValue:"Verification Pending"})
            case UserVerificationStatus.UnderReview:
                return t('KYC:KYC_underReview',{defaultValue:"UnderReview"})
            case UserVerificationStatus.Verified:
                return t('KYC:KYC_verified',{defaultValue:"Verified"})
            default:
                return t('KYC:KYC_unknown',{defaultValue:"Unknown"})
        }
    }

     
    return (
        <div className="nk-header nk-header-fluid nk-header-fixed is-theme">
            <div className="container-xl wide-lg">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger mr-sm-2 d-lg-none">
                        <a className="nk-nav-toggle nk-quick-nav-icon" onClick={toggleNav} data-target="headerNav"><em className="icon ni ni-menu"></em></a>
                    </div>
                    <div className="nk-header-brand">
                        <a href="html/index.html" className="logo-link">
                            <img className="logo-light logo-img" src="/images/logo-dark-nav.png" srcSet="/images/logo-dark-nav.png 2x" alt="logo"/>
                            <img className="logo-dark logo-img" src="/images/logo-dark-nav.png" srcSet="/images/logo-dark-nav.png 2x" alt="logo-dark" />
                        </a>
                    </div> 
                    <div className={`nk-header-menu ${sidebar? "nk-header-active mobile-menu" : ""}`} data-content="headerNav">
                        <div className="nk-header-mobile" style={{ "backgroundColor": "#2c3782"}}>
                            <div className="nk-header-brand">
                                <Link to="/dashboard">
                                    <img className="logo-light logo-img" src="./images/logo.png" srcSet="./images/logo2x.png 2x" alt="logo"/>
                                    <img className="logo-dark logo-img" src="./images/logo-dark.png" srcSet="./images/logo-dark2x.png 2x" onClick={toggleNav} style={{ "marginLeft": "7%"}} alt="logo-dark"/>
                                </Link>
                            </div>
                            <div className="nk-menu-trigger mr-n2">
                                <a className="nk-nav-toggle nk-quick-nav-icon nk-header-active" style={{ "color": "#fafafa"}}  onClick={toggleNav}><em className="icon ni ni-arrow-left"></em></a>
                            </div>
                        </div>
                        <ul className="nk-menu nk-menu-main">
                            <li className="nk-menu-item">
                                <Link to="/dashboard" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-layout2"></em><span className="nk-menu-text">{t('TopNav:Dashboard')}</span>
                                </Link>
                            </li>
                            <li className="nk-menu-item">
                                <Link to="/borrow" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-edit"></em><span className="nk-menu-text">{t('TopNav:Apply Loan')}</span>
                                </Link>
                            </li>
                            <li className={dropDown? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}>
                                <a className="nk-menu-link nk-menu-toggle" onClick={() => toggleDrop()}>
                                <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-list-round"></em><span className="nk-menu-text">{t('TopNav:Loans')}</span>
                                </a>
                                <ul className="nk-menu-sub" style={dropDown? {"display": "block"} : {"display": "block"}}>
                                <li className="nk-menu-item">
                                        <Link to="/myloans" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                            <em style={{"marginRight": "5px", "fontSize": "25px"}} className="icon ni ni-invest"></em><span className="nk-menu-text">{t('TopNav:Active Loans')}</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/loan/application" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                            <em style={{"marginRight": "5px", "fontSize": "25px"}} className="icon ni ni-clipboard"></em><span className="nk-menu-text">{t('TopNav:Pending Applications')}</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/loan/application/history" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                            <em style={{"marginRight": "5px", "fontSize": "25px"}} className="icon ni ni-todo-fill"></em><span className="nk-menu-text">{t('TopNav:Applications History')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={dropDown? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}>
                                <a className="nk-menu-link nk-menu-toggle" onClick={() => toggleDrop()}>
                                <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-wallet-out"></em><span className="nk-menu-text">{t('TopNav:Withdraw')}</span>
                                </a>
                                <ul className="nk-menu-sub" style={dropDown? {"display": "block"} : {"display": "block"}}>
                                    <li className="nk-menu-item">
                                        <Link to="/withdraw/crypto" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                            <em style={{"marginRight": "5px", "fontSize": "25px"}} className="icon ni ni-coins"></em><span className="nk-menu-text">{t('TopNav:Withdraw Crypto')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {/*
                            <li className="nk-menu-item">
                                <Link to="/loan/application" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-clipboard"></em><span className="nk-menu-text">Applications</span>
                                </Link>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <Link to="/myloans" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <em style={{"marginRight": "5px", "fontSize": "25px"}} className="ni ni-invest"></em><span className="nk-menu-text">Manage Loans</span>
                                </Link>
                            </li>
                            
                            <li className="nk-menu-item">
                                <Link to="/kyc" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <span className="nk-menu-text">Verify Identity</span>
                                </Link>
                            </li>
                            <li className="nk-menu-item">
                                <Link to="/transactions/crypto" className="nk-menu-link" onClick={() => mobileNavClick(sidebar)}>
                                    <span className="nk-menu-text">View Transactions</span>
                                </Link>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-text">Other Pages</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to="/profile" className="nk-menu-link">
                                            <span className="nk-menu-text">My Profile<em className="icon ni ni-external"></em></span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/kyc" className="nk-menu-link">
                                            <span className="nk-menu-text">Verify Identity (KYC)</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/transactions/crypto" className="nk-menu-link">
                                            <span className="nk-menu-text">View Transactions<em className="icon ni ni-external"></em></span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>*/}
                        </ul>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">{/*
                            <li className="dropdown notification-dropdown">
                                <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                    <div className="icon-status icon-status-info"><em className="icon ni ni-bell"></em></div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                    <div className="dropdown-head">
                                        <span className="sub-title nk-dropdown-title">Notifications</span>
                                        <a href="#">Mark All as Read</a>
                                    </div>
                                    <div className="dropdown-body">
                                        <div className="nk-notification">
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-foot center">
                                        <a href="#">View All</a>
                                    </div>
                                </div>
                        </li>*/}
                            <li className="hide-mb-sm" onClick={signOut}><a href="#" className="nk-quick-nav-icon"><em className="icon ni ni-signout"></em></a></li>
                            <li className="dropdown user-dropdown order-sm-first">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <div className="user-toggle">
                                        <div className="user-avatar sm">
                                            <em className="icon ni ni-user-alt"></em>
                                        </div>
                                        <div className="user-info d-none d-xl-block">
                                            <div  className="user-status user-status-verified">{currentUser?TranslateVerificationStatus(currentUser.verificationStatus) : ""}</div>
                                            <div className="user-name dropdown-indicator">{t('User ID')}: {currentUser?.userID}</div>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                        <div className="user-card">
                                            <div className="user-avatar">
                                                <span>US</span>
                                            </div>
                                            <div className="user-info">
                                                <span className="lead-text">{t('TopNav:User ID')}: {currentUser?.userID}</span>
                                                <span className="sub-text">{currentUser?.email}</span>
                                            </div>
                                            {/* <div className="user-action">
                                                <a className="btn btn-icon mr-n2" href="html/invest/profile-setting.html"><em className="icon ni ni-setting"></em></a>
                                            </div> */}
                                        </div>
                                    </div>{/*
                                    <div className="dropdown-inner user-account-info">
                                        <h6 className="overline-title-alt">Avaliable Account Balance</h6>
                                        <div className="user-balance">1,494.23 <small className="currency currency-usd">USD</small></div>
                                        <div className="user-balance-sub">Locked <span>15,495.39 <span className="currency currency-usd">USD</span></span></div>
                                        <a href="#" className="link"><span>Withdraw Balance</span> <em className="icon ni ni-wallet-out"></em></a>
                                    </div>*/}
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><Link to="/profile"><em className="icon ni ni-setting-alt"></em><span>{t('My Profile')}</span></Link></li>
                                            <li><Link to="/kyc"><em className="icon ni ni-user-alt"></em><span>{t('KYC Verification')}</span></Link></li>
                                            <li><Link to="/transactions/crypto"><em className="icon ni ni-activity-alt"></em><span>{t('View Transactions')}</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li className={"same_row_container"}>
                                                {/* <a href="#" ><span>Language: </span></a> */}
                                                <a href="#" onClick={() => setLanguage(Language.en_US)}><span style={{marginRight:"5px",marginLeft:"5px"}}> English </span></a>
                                                /<a href="#" onClick={() => setLanguage(Language.zh_CN)}><span style={{marginRight:"5px",marginLeft:"5px"}}> 简中 </span></a> 
                                                /<a href="#" onClick={() => setLanguage(Language.zh_TW)}><span style={{marginRight:"5px",marginLeft:"5px"}}> 繁中 </span></a> 
                                                {/* /<a href="#" onClick={() => setLanguage(Language.zh_TW)}><span>繁</span></a>  */}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li onClick={signOut}><a href="#"><em className="icon ni ni-signout"></em><span>{t('Sign out')}</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> 
        </div> 
    );

}

export default TopNav;