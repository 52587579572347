import { BorrowAsset, CollateralAsset, Transaction } from "../../../ApiService/loan";
import { useTranslation } from 'react-i18next';
import { BalanceAsset } from "../../../ApiService/user";

function numberWithCommas(x:number|string) {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // console.log("x",x,"parts",parts,"result",parts.join("."))
  return parts.join(".");
}

export const useFormatPrice = (type:BorrowAsset | BalanceAsset | CollateralAsset | undefined, price:number, withSymbol:boolean, withFixed?:boolean):string => {
  if(withFixed === undefined)withFixed = true
  const { t } = useTranslation('Common')
  price = Number(price || 0)
  switch(type) {
    case 'USD':
      return `${withSymbol ? `${t('Common:USD',{defaultValue:"USD"})} `: ''}${numberWithCommas(withFixed ? price.toFixed(2) : price)}`
    case 'USDT':
      return `${numberWithCommas( withFixed ? price.toFixed(5) : price)}${withSymbol ? " USDT": ''}`
    case 'HKD':
      return `${withSymbol ? `${t('Common:HKD',{defaultValue:"HKD"})} `: ''}${numberWithCommas(withFixed ? price.toFixed(2) : price)}`
    case 'BTC':
      return `${numberWithCommas(withFixed ? price.toFixed(8) : price)}${withSymbol ? " BTC": ''}`
    case 'ETH':
      return `${numberWithCommas(withFixed ? price.toFixed(8) : price)}${withSymbol ? " ETH": ''}`
    default:
      return price.toString();
  }
}

// export let formatPrice = <
//   TType extends 'USD' | 'USDT' | 'HKD' | 'BTC' | 'ETH'
// >(type: TType, price: number, withSymbol:boolean): string => {
//   price = Number(price)
//   switch(type) {
//     case 'USD':
//       return `${withSymbol && "USD "}${numberWithCommas(price.toFixed(2))}`
//     case 'USDT':
//       return `${withSymbol && "USDT "}${numberWithCommas(price.toFixed(5))}`
//     case 'HKD':
      
//       let result = numberWithCommas(price.toFixed(2))
//       console.log("price",price,"price.toFixed(2)",price.toFixed(2),"result",result)
//       return `${withSymbol && "HKD "}${numberWithCommas(price.toFixed(2))}`
//     case 'BTC':
//       return `${withSymbol && "BTC "}${numberWithCommas(price.toFixed(8))}`
//     case 'ETH':
//       return `${withSymbol && "ETH "}${numberWithCommas(price.toFixed(8))}`
//     default:
//       return price.toString();
//   }
// }


export const FormatTransactionType = (tx: Transaction,t:any) => {

  return ({
    'amount': {
      'deposit': t('Tx:deposit',{defaultValue:"Deposit"}),
      'withdraw': t('Tx:withdraw',{defaultValue:"Withdraw"}),
      'interest': t('Tx:interest',{defaultValue:'Interest'}),
      'interest_repayment': t('Tx:interest_repayment',{defaultValue:'Interest Repayment'}),
      'loan_payout': t('Tx:loan_payout',{defaultValue:'Loan Payout'}),
      'loan_repayment': t('Tx:loan_repayment',{defaultValue:'Loan Repayment'}),
      'loan_termination': t('Tx:loan_termination',{defaultValue:'Loan Termination'}),
      'liquidation': t('Tx:liquidation',{defaultValue:'Liquidation'}),
      'fee': t('Tx:fee',{defaultValue:'Fee'}),
      'fee_reverse': t('Tx:fee_reverse',{defaultValue:'Fee Reverse'}),
      'manual_entry': t('Tx:manual_entry',{defaultValue:'Manual Entry'}),
    },
    'hold': {
      'collateral_lock': t('Tx:collateral_lock',{defaultValue:'Collateral Hold'}),
      'collateral_unlock': t('Tx:collateral_unlock',{defaultValue:'Collateral Unhold'}),
      'liquidation': t('Tx:liquidation',{defaultValue:'Liquidation'}),
      'manual_entry': t('Tx:manual_entry',{defaultValue:'Manual Entry'}),
    },
    'pending_deposit': {
      'deposit': t('Tx:pending_deposit',{defaultValue:'Pending Deposit'}),
      'deposit_reverse': t('Tx:pending_deposit_reverse',{defaultValue:'Pending Deposit Reverse'}),
    },
    'pending_withdraw': {
      'withdraw': t('Tx:pending_withdraw',{defaultValue:'Withdraw'}),
      'withdraw_reverse': t('Tx:pending_withdraw_reverse',{defaultValue:'Pending Withdraw Reverse'}),
    },
  })[tx.transactionTarget][tx.transactionType]
};
export let formatTransactionAmount = (tx: Transaction) => {
  return ({
    'amount': {
      'deposit': tx.amount,
      'withdraw': tx.amount,
      'interest': tx.amount,
      'interest_repayment': tx.amount,
      'loan_payout': tx.amount,
      'loan_repayment': tx.amount,
      'loan_termination': tx.amount,
      'liquidation': tx.amount,
      'fee': tx.amount,
      'fee_reverse': tx.amount,
      'manual_entry': tx.amount,
    },
    'hold': {
      'collateral_lock': -tx.amount,
      'collateral_unlock': -tx.amount,
      'liquidation': -tx.amount,
      'manual_entry': tx.amount,
    },
    'pending_deposit': {
      'deposit': tx.amount,
      'deposit_reverse': tx.amount,
    },
    'pending_withdraw': {
      'withdraw': -tx.amount,
      'withdraw_reverse': tx.amount,
    },
  })[tx.transactionTarget][tx.transactionType] as number;
};
export let formatTransactionTarget = (tx: Transaction) => {
  return ({
    'amount': 'Amount',
    'hold': "Hold",
    'pending_deposit': "Pending Deposit",
    'pending_withdraw': "Pending Withdraw",
  })[tx.transactionTarget] as string;
};