import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import { v4 as uuidv4 } from 'uuid';
import { Trans, useTranslation } from "react-i18next";
import FirebaseUploadFileDisplayer from "../FirebaseUploadFileDisplayer";

export interface UploadedFile{
    fileURL:string;
    isPicture:boolean;
    fileName:string;
    originalFileName:string;
}

const FirebaseFileUploader = ({
    processURLFunction,
    multiple = true,
    maxNumberOfFiles = 0,
    accept = "",
    fileNamePrefix = "",
    fireStorageRef,
    disableDownloadURL  = false,
    withPreview = false,
}:{
    processURLFunction:(uploadFileList: UploadedFile[])=>void, //use this function to pass firebase uploaded data out
    multiple?:boolean,
    maxNumberOfFiles?:number,
    accept?:string,
    fileNamePrefix:string,
    fireStorageRef:firebase.storage.Reference,
    disableDownloadURL?:boolean,
    withPreview ?: boolean,
}) =>{
    const [fileList, setFileList] = useState<File[]>([]); // fileList is to store raw file
    const [uploadFileList,setUploadFileList] = useState<UploadedFile[]>([]) // uploadFileList is to store file data uploaded to firebase
    const [uploadInputKeyToRefresh, setUploadInputKeyToRefresh] = useState("");
    const [loading, setLoading] = useState<boolean>(false)
    const override = css`
        margin-left: 5%;
    `;

    const { t } = useTranslation('FirebaseUploader')
    
    //set file list after user select files, will prevent user upload more than max no. of files
    const handleChange = (e:React.FormEvent<HTMLInputElement>)=>{
        let tempFileListCollection = e.currentTarget.files
        // console.log("handleChange ", tempFileListCollection)
        if(tempFileListCollection === null)return
        if(maxNumberOfFiles > 1){
            if(tempFileListCollection?.length > maxNumberOfFiles){
                setUploadInputKeyToRefresh(Math.random().toString(36))
                return alert(t('max_upload_msg',{maxNumberOfFiles,defaultValue:"You can upload max {{maxNumberOfFiles}} files."}))
            }
            if(fileList.length + tempFileListCollection?.length > maxNumberOfFiles){
                setUploadInputKeyToRefresh(Math.random().toString(36))
                // return alert(`You can upload max ${maxNumberOfFiles} files. Remove uploaded files to free the slot.`)
                return alert(t('max_upload_msg_remove',{maxNumberOfFiles,defaultValue:"You can upload max {{maxNumberOfFiles}} files. Remove uploaded files to free the slot."}))
                
            }
            //for multiple files, concat the filelist and prevent duplicate file
            setUploadInputKeyToRefresh(Math.random().toString(36))
            let fileNameList = fileList.map(e=>e.name)
            let newFileList = [...fileList, ...Array.from(tempFileListCollection).filter(e=>!fileNameList.includes(e.name) ) ]
            setFileList(newFileList)
        }else{
            // for single files, just replace the old record
            setFileList(Array.from(tempFileListCollection))
        }
        
    }

    // upload filelist to firebase storage and return UploadedFile, contain urls and isPicture
    const UploadFileToFireBase = async(tempFileList:File[])=>{
        setLoading(true)
        let tempUploadFileList:UploadedFile[] = []
        // let tempFileList = Array.from(fileList)

        for (const file of tempFileList) {

            // since fileList pass to here contain even uploaded file, prevent re-upload to firebase and reuse old uploaded record
            let oldUploadedFile = uploadFileList.find(e=>e.originalFileName === file.name)
            if(oldUploadedFile){
                tempUploadFileList.push(oldUploadedFile)
                continue
            }

            let fileName = `${fileNamePrefix}-${uuidv4()}`
            await fireStorageRef.child(fileName).put(file);

            let fileURL = ""
            if(!disableDownloadURL){
                fileURL = await fireStorageRef.child(fileName).getDownloadURL()
            }else{
                fileURL = await fireStorageRef.child(fileName).fullPath
                // console.log("fileURL", fileURL)
            }
            
            //  pdf = application/pdf
            //  image = image/jpeg or image/any
            tempUploadFileList.push({
                fileName,
                fileURL,
                isPicture: file.type === "application/pdf" ? false : true,
                originalFileName:file.name,
            })
        }
        setUploadFileList(tempUploadFileList)
        // console.log("Finish")
        setLoading(false)
    }

    const handleRemoveUploadedFile = (e:React.MouseEvent<HTMLButtonElement>, originalFileName:string) =>{
        e.preventDefault()
        setFileList([...fileList.filter(e=>e.name !== originalFileName)])
    }

    useEffect(()=>{
        // console.log("fileList change", fileList)
        if(fileList.length === 0){
            UploadFileToFireBase([])
            // processURLFunction([])
            // console.log("clear url")
        }else{
            UploadFileToFireBase(fileList)
        }
        // fileList === null && processURLFunction([])
    },[fileList])

    useEffect(()=>{
        processURLFunction(uploadFileList)
    },[uploadFileList])

    return (
      <div>
          <input type="file" onChange={handleChange} 
            // ref={fileInputRef}
            key={uploadInputKeyToRefresh || ''}
            multiple = {multiple}
            accept={accept}
            className="btn btn-primary" style={{ position: "relative", display: "inline-flex", letterSpacing: "0.02em", alignItems: "center"}}
            disabled={loading}
          />
          {
                loading &&
                <ClipLoader
                    css={override}
                    size={25}
                    color={"#366CD7"}
                    loading={loading}
                />
          }
          <br/>
          <br/>
          <ol>
            {
                uploadFileList.map((file,index)=>{
                    return(

                        <li key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <button 
                                // className="btn btn-primary"
                                style={{borderRadius:"4px", marginRight:"10px"}}
                                onClick={e=>handleRemoveUploadedFile(e,file.originalFileName)}
                            >
                                <span style={{padding:"10px"}}>x</span>
                            </button>
                            <span style={{flex:1}}>{file.originalFileName}</span>
                            {
                                withPreview &&
                                <FirebaseUploadFileDisplayer file={file} height={50} width={50}/>
                            }
                        </li>
                    )
                })
            }
          </ol>

      </div>
    );
}

export default FirebaseFileUploader