import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Transaction } from '../../../ApiService/loan'
import { useFormatPrice, formatTransactionAmount, FormatTransactionType } from '../../../util/jacob/components/format'


const LoanDetailTransactions = ({
    transactions
}:{ 
    transactions:Transaction[]
}) =>{
    const { t } = useTranslation(['Loan','Common','Tx'])
    return(
        <div className="nk-block nk-block-lg">
            <div className="nk-block-head">
                <h5 className="nk-block-title">{t('Loan:related_transactions',{defaultValue:"Related Transactions"})}</h5>
            </div>

            <div className="card card-bordered">
                <table className="table table-iv-tnx">
                    <thead className="thead-light">
                        <tr>
                            <th className="tb-col-type"><span className="overline-title">{t('Tx:type',{defaultValue:"Type"})}</span></th>
                            <th className="tb-col-date"><span className="overline-title">{t('Tx:date',{defaultValue:"Date"})}</span></th>
                            <th className="tb-col-time tb-col-end"><span className="overline-title">{t('Tx:amount',{defaultValue:"Amount"})}</span></th>
                        </tr>
                    </thead> 
                    <tbody>
                    {
                        transactions
                        .map((tx, i) => {
                            return (<TxDisplayer tx={tx} key={i}/>)
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TxDisplayer = ({
    tx
}:{
    tx:Transaction
}) =>{
    const { t } = useTranslation(['Loan','Common','Tx'])
    let amount = formatTransactionAmount(tx);
    let moneyDisplay = useFormatPrice(tx.asset, amount, true)

    return(
        <tr>
        <td className="tb-col-type"><span className="sub-text">{FormatTransactionType(tx,t)}</span></td>
        <td className="tb-col-date"><span className="sub-text">{moment(tx.timestamp).format("YYYY-MM-DD, h:mm:ss")}</span></td>
        <td className="tb-col-time tb-col-end"><span className={`lead-text ${amount < 0 ? 'text-success' : 'text-danger'}`}>{moneyDisplay}</span></td>
        </tr>
    )
}

export default LoanDetailTransactions