import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/appContext';
import { compose } from '../../util/jacob/core/lambda';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormatPrice } from '../../util/jacob/components/format';

let preventDefault = (event: any) => event.preventDefault()

// declare namespace Details {
//   export interface Props {
//     quote: () => void;
//     createTicket: () => void;
//     requireBankInfo: boolean;
//   }
// }

let Details = (props: any) => {
  const { t } = useTranslation(['Borrow','Common'])
  const [checkAgreement, setCheckAgreement] = useState<boolean>(false)
  const {currentUser} = useContext(AppContext)

  function numberWithCommas(number: string) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const renderBtn = (verificationStatus :string, checkAgreement:boolean) => {
    if (verificationStatus === 'verified') {
      return (
        <button
          className="btn btn-lg btn-primary ttu" 
          data-toggle="modal" 
          data-target="#confirm-invest" 
          // onClick={compose(props.createTicket, preventDefault)}
          onClick={preventDefault}
          disabled={props['collateralPrice'] == undefined || props['requireBankInfo'] || !checkAgreement}
          children={checkAgreement ? t('Confirm & proceed') : t('Please agree the terms & condition')}
        />
      )
    } else {
      return (
        <Link to="/kyc">
        <button
              className="btn btn-lg btn-warning ttu" 
              children={t('Please verify identity first')}
            />
        </Link>
      )
    }
  }  
  return (
    <div className="col-xl-4 col-lg-5 offset-xl-1">
      <div className="card card-bordered ml-lg-4 ml-xl-0">
        <div className="nk-iv-wg4">
          <div className="nk-iv-wg4-sub" style={{borderBottom:"0px"}} >
            <h6 className="nk-iv-wg4-title title">{t('Your Loan Details')}</h6>
            <ul className="nk-iv-wg4-overview g-2">
              <li>
                <div className="sub-text">{t('Borrow Asset')}</div>
                <div className="lead-text">{
                  props['borrowAsset'] ? (
                    props['borrowAsset']
                  ) : ''
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Loan Duration')}</div>
                <div className="lead-text">{
                  props['loanTerm'] ? (
                    `${props['loanTerm']} ${t('Month(s)')}`
                  ) : ''
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Borrow Amount')}</div>
                <div className="lead-text">{
                  useFormatPrice(props['borrowAsset'],props['borrowAmount'],true)
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Interest Rate %')}</div>
                <div className="lead-text">{
                  props['interestRate'] ? (
                    `${(props['interestRate'] * 1e2).toFixed(2)}%`
                  ) : ''
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Interest Per Month')}</div>
                <div className="lead-text">{
                  props['totalInterestPayment'] && props['loanTerm'] ? (
                    `~${numberWithCommas((props['totalInterestPayment'] / props['loanTerm']).toFixed(props['repaymentAsset'] === "HKD" || props['repaymentAsset'] === "USD"? 2 : 6))} ${props['repaymentAsset']}`
                  ) : ''
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Total Interest')}</div>
                <div className="lead-text">{
                  props['totalInterestPayment'] ? (
                    `${numberWithCommas(props['totalInterestPayment'])} ${props['repaymentAsset']}`
                  ) : ''
                }</div>
              </li>
              {/* <li>
                <div className="sub-text">{t('Early Termination Fee')}</div>
                <div className="lead-text">{
                  props['terminationFee'] ? (
                    `${props['terminationFee']} ${props['repaymentAsset']}`
                  ) : ''
                }</div>
              </li> */}
            </ul>
          </div>
          <div className="nk-iv-wg4-sub">
            <ul className="nk-iv-wg4-list"> 
               <li>
                <div className="lead-text">{t('Total Loan')}</div>
                <div className="caption-text text-primary">{
                  useFormatPrice(props['borrowAsset'],props['borrowAmount'],true)
                }</div>
              </li>
            </ul>
          </div>
          <div className="nk-iv-wg4-sub">
            <ul className="nk-iv-wg4-list">
              <li>
                <div className="sub-text">{t('Collateral Asset')}</div>
                <div className="lead-text">{
                  props['collateralAsset'] ? (
                    props['collateralAsset']
                  ) : ''
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Loan-To-Value Ratio')}</div>
                <div className="lead-text">{
                  props['LTV'] ? (
                    `${(props['LTV'] * 1e2).toFixed(2)}%`
                  ) : ''
                }</div>
              </li>
            {/* </ul>
          </div>
          <div className="nk-iv-wg4-sub">
            <ul className="nk-iv-wg4-list"> */}
              {/* <li>
                <div className="sub-text">{t('Collateral Asset Value')}</div>
                <div className="lead-text">{
                  useFormatPrice(props['repaymentAsset'],props['collateralPrice'] * props['collateralAmount'],true)
                }</div>
              </li> */}
              <li>
                <div className="sub-text">{t('Amount to be locked')}</div>
                <div className="lead-text">{
                  useFormatPrice(props['collateralAsset'],props['collateralAmount'],true)
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Margin call at')} <span>(62.5% LTV)</span></div>
                <div className="lead-text">{
                  `1 ${props['collateralAsset']} = ${useFormatPrice(props['repaymentAsset'],props['price60PC'],true)}`
                }</div>
              </li>
              <li>
                <div className="sub-text">{t('Liquidation at')} <span>(71.5% LTV)</span></div>
                <div className="lead-text">{
                  `1 ${props['collateralAsset']} = ${useFormatPrice(props['repaymentAsset'],props['price80PC'],true)}`
                }</div>
              </li>
            </ul>
          </div>
          {/* <div className="nk-iv-wg4-sub">
            <ul className="nk-iv-wg4-list"> */}
              {/* <li>
                <div className="lead-text">{t('Total Loan')}</div>
                <div className="caption-text text-primary">{
                  useFormatPrice(props['borrowAsset'],props['borrowAmount'],true)
                }</div>
              </li> */}
              {/* <li>
                <div className="lead-text">{t('Total Repayment')}</div>
                <div className="caption-text text-primary">{
                  useFormatPrice(props['repaymentAsset'],props['totalOutstanding'],true)
                }</div>
              </li> */}
              {/* <li>
                <div className="lead-text">{t('Total Collateral')}</div>
                <div className="caption-text text-primary">{
                  useFormatPrice(props['collateralAsset'],props['collateralAmount'],true)
                }</div>
              </li> */}
            {/* </ul>
          </div> */}
          <div className="nk-iv-wg4-sub text-center bg-lighter">
            <button 
              className="btn btn-lg btn-primary ttu" 
              onClick={compose(props.quote, preventDefault)}
              disabled={props['borrowAmount'] == undefined || props['borrowAmount'] <= 0}
              children={t('Get Quote')}
            />
          </div>
          <div className="nk-iv-wg4-sub text-center bg-lighter">
              <div style={{"marginLeft": "35px"}} className="custom-control custom-control-xs custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="checkbox" checked={checkAgreement} onChange={()=>setCheckAgreement(!checkAgreement)}/>
                <label className="custom-control-label" htmlFor="checkbox">{t('I agree the')} <Link target="_blank" to="/terms">{t('terms and conditions.')}</Link></label>
              </div>
            {renderBtn(currentUser? currentUser.verificationStatus: "", checkAgreement)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;