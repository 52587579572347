import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoanTicket } from '../../../ApiService/loan'
import moment from 'moment';
import { useFormatPrice } from '../../../util/jacob/components/format';

export const LoanDetailBasicInformation = ({
    loanTicket
}:{
    loanTicket:LoanTicket
}) =>{
    const { t } = useTranslation(['Loan','Common'])
    return(
        <div className="nk-block">
        <div className="nk-block-head">
          <h5 className="nk-block-title">{t('Loan:loan_information',{defaultValue:"Loan Information: "})}</h5>
        </div>
        <div className="card card-bordered">
          <div className="card-inner">
            <div className="row gy-gs">

              
              <div className="col-md-6">
                <div className="nk-iv-wg3">
                  <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                    <div className="nk-iv-wg3-sub">
                      <div className="nk-iv-wg3-amount">
                        <div className="number">{useFormatPrice(loanTicket.borrowAsset, loanTicket.borrowAmount, true)}</div>
                      </div>
                      <div className="nk-iv-wg3-subtitle">{t('Loan:borrow_amount',{defaultValue:"Borrow Amount"})}</div>
                    </div>
                    <div className="nk-iv-wg3-sub">
                      <span className="nk-iv-wg3-plus text-soft"><em className="icon ni ni-plus"></em></span>
                      <div className="nk-iv-wg3-amount">
                        <div className="number">{useFormatPrice(loanTicket.borrowAsset, loanTicket.totalInterestPayment, true)}</div>
                      </div>
                      <div className="nk-iv-wg3-subtitle">{t('Loan:total_interest',{defaultValue:"Total Interest"})}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-2">
                <div className="nk-iv-wg3 pl-md-3">
                  <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                    <div className="nk-iv-wg3-sub">
                      <div className="nk-iv-wg3-amount">
                        <div className="number">{useFormatPrice(loanTicket.borrowAsset, loanTicket.totalOutstanding, true)}</div>
                      </div>
                      <div className="nk-iv-wg3-subtitle">{t('Loan:total_repayment',{defaultValue:"Total Repayment"})}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-gs">
              <div className="col-md-6">
                <div className="nk-iv-wg3">
                  <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                    <div className="nk-iv-wg3-sub">
                      <div className="nk-iv-wg3-amount">
                        <div className="number">
                          {
                            loanTicket.depositAddress
                          }
                        </div>
                      </div>
                      <div className="nk-iv-wg3-subtitle">{t('Loan:deposit_address',{defaultValue:"Deposit Address"})}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 offset-lg-2">
                <div className="nk-iv-wg3 pl-md-3">
                  <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                    <div className="nk-iv-wg3-sub">
                      <div className="nk-iv-wg3-amount">
                        <div className="number">
                          {useFormatPrice(loanTicket.collateralAsset, loanTicket.fundedAmount, false)}/{useFormatPrice(loanTicket.collateralAsset, loanTicket.collateralAmount, true)}
                        </div>
                      </div>
                      <div className="nk-iv-wg3-subtitle">{t('Loan:collateral_funded',{defaultValue:"Funded"})}/{t('Loan:collateral_total_need',{defaultValue:"Collateral Amount"})}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="schemeDetails" className="nk-iv-scheme-details">
          <ul className="nk-iv-wg3-list">
            <li>
              <div className="sub-text" >{t('Loan:loanID',{defaultValue:"Loan ID"})}</div>
              <div className="lead-text" >{loanTicket.loanID}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:loan_term',{defaultValue:"Loan Term"})}</div>
              <div className="lead-text" >{t('Common:Months',{defaultValue:"{{months}} Months", months:loanTicket.loanTerm})}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Common:APR',{defaultValue:"APR"})}</div>
              <div className="lead-text" >{`${(loanTicket.interestRate * 1e2).toFixed(2)}%`}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Common:LTV',{defaultValue:"Pledged LTV Ratio"})}</div>
              <div className="lead-text" >{`${(loanTicket.LTV * 1e2).toFixed(2)}%`}</div>
            </li>
          </ul>
          <ul className="nk-iv-wg3-list">
            <li>
              <div className="sub-text" >{t('Loan:borrow_amount',{defaultValue:"Borrow Amount"})}</div>
              <div className="lead-text" >{useFormatPrice(loanTicket.borrowAsset, loanTicket.borrowAmount, true)}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:collateral_funded',{defaultValue:"Funded Collateral"})}</div>
              <div className="lead-text" >{useFormatPrice(loanTicket.collateralAsset, loanTicket.fundedAmount, true)}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:collateral_total_need',{defaultValue:"Collateral Need"})}</div>
              <div className="lead-text" >{useFormatPrice(loanTicket.collateralAsset, loanTicket.collateralAmount, true)}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:request_date',{defaultValue:"Request Date"})}</div>
              <div className="lead-text" >{`${moment(loanTicket.requestDate).format("YYYY-MM-DD")}`}</div>
            </li>
          </ul>
          <ul className="nk-iv-wg3-list">
            <li>
              <div className="sub-text" >{t('Loan:margin_call',{defaultValue:"Margin Call At"})}</div>
              <div className="lead-text" >{`1 ${loanTicket.collateralAsset} = ${useFormatPrice(loanTicket.borrowAsset, loanTicket.price60PC, true)}`}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:liquidation',{defaultValue:"Liquidation At"})}</div>
              <div className="lead-text" >{`1 ${loanTicket.collateralAsset} = ${useFormatPrice(loanTicket.borrowAsset, loanTicket.price80PC, true)}`}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:daily_interest',{defaultValue:"Daily Interest"})}</div>
              <div className="lead-text" >{`~${useFormatPrice(loanTicket.borrowAsset, loanTicket.dailyInterest, true)}`}</div>
            </li>
            <li>
              <div className="sub-text" >{t('Loan:total_interest',{defaultValue:"Total Interest"})}</div>
              <div className="lead-text" >{useFormatPrice(loanTicket.borrowAsset, loanTicket.totalInterestPayment, true)}</div>
            </li>
          </ul>
          </div>
        </div>
      </div>
    )
}

export default LoanDetailBasicInformation