interface BankDepositAccount {
    bankLocation:string
    bankName:string
    accountNumber:string
    accountHolderName:string
    fpsID?:string
}
export const MoneyLenderBankAccount:BankDepositAccount = {
    bankLocation:"Hong Kong SAR",
    bankName:"Bank of Communications (Hong Kong) Limited | 交通银行（香港）",
    accountHolderName:"Grand Prospects Finance International Limited",
    accountNumber:"382-569-0202-4914",
    fpsID:"160581393"
}