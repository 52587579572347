export const Tx = {
    "type":"交易种类",
    "date":"交易日期",
    "amount":"交易金额",
    "asset":"资产种类",
    "tx_ref":"交易编号",

    'deposit': '存款',
    'withdraw': '提现',
    'interest': '利息',
    'interest_repayment': '贷款利息还款',
    'loan_payout': '批出贷款',
    'loan_repayment': '贷款还款',
    'loan_termination': '贷款终止',
    'liquidation': '贷款平仓',
    'fee': '手续费',
    'fee_reverse': '返还手续费',
    'manual_entry': '人工处理',
    'collateral_lock': '贷款抵押锁定',
    'collateral_unlock': '贷款抵押解锁',

    'pending_deposit': '存款（未确认）',
    'pending_deposit_reverse': '存款（已确认）',

    'pending_withdraw': '提现（未确认）',
    'pending_withdraw_reverse': '提现（已确认）',

    "crypto_transactions":"虚拟货币交易记录",
    "number_of_tx":"您有{{total}}条交易记录"
}
