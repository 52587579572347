export const Borrow = {
    "borrow_page_title":"请选择需要的贷款",
    "borrow_page_type":"港币",
    "borrow_button":"贷款",
    "borrow_go_back":"返回",

    "hkd_release_method":"贷款将直接发放到银行账户",

    // USDT Borrow
    "hkd_borrow_page_title":"申请港币贷款",
    "hkd_borrow_page_step1_title":"第1步：贷款表格",
    "hkd_borrow_page_step1_description":"请选择贷款金额、贷款期、贷款比例、和抵押货币",
    "You will get": "你会得到",
    "Your loan will arrive in your HKD bank account": "您的贷款将存入您的港元银行帐户",
    "Choose your interest rate / LTV Ratio": "选择您的利率 / 贷款抵押价值比",
    "Choose Collateral Asset": "选择您的保证金抵押品",
    "Bitcoin - BTC": "比特币 - BTC",
    "Ethereum - ETH": "以太币 - ETH",
    "USDT - USDT": "USDT",
    "Choose Loan Duration": "贷款期限",
    "Months": "个月",
    "Choose Quick Amount to Borrow": "选择贷款金额",
    "Or Enter Your Amount": "或输入贷款金额",
    "Minimum borrow 5,000 HKD and up to 2,000,000 HKD": "最低借贷 5,000 港币, 最高至 2,000,000 港币",

    // USDT Borrow
    "Apply for a USDT Loan":"申请 USDT 贷款",
    "Your loan will arrive in your GP wallet": "您的贷款将发送到您的GP钱包中",
    "Minimum borrow 700 USDT and upto 250,000 USDT": "最低借贷 700 USDT, 最高至 250,000 USDT",

    // HKD Bank Info
    "Bank information has been confirmed, please proceed to obtain a loan quote and continue.": "银行信息已确认，请继续获取贷款报价并继续",
    "hkd_borrow_page_step2_title": "步骤2：输入您的银行帐户详细信息",
    "We will release the loan to this bank account.": "我们会将贷款释放到该银行帐户",
    "Bank Account Information": "银行账户信息",
    "Bank Name": "银行名称",
    "This field is required": "这是必填栏",
    "Bank Transfer Method": "银行转账方式",
    "BankAccount": "银行账户",
    "FPS": "FPS - 快速支付系統",
    "Bank Account Number": "银行帐号",
    "FPS ID / Email / Phone Number / Bank Account": "FPS ID / 电子邮件 / 电话号码 / 银行帐户",
    "Account Holder Full Name (English)": "帐户持有人全名（英文）",
    "Account Type": "帐户类型",
    "Savings": "储蓄账户",
    "Current": "往来账户",
    "Comments / Special Instructions": "特别说明",
    "Confirm Bank Account Details": "确认银行帐户",

    // Borrow Details
    "Your Loan Details": "贷款详细信息",
    "Borrow Asset": "借入资产",
    "Loan Duration": "贷款期限",
    "Month(s)": "个月",
    "Borrow Amount": "借入金额",
    "Interest Rate %": "利率 ％",
    "Interest Per Month": "每月利息",
    "Total Interest": "总利息",
    "Early Termination Fee": "提前终止贷款费",
    "Collateral Asset": "抵押资产",
    "Loan-To-Value Ratio": "贷款抵押价值比",
    "Collateral Asset Value": "抵押品价值",
    "Amount to be locked": "抵押品锁定金额",
    "Margin call at": "追加保证金价",
    "Liquidation at": "清算价",
    "Total Loan": "总贷款",
    "Total Repayment": "总还款额",
    "Total Collateral": "抵押总额",
    "I agree the": "我同意",
    "terms and conditions.": "条款和条件",
    "Get Quote": "报价",
    "Confirm & proceed": "确认并继续",
    "Please agree the terms & condition": "请同意条款和条件",
    "Please verify identity first": "请先验证身份",

    // Borrow Dialog
    "Application error, please contact support": "申请错误，请联系支持团队",
    "Excepted error occured, please contact support": "发生错误，请与支持团队联系",
    "Please confirm your loan application.": "请确认您的贷款申请",
    "1. You will be redirected to DocuSign to sign loan agreement after clicking the confirm button.": "1. 单击确认按钮后，您将被重定向到贷款详情页面",
    "2. After Signing the Loan Agreement via DocuSign, please proceed to deposit collateral to your wallet address.": "2. 通过 DocuSign 签署贷款协议后，请继续将抵押品存入您的钱包地址",
    "3. Once we have received your collateral deposit, your loan will be released to your GP Wallet or Bank Account.": "3. 我们收到您的抵押存款后，您的贷款将被释放到您的GP钱包或银行帐户中",
    "4. Please confirm your loan application within 24 hours.":"4. 请在24小时内确认你的贷款申请",
    "Confirm": "确认",
    "Redirecting to DocuSign": "进入贷款详情页面",
    "Applying loan": "申请贷款中",
    "Cancel": "取消",

    "usdt_release_method":"贷款将发放到系统钱包，需要进行提现",
    // "hkd_min_period":"1 日",
    // "hkd_max_period":"最高还款期",
    // "hkd_can_early_repayment":"最高还款期",
    // "hkd_can_early_termination":"最高还款期",

    "min_period":"最低还款期",
    "max_period":"最高还款期",
    "can_early_repayment":"提早还款",
    "can_early_termination":"提早终止贷款",
} 