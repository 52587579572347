export const Loan = {

    // UI
    "loan_active_title":"貸款（已生效）",
    "loan_active_subtitle":"進行中",
    "loan_matured_subtitle":"已完結貸款",
    "loan_pending_title":"貸款（審核中）",
    "loan_pending_subtitle":"正在審核貸款",
    "loan_application_history_title":"貸款申請歷史",
    "loan_application_processed_subtitle":"貸款申請歷史（成功）",
    "loan_application_closed_subtitle":"貸款申請歷史（失敗 / 逾期）",
    "check_tx_button":"查看交易記錄",
    "borrow_more_button":"貸款更多",
    "back_to_my_loans":"返回我的貸款",

    // Loan fund release ref
    "loan_fund_release_ref":"貸款發放證明",
    "loan_fund_release_bank_tx":"匯款編號",

    // Loan Detail
    "loan_application_time_limit_description":"貸款申請有效期: ",
    "loanID":"貸款編號",
    "loan_term":"貸款期數",
    "borrow_amount":"貸款金額",
    "total_interest":"總利息",
    "daily_interest":"日息",
    "total_repayment":"總還款",
    "total_outstanding":"總金額（含利息）",
    "request_date":"申請日期",
    "start_date":"開始日期",
    "end_date":"結束日期",
    "application_status":"申請狀況：",
    "loan_information":"貸款詳情",
    "collateral_total_need":"抵押保證金總金額",
    "collateral_funded":"已繳納抵押保證金",
    "collateral_status":"抵押保證金狀況：",
    "collateral_empty":"未繳納抵押保證金",
    "collateral_not_enough":"抵押保證金不足",
    "collateral_enough":"抵押保證金繳納完成",
    "contract_borrower_status":"借貸人合約簽署狀況：",
    "contract_admin_status":"貸款公司合約簽署狀況：",
    "margin_call":"追加抵押保證金通知",
    "liquidation":"平倉 / 終止貸款",
    
    
    "First step":"第一步",
    "Second step":"第二步",
    "sign_agreement":"按此簽署貸款協議",
    "signed_agreement":"貸款協議已簽署",
    "non_signed_borrower":"等待管理員確認",
    "non_signed_agreement":"貸款協議未簽署",
    "deposit_collateral":"按此抵押貨幣",
    "deposit_address":"抵押貨幣入賬地址",
    "tx_proof":"貸款公司銀行轉賬證明",
    "tx_reference":"貸款公司銀行轉賬交易編號",

    "start_repayment_button":"還款方法",
    "apply_early_termination_button":"申請提前終止貸款",
    "pay_early_termination_button":"繳付提前終止貸款費用",

    // Dialogue Wallet
    "send_coins_to_address":"請轉賬{{amount}} {{coin}}到以下地址繳納抵押保證金",
    "dialogue_wallet_msg":"您的<bold> {{borrowAmount}} {{borrowAsset}} </bold> 貸款將會在我們收到<bold> {{collateralAmount}} {{collateralAsset}} </bold> 抵押保證金後批核。請轉賬抵押保證金到以下地址：",
    "tx_reminder":"您可以在賬戶交易記錄內查看最新情況。",
    "tx_crypto_reminder":"視乎區塊鏈堵塞情況，交易將在轉賬10分鐘至30分鐘後顯示到交易記錄內。",
    "Please_wait_for_address":"請等待系統分配地址",

    // Dialogue termination confirm
    "dialogue_termination_confirm_header":"請確認是否提前終止貸款",
    "dialogue_termination_confirm_reminer1":"您的貸款抵押品會在我們收到您的全額還款及確認後退還到GP錢包。",

    // Dialogue Repayment Crypto
    "dialogue_repayment_crypto_header":"還款(#{{repaymentID}})",
    "dialogue_repayment_crypto_msg":"請轉賬<bold> {{repaymentAmount}} {{repaymentAsset}} </bold>到以下地址還款: ",

    // Dialogue Repayment Bank Deposit
    "dialogue_repayment_bank_header":"還款(#{{repaymentID}})",
    "dialogue_repayment_bank_msg":"請轉賬<bold> {{repaymentAmount}} {{repaymentAsset}} </bold>到以下銀行賬戶: ",
    "dialogue_repayment_bank_upload_success":"文件上載成功，請關閉視窗",
    "dialogue_repayment_bank_processing":"您的還款文件正被審核，請稍後再查詢，謝謝",
    "dialogue_repayment_bank_upload_reminder":"請在轉賬後將銀行收據上傳",

    // Dialogue Termination Crypto
    "dialogue_termination_crypto_header":"終止貸款 (#{{loanID}})",
    "dialogue_termination_crypto_msg":"如需終止貸款，請在<bold> {{repaymentDate}} </bold> 前轉賬<bold> {{repaymentAmount}} {{repaymentAsset}} </bold> 到以下地址。 您的貸款抵押品<bold> {{collateralAmount}} {{collateralAsset}}</bold> 會在我們收到您的全額還款及確認後退還到GP錢包。",
    
    
    // Dialogue Termination Bank Deposit
    "dialogue_termination_bank_header":"終止貸款 (#{{loanID}})",
    "dialogue_termination_bank_msg":"如需終止貸款，請在<bold> {{repaymentDate}} </bold> 前轉賬<bold> {{repaymentAmount}} {{repaymentAsset}} </bold> 到以下銀行賬戶。 您的貸款抵押品<bold> {{collateralAmount}} {{collateralAsset}}</bold> 會在我們收到您的全額還款及確認後退還到GP錢包。",
    "dialogue_termination_bank_upload_success":"文件上載成功，請關閉視窗",
    "dialogue_termination_bank_processing":"您的還款文件及終止貸款申請正被審核，請稍後再查詢，謝謝",
    "dialogue_termination_bank_upload_reminder":"請在轉賬後將銀行收據上傳",

    // Repayments
    "upcoming_repayments":"還款詳情",
    "interest_repayment":"利息還款",
    "principle_and_interest_repayment":"利息及本金還款",
    "loan_termination_repayment":"貸款終止",
    "early_repayment":"提早還款",
    "unknown_repayment":"未知還款，請聯絡我們查詢",
    "repayment_type":"還款種類",
    "repayment_date":"還款日期",
    "repayment_status":"還款狀況",
    "repayment_paid_amount":"已付",
    "repayment_remain_amount":"需還款金額",
    "repayment_state_active":"進行中",
    "repayment_state_cancelled":"已取消",
    "repayment_state_overdue":"已逾期",
    "repayment_state_paid":"已完成",
    "repayment_state_pending":"等候中",
    "repayment_state_processing":"處理中",
    "unknown_repayment_state":"未知",

    // loan status
    // Loan:status:loan_status
    // "status":{
    // "loan_status":"貸款狀況",
    // },
    "loan_status":"貸款狀況",
    "current_LTV":"現時貸款抵押比例",
    "loan_term_passed":"已完成期數",
    "next_repayment":"下期還款",
    "amount_due":"需還款 ",
    "days_remian":"距離還款日",
    "current_repayment":"已還款",
    "today_termination_price":"現時貸款終止價格",
    "termination_fee":"終止貸款手續費",
    "void":"已豁免",
    "months":"期",
    "terminating":"貸款終止中",
    // "days_remian":"距離還款日",
    // "current_LTV":"現時貸款抵押比例",

    // Transactions
    "related_transactions":"相關交易記錄",

} 