export interface Function<
  TArgs extends any[] = any[],
  Tret extends any = any
> {
  (...args: TArgs): Tret;
}
export function compose(
  ...fns: Function<any>[]
): Function<any> {
  return arg => fns.reduceRight((acc, fn) => fn(acc), arg)
}
export function pipe(
  ...fns: Function<any>[]
): Function<any> {
  return arg => fns.reduce((acc, fn) => fn(acc), arg)
}
export function composePromise(
  ...fns: Function<any, Promise<any>>[]
): Function<any, Promise<any>> {
  return arg => fns.reduceRight((acc, fn) => acc.then(fn), Promise.resolve(arg))
}
export function composeAny(
  ...fns: Function<any, any>[]
): Function<any, Promise<any>> {
  return arg => fns.reduceRight((acc, fn) => acc.then(fn), Promise.resolve(arg))
}
export function curry(
  fn: Function,
  acc: any[]
) {
  return (...args: any[]) => (
    ((...args: any[]) => (
      args.length != fn.length ?
      curry(fn, args) :
      fn.apply(null, args)
    )).apply(null, acc.concat(args))
  );
}
export let guard = (fg: boolean) => <
  TArgs extends any[]
>(fn: Function<TArgs, any>): Function<TArgs, void> => {
  if (fg) {
    return fn;
  }
  return (...args) => {}
};