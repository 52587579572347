export const KYC = {
    "KYC_title":"实名认证",
    "KYC_title_done":"实名认证完成",
    "KYC_start_button":"实名认证",
    "kycFinishMsg":"你已完成实名认证，请点击下面按钮开始贷款。",
    "kycStartMsg":"点击下面按钮开始实名认证",
    "Start Verification":"开始认证",
    "Click here to start borrowing":"开始贷款",
    "regulation":"基于法律规定，所有贷款客户均需完成实名认证",

    "KYC_none":"未实名认证",
    "KYC_pending":"等候实名认证中",
    "KYC_underReview":"实名认证审核中",
    "KYC_verified":"已完成实名认证",
    "KYC_unknown":"未知实名认证状态",
    "unverified":"未认证",
    "verified":"已认证",

    "KYCFormStep1_not_empty":"必填",

    "KYCFormStep1_title":"个人资料、地址、工作",
    "KYCFormStep1_title_description":"实名认证需要您的个人资料、地址、工作、收入",
    "KYCFormStep1_guideline1":"请小心填写您的个人资料，提交后将不能修改。",
    "KYCFormStep1_full_name_english":"英文全名（与身份证相同）",
    "KYCFormStep1_full_name_chinese":"中文全名（与身份证相同）",
    "KYCFormStep1_residential_address":"住址",
    "KYCFormStep1_mobile_phone":"手提电话",
    "KYCFormStep1_mobile_phone_length_limit":"必填，电话号码必须为8位数字",
    "KYCFormStep1_source_of_income":"收入来源",
    "KYCFormStep1_saving":"储蓄",
    "KYCFormStep1_salary":"薪金",
    "KYCFormStep1_investment_proceed":"投资",
    "KYCFormStep1_inheritance":"遗产",
    "KYCFormStep1_gift_from_family":"家人赠送",
    "KYCFormStep1_others":"其他",
    "KYCFormStep1_name_of_employer":"工作公司名称",
    "KYCFormStep1_year_of_employment":"工作年资",
    "KYCFormStep1_income_currency":"收入货币种类",
    "KYCFormStep1_annual_inome":"工作年薪",
    "KYCFormStep1_finish_msg":"步骤1已完成，请继续填写下面资料",
    "KYCFormStep1_submit_button":"提交步骤1",
    "KYCFormStep1_verifying":"您的个人资料、地址、工作审核中，请继续填写其他资料",
    "KYCFormStep1_verified":"您的个人资料、地址、工作审核已通过，请继续填写其他资料",

    "KYCFormStep2_title":"上传证明文件",
    "KYCFormStep2_title_description":"请上传以下文件的电子副本供贷款审核。",
    "KYCFormStep2_guideline1":"请准备以下文件的电子副本：",
    "KYCFormStep2_id_card":"身份证",
    "KYCFormStep2_address_proof":"住址证明",
    "KYCFormStep2_bank_statement":"银行月结单",
    "KYCFormStep2_income_statement":"收入证明",
    "KYCFormStep2_guideline2":"为了尽快通过您的申请，请确保以下事项：",
    "KYCFormStep2_guideline2_not_expire":"所有证明文件均需处于有效期内。",
    "KYCFormStep2_guideline2_visible":"文件需完整及内容清晰可见。",
    "KYCFormStep2_guideline2_no_light_glare":"文件没有任何反光。",
    "KYCFormStep2_guideline2_image_format":"只接受jpeg, jpg, png, pdf格式的文件。",
    "KYCFormStep2_upload_id_card_title":"上传身份证（上限：{{uploadIdCardLimit}} 张）",
    "KYCFormStep2_upload_bank_statement_title":"上传银行月结单（上限：{{uploadBankStatementLimit}} 张）",
    "KYCFormStep2_upload_income_statement_title":"上传收入证明（上限：{{uploadIncomeStatementLimit}} 张）",
    "KYCFormStep2_upload_address_proof_title":"上传地址证明（上限：{{uploadAddressProofLimit}} 张）",
    "KYCFormStep2_finish_msg":"所有文件上传完成，请继续完成认证",
    "KYCFormStep2_verifying":"您的证明文件审核中，请继续填写其他资料",
    "KYCFormStep2_verified":"您的证明文件审核已通过，请继续填写其他资料",

    
    "KYCForm_confirm_read_terms":"我已阅读<1>条款</1>及<3>私隐政策</3>",
    "KYCForm_confirm_all_info_correct":"我确认以上资料及文件均为正确",
    "KYCForm_continue_verification_button":"继续认证",

    "KYC_success_page_title":"实名认证已完成",
    // "KYC_success_page_description":"我们正在处理您的申请，请于稍后再次登录网站查看最新实名认证状态，或留意邮箱。",
    "KYC_success_page_under_review":"您的身份正在被核实，过程大约5-10分钟，请于稍后再次登录网站查看最新实名认证状态，或留意邮箱。",
    "KYC_success_page_start_borrow_button":"开始贷款",

    "KYC_error_page_title":"实名认证失败",
    "KYC_error_page_fail_msg":"我们无法辨认你的身份，请点击下面按钮重新进行人脸识别实名认证",
    "KYC_error_page_restart_button":"重新认证",
}