import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

const TnCPage = () => {
    useEffect(() => {
        const script = document.createElement("script");
    
        script.src = "https://www.powr.io/powr.js?platform=react";
        script.async = true;
    
        document.body.appendChild(script);
    })
    return (
        <React.Fragment>
            <div className="nk-content nk-content-lg nk-content-fluid">
                <div class="powr-faq" id="7e0093e8_1611798703"></div>
            </div>
        </React.Fragment>
    )
}

export default TnCPage;